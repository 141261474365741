import i18n from "../../localization"

  
export const getCompanyHeaderMapper=(data)=>{
    const companyHeaderMapper=[]
    const companyDataMappar=getCompanyMapper(data)
    const keys=companyDataMappar.length>0? Object.keys(companyDataMappar[0]):[]

    keys.map((key)=>{
        let words = key.split("_");
        let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        let result = capitalizedWords.join(" ");

        companyHeaderMapper.push({
            field:key,
            headerName:i18n.t(result),
            width: (key=='name'||key=="account_name")?200:100,

        })
    })


    return companyHeaderMapper
}

export const getCompanyMapper=(data)=>{
    const companyMapperData=[]
    data.map((item)=>{
        companyMapperData.push({
      id: item.id,
      name: item.name,
      account_balance: 0,
    //   is_receivable: item.is_receivable,
      creator: item.creator,
      created_at: (item.created_at),
        })
    })

    return companyMapperData
}

export const getOneCompanyMapper=(data)=>{
   
    const oneCompanyMapperData={
        name: data?.name,
        commission:data?.commission,
        account_type:'Company',
        }
  
    return oneCompanyMapperData
}