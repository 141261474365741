import React, { useState } from "react";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { useTheme, Box, IconButton, InputBase } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useProSidebar } from "react-pro-sidebar";
import ListLocalization from "../../components/ListLocalization";
import ProfileList from "../../components/ProfileList";
import ExchangeController from "../../features/exchange/ExchangeController";
import AuthController from "../../features/auth/AuthControler";
import { Link } from "react-router-dom";
const Topbar = () => {
  const theme = useTheme();
  const cookies={}
document.cookie?.split(';').map((item)=>{
 
  const [name, value] = item.trim().split('=');
  cookies[name]=value
});
  const role=cookies?.user?JSON.parse(cookies?.user)?.role:''
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const [open, setOpen] = useState(false)
  const [open1, setOpen1] = useState(false)

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box display="flex">
        {broken && !rtl && (
          <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}
        {/* <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          p={0.2}
          borderRadius={1}
        >
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" />
          <IconButton type="button">
            <SearchIcon />
          </IconButton>
        </Box> */}
      </Box>
      <Box display="flex">
        {/* {open&&role=='Manager'&&<ExchangeController open={open} setOpen={setOpen} child="edit-exchange"/>} */}
        {open1&&<AuthController open={open1} setOpen={setOpen1}  child="edit-password"/>}

        {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            
           <LightModeOutlinedIcon />
          ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton> */}
    
     
        <IconButton>
          <ProfileList onClick={()=>{setOpen1(true)}} />
        </IconButton>

        {/* <IconButton>
          <ListLocalization/>
        </IconButton> */}

        {role=='Manager'&&<IconButton>
          <Link to='/constants'>
          <CurrencyExchangeIcon  />
           </Link>
        </IconButton>}

       
        {broken && rtl && (
          <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default Topbar;
