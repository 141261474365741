export const ADD_EMPLOYEE_FORM =
  [
    {
      feild:'textField',
      type: "text",
      label: "Name",
      name: "name",
      grid: "2",
    },
   
    {
      feild:'textField',
      type: "text",
      label: "Username",
      name: "username",
      grid: "2",
    },
   
    {
      feild:'textField',
      type: "password",
      label: "Password",
      name: "password",
      grid: "2",
    },
   
    {
      feild:'select',
      type: "text",
      label: "Role",
      data:[],
      name: "role",
      grid: "4",
    },

    {
      feild:'switch',
      type: "checkbox",
      label: "Active",
      name: "is_active",
      grid: "2",
      disabled:false
    },
  ];

  export const EDIT_EMPLOYEE_FORM =
  [
    {
      feild:'textField',
      type: "text",
      label: "Name",
      name: "name",
      grid: "2",
    },
   
    {
      feild:'textField',
      type: "text",
      label: "Username",
      name: "username",
      grid: "2",
    },
   
    {
      feild:'textField',
      type: "password",
      label: "Password",
      name: "password",
      grid: "2",
    },
   
    {
      feild:'select',
      type: "text",
      label: "Role",
      data:[],
      name: "role",
      grid: "4",
    },

    {
      feild:'switch',
      type: "checkbox",
      label: "Active",
      name: "is_active",
      grid: "2",
      disabled:false
    },
  ];