import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from "react-i18next";
import { GlobalBoxContainer } from "../../Styles/Styles";
import { Actions, DataGridTable, Header } from "../../components";
import { getEmployeeHeaderMapper, getEmployeeMapper } from "./mapper";
import { employeeSelector } from "./redux/slice";
import { useState } from "react";

const Employees = ({ getEmployees, deleteEmployee }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { employees,total } = useSelector(employeeSelector)

  const [paginationModel, setPaginationModel] = useState({
    page:0,
    pageSize:100
  })
  useEffect(() => {
    getEmployees({page:paginationModel.page+1,limit:paginationModel.pageSize})
  }, [])

  useEffect(() => {
    getEmployees({page:paginationModel.page+1,limit:paginationModel.pageSize})

  }, [paginationModel.page,paginationModel.pageSize])

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header viewAll={true} title="Employees" subtitle={"Welcome to your " + "employees"} />
      </Box>
      <Button sx={{ bgcolor: '#0066CC' }} variant='contained' startIcon={<AddIcon />} onClick={() => { navigate('/employees/add') }}>{t('Add')}</Button>
      <GlobalBoxContainer>
        <DataGridTable total={total} paginationModel={paginationModel} setPaginationModel={setPaginationModel}  rows={getEmployeeMapper(employees)} columns={getEmployeeHeaderMapper(employees).concat(Actions(deleteEmployee, "edit/")).reverse()} />
      </GlobalBoxContainer>
    </Box>
  );
};

export default Employees;