import { createSlice } from "@reduxjs/toolkit"
import {  getExpances } from "./service"
import {  pendingReducer, rejectedReducer } from "../../../redux/globalReducer"
const initialState = {
    status: '',
    logging: false,
    total:0,
    Expances:[],
    Expance:{},
}
export const ExpanceSlice = createSlice({
    name: 'Expance',
    initialState,
    reducers: {
        delete(state,action){
            return {
                ...state,Expances:[...state.Expances].filter((Expance)=>Expance.id!=action.payload)
            }
       
        }
    },
    extraReducers: {
        [getExpances.pending]: pendingReducer,
        [getExpances.fulfilled]: (state, { payload }) => {
            state.Expances= payload?payload.data:[]
            state.total=payload?payload.total:state.total
            state.status = 'fulfilled' 
            state.logging = false
        },
        [getExpances.rejected]: rejectedReducer,

 
    }
})

export const ExpanceSelector = (state) => state.Expance;