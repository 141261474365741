import { DELETE_COMPANY, EDIT_COMPANY, COMPANY, COMPANYS, STORE_COMPANY } from "../../constent/urls"
import Requests from "../../services/Requests"
import HandleError from "../../services/HandleError"

class Company{
    static getCompanys = async (headers,params,fail) => {
        let result=await Requests.get(COMPANYS,headers,params );
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static getCompany = async (headers,id,fail) => {
        let result=await Requests.get(COMPANY+id,headers);
        
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static addCompany = async (headers,body,fail) => {
        let result=await Requests.post(STORE_COMPANY,headers,body)
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static editCompany = async (headers,body,id,fail) => {
        let result=await Requests.patch(EDIT_COMPANY+id,headers,body)
        //console.log(result);
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static deleteCompany = async (headers,id,fail) => {
        let result=await Requests.delete(DELETE_COMPANY+id,headers)
        return HandleError.handleError(result,()=>{return result},fail)
    }

}
export default Company;