import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import PersonIcon from '@mui/icons-material/Person';
import Iconify from "../components/Iconify";
import WorkIcon from '@mui/icons-material/Work';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { tokens } from "../theme";
import supplierIcon from '../assets/img/supplier-icon.png'

const cookies={}
document.cookie?.split(';').map((item)=>{
 
  const [name, value] = item.trim().split('=');
  cookies[name]=value
});

const role=cookies?.user?JSON.parse(cookies?.user)?.role:''
//console.log(role);
const getIcon = (name) => <Iconify sx={{color:"#4FA1CF"}} icon={name} width={22} height={22} />;
const sideBarItem=   {
  "Manager":
[
    
      {
        title: "Customers",
        to: "/customers",
        icon: getIcon("raphael:customer"),
      },

      {
        title: "Companys",
        to: "/companys",
        icon: getIcon("mdi:company"),
      },

      {
        title: "Suppliers",
        to: "/suppliers",
        icon: <img  width='25' height='25' src={supplierIcon}/>,
      },

      {
        title: "Employees",
        to: "/employees",
        icon: getIcon("clarity:employee-group-solid"),
      },

      {
        title: "Tickets",
        to: "/tickets",
        icon: getIcon("ion:ticket-outline"),
      },

      {
        title: "Current General Daily",
        to: "/general-daily/current-general-daily",
        icon: getIcon("ph:notebook"),
      },
      {
        title: "transaction",
        to: "/transactions/add",
        icon: getIcon("ant-design:transaction-outlined"),
      },

      {
        title: "Funds",
        to: "/funds",
        icon: getIcon("carbon:box"),
      },

      {
        title: "Bank",
        to: "/bank",
        icon: getIcon("carbon:box"),
      },

      {
        title: "Expances",
        to: "/Expances",
        icon: getIcon("wpf:paid"),
      },

      {
        title: "Recivables",
        to: "/recivables",
        icon: getIcon("carbon:box"),
      },

     
],
Normal_Employee: [
    
 

  {
    title: "Tickets",
    to: "/tickets",
    icon: getIcon("ion:ticket-outline"),
  },  
],
"Receivables_Employee": [
    
  {
    title: "Customers",
    to: "/customers",
    icon: getIcon("raphael:customer"),
  },



  {
    title: "Tickets",
    to: "/tickets",
    icon: getIcon("ion:ticket-outline"),
  },
  
  {
    title: "Recivables",
    to: "/recivables",
    icon: getIcon("carbon:box"),
  },
]
}
export const sideBarData = 
[
  {
    items: sideBarItem[role]
  }

];
