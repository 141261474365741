import { createAsyncThunk } from "@reduxjs/toolkit";
import Expance from "../ExpanceApi";

export const getExpances= createAsyncThunk(
  "ExpanceSlice/getExpances",
  async ({ headers,params }, { rejectWithValue }) => {
    return await Expance.getExpances(headers,params, () => {
      return rejectWithValue();
    });
  }
);

