import i18n from "../../localization";

export const getCustomerHeaderMapper = (data) => {
  const customerHeaderMapper = [];
  const customerDataMappar = getCustomerMapper(data);
  const keys =
    customerDataMappar.length > 0 ? Object.keys(customerDataMappar[0]) : [];
  keys.map((key) => {
    let words = key.split("_");
    let capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    let result = capitalizedWords.join(" ");
    customerHeaderMapper.push({
      field: key,
      headerName: i18n.t(result),
      width: key=='name'?200:100
    });
  });
  return customerHeaderMapper;
};

export const getTicketHeaderMapper = (data) => {
  const customerHeaderMapper = [];
  const customerDataMappar = getTicketMapper(data);
  const keys =
    customerDataMappar?.length > 0 ? Object.keys(customerDataMappar[0]) : [];
  keys.map((key) => {
    let words = key.split("_");
    let capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    let result = capitalizedWords.join(" ");
    customerHeaderMapper.push({
      field: key,
      headerName: i18n.t(result),
      width: 108,
    });
  });
  return customerHeaderMapper;
};

export const getTransactionHeaderMapper = (data) => {
  //console.log(data);
  const customerHeaderMapper = [];
  const customerDataMappar = getTransactionMapper(data);
  const keys =
    customerDataMappar?.length > 0 ? Object.keys(customerDataMappar[0]) : [];
  keys.map((key) => {
    let words = key.split("_");
    let capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    let result = capitalizedWords.join(" ");
    customerHeaderMapper.push({
      field: key,
      headerName: i18n.t(result),
      width: 132,
    });
  });
  return customerHeaderMapper;
};

export const getCustomerMapper = (data) => {
  const customerMapperData = [];
  data?.map((item,index) => {
    customerMapperData.push({
      id: item.id,
      name: item.name,
      is_receivable: item.is_receivable,
      created_at: item.created_at,
    });
  });

  return customerMapperData;
};

export const getOneCustomerMapper = (data) => {
  const oneCustomerMapperData = {
    name: data.name,
    is_receivable: data.is_receivable,
    account_type: "Customer",
  };

  return oneCustomerMapperData;
};

export const getTicketMapper = (tickets) =>
  tickets?.map((ticket) => ({
    id: ticket.id,
    description:ticket.description,
    travel: ticket.travel,
    ticket_number: ticket.ticket_number,
    currency:ticket.currency,
    company_name: ticket.company_name,
    supplier_name: ticket.supplier_name,
    customer_name: ticket.customer_name,
    creator_name: ticket.creator_name,
    creator_role: ticket.creator_role,
    status: ticket.status,
    customer_price:ticket.customer_price,
    type:ticket.type,
    rest_amount: ticket.rest_amount,
    created_at: ticket.created_at,
  }));

export const getTransactionMapper = (transactions) =>
  transactions?.map((transaction) => ({
    id: transaction.id,
    travel: transaction.travel,
    ticket_number: transaction.ticket_number,
    transaction_date: transaction.transaction_date,
    dollar_price: transaction.dollar_price,
    description: transaction.description,
    created_at: transaction.created_at,
    syrian_payed: transaction.syrian_payed,
    dollar_payed: transaction.dollar_payed,
  }));

export const getReceivableDetails = (receivableDetails) =>
  receivableDetails &&
{
    // account_name: receivableDetails.account_name,
    syrian_price_to_be_paid: receivableDetails.syrian_price_to_be_paid,
    dollar_price_to_be_paid: receivableDetails.dollar_price_to_be_paid,
    syrian_price_to_be_received: receivableDetails.syrian_price_to_be_received,
    dollar_price_to_be_received: receivableDetails.dollar_price_to_be_received,
  };

  

