import React from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  editPassword,
  loginUser,
} from "./redux/service";
import { LoginSchema } from "./schema";
import Validation from "../../services/Validation";
import Login from "./Login";
import useHeaders from "../../hooks/useHeaders";
import EditPassword from "./EditPassword";
import { editPasswordSchema } from "./validationSchema";
export default function AuthController({ child,open,setOpen }) {
  const [errors, setErrors] = useState({});
 
  const [cookies] = useCookies([
    "token",
    "user",
    "phoneNumber",
    "email",
    "active",
  ]);
  const dispatch = useDispatch();
  const headers=useHeaders()


  const loginSubmit = async (login) => {
    const loginValidationErrors = Validation.validation(LoginSchema, {
      username: login.username,
      password: login.password,
    });
    if (loginValidationErrors) {
      setErrors(loginValidationErrors);
    } else {
      dispatch(loginUser({ login, headers})).then((res) => {
        if (res.error) {
          //TODO handle error
        } else {
         //console.log(res);
          document.cookie= `token=${res?.payload?.headers?.authorization}; path=/;`;
          document.cookie= `user=${JSON.stringify(res?.payload?.data?.data)}; path=/;`;
          window.location=res?.payload?.data?.data?.role=='Manager'?'/funds':'/tickets'

        }
      });
    }
  };

  const _editPassword = async (password) => {
    //console.log(password);
    const editPAsswordValidationErrors = Validation.validation(editPasswordSchema,password );
    if (editPAsswordValidationErrors) {
      setErrors(editPAsswordValidationErrors);
    } else {
      dispatch(editPassword({ body:password, headers})).then((res) => {
        if (res.error) {
          //TODO handle error
        } else {
         //console.log(res);
          document.cookie= ``;
          window.location='/'

        }
      });
    }
  };


  const initChild = () => {
    switch (child) {
      case "login": {
        return (
          <Login submit={loginSubmit} errors={errors} setErrors={setErrors} />
        );
      }

      case "edit-password": {
        return (
          <EditPassword open={open} setOpen={setOpen}  editPassword={_editPassword} errors={errors} setErrors={setErrors} />
        );
      }

      default:
    }
  };

  return (
    <div>
      {cookies.token && <Navigate to="/" />}
      <ToastContainer />
      {initChild()}
    </div>
  );
}
