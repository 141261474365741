import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Header, LoadingDialog } from "../../components";
import { ADD_TICKET_FORM, ADD_TICKET_FORM_SECOUND, FLAT_COMMISSION_FORM, PLUS_COMMISSION_FORM, RATIO_COMMISSION_FORM } from "./form";
import { useDispatch, useSelector } from "react-redux";
import { companySelector } from "../company/redux/slice";
import { customerSelector } from "../customer/redux/slice";
import { supplierSelector } from "../supplier/redux/slice";
import ticketImage from './—Pngtree—golden ticket_9017670.png'
import {
  getCompanyData,
  getConstantData,
  getCustomerData,
  getSupplierData,
} from "../../services/functions";
import { ticketSelector, ticketSlice } from "./redux/slice";
import CustomerReceivableDetailsDialog from "../../components/CustomerReceivableDetailsDialog";
import { constantSelector } from "../constant/redux/slice";
import { SectionStyle } from "../../Styles/Styles";

const AddTicket = ({
  addTicket,
  errors,
  setErrors,
  getCustomers,
  getCustomerRecivable,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { companys } = useSelector(companySelector);
  const { constants } = useSelector(constantSelector);

  const { customers } = useSelector(customerSelector);
  const { suppliers } = useSelector(supplierSelector);
  const { receivableDetails } = useSelector(ticketSelector);

  const dispatch = useDispatch();
  //console.log(constants);
  // const receivableDetails = [
  //   {
  //     ticket_id: 44,
  //     travel: "رحلة دمشق دبي",
  //     ticket_number: "XD230DVdV9",
  //     status: "Opened",
  //     currency: "SYP",
  //     restAmount: 4900000,
  //     customer_name: "إيلي قرا",
  //   },
  //   {
  //     ticket_id: 48,
  //     travel: "رحلة دمشق دبي",
  //     ticket_number: "XD230UVdV9",
  //     status: "Opened",
  //     currency: "SYP",
  //     restAmount: 900000,
  //     customer_name: "إيلي قرا",
  //   },
  //   {
  //     ticket_id: 44,
  //     travel: "رحلة دمشق دبي",
  //     ticket_number: "XD230DVdV9",
  //     status: "Opened",
  //     currency: "SYP",
  //     restAmount: 4900000,
  //     customer_name: "إيلي قرا",
  //   },
  //   {
  //     ticket_id: 48,
  //     travel: "رحلة دمشق دبي",
  //     ticket_number: "XD230UVdV9",
  //     status: "Opened",
  //     currency: "SYP",
  //     restAmount: 900000,
  //     customer_name: "إيلي قرا",
  //   },
  // ];

  const { t } = useTranslation();
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  
  const formattedDate = `${year}-${month}-${day}`;
  const [dialog, setDialog] = useState(false)
  const [ticket, setTicket] = useState({
    travel: "",
    ticket_number: "",
    customer_id: "",
    customer_name: "",
    supplier_id: "",
    customer_price:'',
    supplier_commission:'',
    company_id: "",
    ticket_price: "",
    pay_price_sy: "",
    pay_price_dollar: "",
    release_date: formattedDate,
    description: "",
    ticket_fare:"",
    supplier_deal_type:'',
    currency_id:""
  });
  const [openReceivableDetails, setopenReceivableDetails] = useState(false);
  const [fieldsData, setFieldsData] = useState({});
  const [selectData, setSelectData] = useState({
    currency_id:[],
    customer_name: [],
    supplier_id: [],
    company_id: [],
    supplier_deal_type:[{id:'RATIO',value:'RATIO'},{id:'FLAT_COMMISSION',value:'FLAT_COMMISSION'}]
  });

  const handleFormSubmit = () => {
    delete ticket['""']
    addTicket(ticket, suppliers, companys);
  };

  const submitSearchCustomer = (value) => {
    console.log(value);
    //console.log(value);
    setDialog(true)
    getCustomers({ name: value });

    setErrors((prevErrors) => ({ ...prevErrors, customer_name: "" }));
  };

  const submitCustomer = (cus) => {
    if(cus==undefined){
      dispatch(ticketSlice.actions.clearReceivableDetails('')) 
    }
    else{
     
      const customer = cus;
      console.log(customer);
      const id = customer?.id;
      const name=customer?.name
      // console.log(name);
      // console.log(id);
      setTicket({ ...ticket, customer_id: id,customer_name:name });
      setErrors((prevErrors) => ({ ...prevErrors, customer_name: "" }));
  
      if (customer.is_receivable) {
        getCustomerRecivable(id);
      }
      else
      {
        console.log("clear");
       dispatch(ticketSlice.actions.clearReceivableDetails('')) 
  
      }
    }
   
  };
  //console.log(receivableDetails);



  const handleChange = (event) => {
    // //console.log(event);
    const { name, value } = event.target;


     if (name == "ticket_price_dollar" || name == "ticket_price_sy") {
      const name2 =
        name == "ticket_price_dollar"
          ? "ticket_price_sy"
          : "ticket_price_dollar";
      const name3=name == "ticket_price_dollar"
      ? "ticket_price_sy_fare"
      : "ticket_price_dollar_fare";
      setTicket({ ...ticket, [name]: value, [name2]: " " ,[name3]:' '});
      setSelectData({...selectData,currency_id:getConstantData(constants)})

      setErrors((prevErrors) => ({ ...prevErrors, [name]: "", [name2]: "",[name3]:'' }));
    } 
    else if(name==='supplier_id'){
      //console.log(suppliers);
      const supplier=suppliers.filter((supplier)=>(supplier.name==value))[0]
      if(supplier?.currency_id!==4&&supplier?.currency_id!==1&&supplier?.currency_id!=null){
        //console.log("diab");
      setTicket({ ...ticket, [name]: value,['currency_id']:supplier?.currency });
      setSelectData({...selectData,currency_id:[{id:supplier?.currency_id,value:supplier?.currency}]})
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
      else{
        setTicket({ ...ticket, [name]: value});
        setSelectData({...selectData,currency_id:getConstantData(constants)})

        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    }
    else {
      
      
      setTicket({ ...ticket, [name]: value });

      setSelectData({...selectData,currency_id:getConstantData(constants)})

      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }

   
  };

  const handleChangeSwitch = (event) => {
    const { name, checked } = event.target;
    setTicket({ ...ticket, [name]: checked });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleColor = (color) => {
    setTicket({ ...ticket, color: color.hex });
    setErrors((prevErrors) => ({ ...prevErrors, color: "" }));
  };


  const handleChangeField = (event, itemName, index) => {
    const { name, value } = event.target;
    //console.log(name, value, index);
    const fields = ticket[itemName];
    fields[index][name] = value;
    setTicket({ ...ticket, [itemName]: fields });
  };
  const addField = (name) => {
    setFieldsData((prevField) => ({
      ...prevField,
      [name]: prevField[name] + 1,
    }));
    const fields = ticket[name];
    fields.push({});
    setTicket((prevTicket) => ({ ...prevTicket, [name]: fields }));
  };

  const deleteField = (name, index) => {
    setFieldsData((prevField) => ({
      ...prevField,
      [name]: prevField[name] - 1,
    }));
    const fields = ticket[name];
    fields.splice(index, 1);
    setTicket({ ...ticket, [name]: fields });
  };

  useEffect(() => {
    //console.log(customers);
    setDialog(false)
    setSelectData({
      ...selectData,
      company_id: getCompanyData(companys),
      customer_name: getCustomerData(customers),
      supplier_id: getSupplierData(suppliers),
      currency_id:getConstantData(constants)
    });
  }, [companys, customers, suppliers,constants]);

  useEffect(() => {
    dispatch(ticketSlice.actions.clearReceivableDetails('')) 
    // Promise.all([getCustomers({}), getCompanies(), getSuppliers()]);
  }, []);


  
  
  console.log(ticket);

  // useEffect(() => {
  //   if(receivableDetails.length>0){
  //     setopenReceivableDetails(true)
  //   }
  // }, [receivableDetails])

  //console.log(customers);

  return (
    <Box m="20px">
      {dialog && <LoadingDialog open={dialog} />}
      
      {openReceivableDetails && (
        <CustomerReceivableDetailsDialog
          tickets={receivableDetails}
          setOpen={setopenReceivableDetails}
          open={openReceivableDetails}
        />
      )}
      <Header title="Ticket" subtitle="a New Ticket" />
      {receivableDetails.length > 0 && (
        <Button
          sx={{ bottom: "50px", float: "right" }}
          onClick={() => {
            setopenReceivableDetails(true);
          }}
        >
          {t("Show Receivable Detial")}
        </Button>
      )}
    
        <Grid style={{width:'90%',margin:'auto'}} container  spacing={4}>
          <Grid lg={7} >
          <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        width="100% "
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
          <Form
          submitSearchAutoComplete={submitSearchCustomer}
          submitAutoComplete={submitCustomer}
          setValue={setTicket}
          
          selectData={selectData}
          handleColor={handleColor}
          handleChangeSwitch={handleChangeSwitch}
          errors={errors}
          data={ticket}
          handleChange={handleChange}
          type="add"
          form={ADD_TICKET_FORM}
        />
        
         </Box>
    
          </Grid>
          {/* <Grid lg={2}/> */}

          <Grid  lg={5}>
          <SectionStyle className="image" sx={{ height:'300px', background: `url(https://www.freeiconspng.com/thumbs/hd-tickets/ticket-png-clipart-in-circle-design-21.png)`,backgroundSize:'contain',backgroundRepeat:'no-repeat', display: {lg:'flex', xs: "none", md: "none",s:'none'} }}></SectionStyle>

              
          </Grid>
        
         
        </Grid>
        <Divider sx={{marginTop:'30px'}}/>

        <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        width="100% "
        sx={{marginTop:'30px',
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >

          <Form
          submitSearchAutoComplete={submitSearchCustomer}
          submitAutoComplete={submitCustomer}
          setValue={setTicket}
          selectData={selectData}
          handleColor={handleColor}
          handleChangeSwitch={handleChangeSwitch}
          errors={errors}
          data={ticket}
          handleChange={handleChange}
          type="add"
          form={ADD_TICKET_FORM_SECOUND}
        />
        
         </Box>

        <Divider sx={{marginTop:'30px'}}/>

    
        <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        width="100% "
        sx={{marginTop:'30px',
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        {ticket.supplier_deal_type==='PLUS_COMMISSION'&&<Form
          
          setValue={setTicket}
          selectData={selectData}
          handleColor={handleColor}
          handleChangeSwitch={handleChangeSwitch}
          errors={errors}
          data={ticket}
          handleChange={handleChange}
          type="add"
          form={PLUS_COMMISSION_FORM}
        />}

{ticket.supplier_deal_type==='FLAT_COMMISSION'&&<Form
          
          setValue={setTicket}
          selectData={selectData}
          handleColor={handleColor}
          handleChangeSwitch={handleChangeSwitch}
          errors={errors}
          data={ticket}
          handleChange={handleChange}
          type="add"
          form={FLAT_COMMISSION_FORM}
        />}

{ticket.supplier_deal_type==='RATIO'&&<Form
          
          setValue={setTicket}
          selectData={selectData}
          handleColor={handleColor}
          handleChangeSwitch={handleChangeSwitch}
          errors={errors}
          data={ticket}
          handleChange={handleChange}
          type="add"
          form={RATIO_COMMISSION_FORM}
        />}

        </Box>
     

      <Box display="flex" justifyContent="end" mt="20px">
        <Button
          onClick={() => {
            handleFormSubmit();
          }}
          type="submit"
          color="secondary"
          variant="contained"
        >
          {t("New Ticket")}
        </Button>
      </Box>
    </Box>
  );
};

export default AddTicket;
