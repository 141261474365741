import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from "react-i18next";
import { GlobalBoxContainer } from "../../Styles/Styles";
import { Actions, DataGridTable, Header } from "../../components";
import { getTicketHeaderMapper, getTicketMapper } from "./mapper";
import { ticketSelector } from "./redux/slice";
import { useState } from "react";
import { Filter } from "../../components/Filter";
import SearchField from "../../components/SearchField";
import { GENERAL_DAILY_FILTER_FORM } from "../generalDaily/form";
import VoidTicket from "./VoidTicket";
import RefundTicket from "./RefundTicket";
import ReissueTicket from "./ReissueTicket";
import { autoScroll } from "../../services/functions";
const currentDate = new Date();

const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const day = String(currentDate.getDate()).padStart(2, '0');

const formattedDate = `${year}-${month}-${day}`;
const Tickets = ({ getTickets,voidTicketSubmit,refindTicketSubmit,reissueTicketSubmit,errors,setErrors,deleteTicket }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { tickets,total } = useSelector(ticketSelector)
  const [voidOpen, setVoidOpen] = useState({
    open:false,
    id:''
  })
  const [refundOpen, setRefundOpen] = useState({
    open:false,
    id:''
  })
  const [reissueOpen, setReissueOpen] = useState({
    open:false,
    id:'',
    ticket:{}
  })
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({
    min_date:'',
    max_date:''
  })

  const [search, setSearch] = useState('')

  const [paginationModel, setPaginationModel] = useState({
    page:0,
    pageSize:100
  })

  const handleFilter=()=>{
    setPaginationModel({...paginationModel,page:1})

    setOpen(false)
    getTickets({search:search?search:undefined,page:1,limit:paginationModel.pageSize,min_date:filter.min_date?filter.min_date:undefined,max_date:filter.max_date?filter.max_date:undefined})
    
  }
  const getSearchResults=()=>{

    setPaginationModel({...paginationModel,page:0})
    // getTickets({search:search?search:undefined,page:1,limit:paginationModel.pageSize})


  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleFilterOpen = (item) => {
    // //console.log(deleteSubmit);
  
    handleClickOpen();
  };

  const voidTicket=(id)=>{
    setVoidOpen({open:true,id})
  }

  const handleVoidTicketClose = () => {
    setVoidOpen({open:false,id:''});
  };
  const voidTicketOk=(_void)=>{
    // handle void ticket
    voidTicketSubmit(_void)
    setVoidOpen({id:'',open:false})
    //console.log("void ticket");
  }

  const refundTicket=(id)=>{
    setRefundOpen({open:true,id})
  }
  const reissueTicket=(id,ticket)=>{
    setReissueOpen({open:true,id,ticket})
  }

  const handleRefundTicketClose = () => {
    setRefundOpen({open:false,id:''});
  };
  const handleReissueTicketClose = () => {
    setReissueOpen({open:false,id:'',ticket:{}});
  };
  const refundTicketOk=(refund)=>{
    // handle refund ticket
    refindTicketSubmit(refund)
    setRefundOpen({id:'',open:false})
    

  }

  const reissueTicketOk=(reissue)=>{
    // handle refund ticket
    reissueTicketSubmit(reissue)
    setReissueOpen({id:'',open:false})
    

  }
  useEffect(() => {
    getTickets({page:paginationModel.page+1,limit:paginationModel.pageSize})
  }, [])

  useEffect(() => {
    getTickets({search:search?search:undefined,page:1,limit:paginationModel.pageSize,min_date:filter.min_date?filter.min_date:undefined,max_date:filter.max_date?filter.max_date:undefined})


  }, [paginationModel.page,paginationModel.pageSize])

  useEffect(() => {
    if(search)
    getTickets({page:paginationModel.page+1,limit:paginationModel.pageSize,search,min_date:filter.min_date?filter.min_date:undefined,max_date:filter.max_date?filter.max_date:undefined})

    else
    getTickets({page:paginationModel.page+1,limit:paginationModel.pageSize,min_date:filter.min_date?filter.min_date:undefined,max_date:filter.max_date?filter.max_date:undefined})


  }, [search])

  
  
  


  return (
    <Box m="20px">
      {voidOpen.open&&<VoidTicket id={voidOpen.id} onClose={handleVoidTicketClose} onOk={voidTicketOk} open={voidOpen.open}/>}
      {refundOpen.open&&<RefundTicket id={refundOpen.id} onClose={handleRefundTicketClose} onOk={refundTicketOk} open={refundOpen.open}/>}
      {reissueOpen.open&&<ReissueTicket ticket={reissueOpen.ticket} id={reissueOpen.id} onClose={handleReissueTicketClose} onOk={reissueTicketOk} open={reissueOpen.open}/>}


      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header viewAll={true} title="Tickets" subtitle={"Welcome to your " + "tickets"} />
      </Box>
    <Filter open={open} setOpen={setOpen}  data={filter} errors={errors} setErrors={setErrors} setData={setFilter} form={GENERAL_DAILY_FILTER_FORM} handleFilter={handleFilter}  />
    <Button sx={{ bgcolor: '#0066cc',marginRight:'2%',top:'10px'}} variant='contained'  onClick={ handleFilterOpen}>{t('Filter')}</Button>
      <SearchField getSearchResults={getSearchResults}  label={"Search Customer name or ticket number"} placeholder={'Enter Customer name or ticket number'} setValue={setSearch} value={search} />
      <Button sx={{ bgcolor: '#0066CC',top:'10px',right:'1%' }} variant='contained' startIcon={<AddIcon />} onClick={() => { navigate('/tickets/add') }}>{t('Add')}</Button>
      {/* <Button sx={{ bgcolor: '#0066CC',top:'10px',right:'2%' }} variant='contained' startIcon={<AddIcon />} onClick={() => { navigate('/tickets/add-returned') }}>{t('Add Returned Ticket')}</Button> */}


      <GlobalBoxContainer id='ddddd'>
        <DataGridTable total={total} paginationModel={paginationModel} setPaginationModel={setPaginationModel} rows={getTicketMapper(tickets)} columns={getTicketHeaderMapper(tickets).concat(Actions("", "",'','','','','',voidTicket,refundTicket,reissueTicket,'edit/','',deleteTicket
        )).reverse()} />
      </GlobalBoxContainer>
    </Box>
  );
};

export default Tickets;