import React, {  useEffect, useState } from "react";
import useHeaders from "../../hooks/useHeaders";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addConstant,deleteConstant,editConstant,getConstant,getConstants
} from "./redux/service";
import { constantSelector,constantSlice} from "./redux/slice";
import { useSidebarContext } from "../../components/global/sidebar/sidebarContext";
import Constants from "./Constants";
import AddConstant from "./AddConstant";
import EditConstant from "./EditConstant";
import { LoadingDialog } from "../../components";
import Validation from "../../services/Validation";
import { addConstantSchema, editConstantSchema } from "./validationSchema";

function ConstantsController({ child }) {
  const { setSelected } = useSidebarContext();
  const { logging } = useSelector(constantSelector);
  const navigate = useNavigate();
  const headers = useHeaders();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({})
  const _getConstants = async (params) => {
    dispatch(getConstants({ headers,params }))
  };
  const _deleteConstant = async (id) => {
    dispatch(deleteConstant({ headers, id })).then((res) => {
      if (res.error) {
      } else {
        dispatch(constantSlice.actions.delete(id));
      }
    });
  };
  const _addConstant = async (constant) => {
  
     const constantValidationErrors = Validation.validation(addConstantSchema, constant);
     if (constantValidationErrors) {
      setErrors(constantValidationErrors);
    } else {
    const _constant={
      ...constant
     }
       dispatch(addConstant({ headers,constant:_constant })).then((res) => {
         if (res.error) {
         } else {
           navigate("/constants");
         }
       });
      }
  
    
  };



  const _getConstant = async (id) => {
    dispatch(getConstant({ headers, id }));
  };
  const _editConstant = async (id, constant) => {
    const constantValidationErrors = Validation.validation(editConstantSchema, constant);
    if (constantValidationErrors) {
     setErrors(constantValidationErrors);
   } else {
    const _constant={...constant}
      dispatch(editConstant({ headers, constant: _constant,id})).then((res) => {
        if (res.error) {
        } else {
          navigate("/constants");
        }
      });
    }
    }


  useEffect(() => {
    setSelected&& setSelected("Constants");
  }, []);

  const initChild = () => {
    switch (child) {
      case "constants": {
        return <Constants deleteConstant={_deleteConstant} getConstants={_getConstants}/>;
      }
      case "add-constant":
        return (
          <AddConstant
        addConstant={_addConstant}
        errors={errors}
        setErrors={setErrors}
          />
        );

      case "edit-constant":
        return (
          <EditConstant
          editConstant={_editConstant}
          getConstant={_getConstant}
          errors={errors}
          setErrors={setErrors}
          />
        );

      default:
    }
  };
  return (
    <div>
      {logging && <LoadingDialog open={logging} />}

      {initChild()}
    </div>
  );
}

export default ConstantsController;
