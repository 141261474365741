import { createSlice } from "@reduxjs/toolkit"
import { addFund, deleteFund, editFund, getFunds, getFund, addBalance } from "./service"
import { fulfilledReducer, pendingReducer, rejectedReducer } from "../../../redux/globalReducer"
const initialState = {
    status: '',
    logging: false,
    funds:[],
    transaction_total:0,
    receivable_details_total:0,
    fund:{},
}
export const fundSlice = createSlice({
    name: 'fund',
    initialState,
    reducers: {
        delete(state,action){
            return {
                ...state,funds:[...state.funds].filter((fund)=>fund.id!=action.payload)
            }
       
        }
    },
    extraReducers: {
        [getFunds.pending]: pendingReducer,
        [getFunds.fulfilled]: (state, { payload }) => {
            state.funds= payload?payload:[]
            state.transaction_total=payload?payload.transaction_total:state.transaction_total
            state.receivable_details_total=payload?payload.receivable_details_total:state.receivable_details_total

            state.status = 'fulfilled'
            state.logging = false
        },
        [getFunds.rejected]: rejectedReducer,

        [deleteFund.pending]: pendingReducer,
        [deleteFund.fulfilled]: fulfilledReducer,
        [deleteFund.rejected]: rejectedReducer,

        
        [addFund.pending]: pendingReducer,
        [addFund.fulfilled]: fulfilledReducer,
        [addFund.rejected]: rejectedReducer,

        [addBalance.pending]: pendingReducer,
        [addBalance.fulfilled]: fulfilledReducer,
        [addBalance.rejected]: rejectedReducer,

        [getFund.pending]: pendingReducer,
        [getFund.fulfilled]: (state, { payload }) => {
           
            state.fund= payload?payload:{}
            state.status = 'fulfilled'
            state.logging = false
        },
        [getFund.rejected]: pendingReducer,

        [editFund.pending]: pendingReducer,
        [editFund.fulfilled]: fulfilledReducer,
        [editFund.rejected]: rejectedReducer,
 
    }
})

export const fundSelector = (state) => state.fund;