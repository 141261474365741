import Joi from "joi-browser";

export const addRecivableSchema = {
    customer_id: Joi.string().allow(null)
    .error(() => {
      return {
        message: "Please enter a customer id",
      };
    }),

    amount_sy: Joi.any()
    .error(() => {
      return {
        message: "Please enter price in syrian pounds ",
      };
    }),

    amount_dollar: Joi.when('amount_sy',{
      is:Joi.number(),
      then: Joi.any(),
      otherwise:Joi.number().required() 
    })
    .error(() => {
      return {
        message: "Please enter price in dollar ",
      };
    }),

    description: Joi.any(),


};

export const editRecivableSchema = {
  
};
