export const ADD_Recivable_FORM =
  [
    {
      feild:'select',
      type: "text",
      label: "Customer",
      data:[],
      name: "customer_id",
      grid: "4",
    },

    {
      feild:'textField',
      type: "text",
      label: "Pay price sy",
      name: "amount_sy",
      grid: "2",
    },
   

    {
      feild:'textField',
      type: "text",
      label: "Pay price dollar",
      name: "amount_dollar",
      grid: "2",
    },

    {
      feild:'textField',
      type: "text",
      label: "description",
      name: "description",
      grid: "4",
      multiline:true,
      rows:4
    },

   

    
  ];

  export const EDIT_Recivable_FORM =
  [
    
  ];