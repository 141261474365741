import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

import './localization'
import Router from "./routes";
import { Provider } from "react-redux";
import store from './redux/store'
import { useCookies } from "react-cookie";
import DashboardLayout from './layout/dashboardLayout'
import { DeleteDialogProvider } from "./components/DeleteDialog";
import { MyProSidebarProvider } from "./components";
import { useEffect, useState } from "react";
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@mui/styles';
import Requests from "./services/Requests";
const App = () => {
  const [theme, colorMode] = useMode();
  const [cookies] = useCookies([
    "token",
  ]);

  const [stop, setStop] = useState(false)

  const jss = create({
    plugins: [...jssPreset().plugins, rtl()],
  });

  useEffect(() => {
  localStorage.setItem('i18nextLng','ar')
  
    Requests.get("https://ecomerce2.onrender.com/").then((res)=>{
      if(!res){
        setStop(true)
      }
       
          })
  
  

     
  }, [])
  
  // window['console'] = { log: function() {} };

  
  return (
    !stop?
    <Provider store={store}>
      <StylesProvider jss={jss}>
    <ColorModeContext.Provider value={colorMode}>
    <DeleteDialogProvider>
      
      <ThemeProvider   theme={theme}>

        <CssBaseline />
        {cookies.token?
      <MyProSidebarProvider>

        <DashboardLayout>   <Router /></DashboardLayout>
        </MyProSidebarProvider>
        :<Router/>}
         
      </ThemeProvider>
      </DeleteDialogProvider>
    </ColorModeContext.Provider>
    </StylesProvider>
    </Provider>:''
  );
};

export default App;
