export const ADD_CUSTOMER_FORM =
  [
    {
      feild:'textField',
      type: "text",
      label: "Name",
      name: "name",
      grid: "2",
    },

  ];

  export const EDIT_CUSTOMER_FORM =
  [
    {
      feild:'textField',
      type: "text",
      label: "Name",
      name: "name",
      grid: "2",
    },

    {
      feild:'switch',
      type: "checkbox",
      label: "Receivable",
      name: "is_receivable",
      grid: "2",
      disabled:false
    },
  ];