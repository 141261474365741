import { Typography, useTheme } from "@mui/material";
import { MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { tokens } from "../theme";


const SideBarItem = ({ title, to, icon, selected, setSelected }) => {
  //console.log(to);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    return (
      <MenuItem
        active={selected === title}
        style={{ color: "#0066CC" }}
        onClick={() => setSelected(title)}
        icon={icon}
        component={<Link to={to} />}
      
      >
        <Typography sx={{fontWeight:'bold'}}>{title}</Typography>
      </MenuItem>
    );
  };

  export default SideBarItem