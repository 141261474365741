import React from 'react'
import { useTranslation } from 'react-i18next'

function ReceivableDetails({ data,title }) {
    const {t}=useTranslation()
    //console.log(data);
  return (
    <div id="accountStatement" className="object-viewer-container">
    <h1 className="object-viewer-header">{t(title)}</h1>
    <table className="object-viewer-table">
      <thead>
       
      </thead>
      <tbody>
        {data&&Object.keys(data).map((property) => (
          <tr key={property}>
            <td>{t(property.split("_").map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1) ).join(" "))}</td>
            <td style={{ color:'#000'}}>{data[property]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  )
}

export default ReceivableDetails