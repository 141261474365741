import { createAsyncThunk } from "@reduxjs/toolkit";
import Auth from "../AuthApi";
export const loginUser = createAsyncThunk(
  "authSlice/login",
  async ({ login, headers }, { rejectWithValue }) => {
    return await Auth.login(headers, login, () => {
      throw new Error("Failed authentication");
    });
  }
);

export const sendCodeToEmail = createAsyncThunk(
  "authSlice/sendCodeToEmail",
  async ({ email, headers }, { rejectWithValue }) => {
    //console.log(email);
    return await Auth.sendCodeToEmail(headers, email, () => {
      throw new Error("Failed activation");
    });
  }
);

export const resetPassword = createAsyncThunk(
  "authSlice/resetPassword",
  async ({ email, code, password, headers }, { rejectWithValue }) => {
    return await Auth.resetPassword(headers, { code, password, email }, () => {
      throw new Error("Failed activation");
    });
  }
);

export const editPassword = createAsyncThunk(
  "authSlice/editPassword",
  async ({ body, headers }, { rejectWithValue }) => {
    return await Auth.editPassword(headers,body, () => {
      throw new Error("Failed edit password");
    });
  }
);

export const logout = createAsyncThunk(
  "authSlice/logout",
  async ({  headers }, { rejectWithValue }) => {
      return await Auth.logout(headers, () => {
      throw new Error("Failed logout");
    });
  }
);
