import Joi from "joi-browser";

export const addFundSchema = {
  
};
export const addBalanceSchema = {
    dollar_balance: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter  Dollar balance",
      };
    }),

    syrian_balance: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter  name",
      };
    }),
};

export const editFundSchema = {
  
};
