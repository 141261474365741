import ColorPicker from "react-pick-color";
import GeneralLabel from "./GeneralLabel";
export default function GeneralColorPicker({color,handleColor,label,labelColor,labelVarient}) {
//console.log(label);

  return (
    <div>
    <GeneralLabel color={labelColor} variant={labelVarient} >
      {label}
    </GeneralLabel>
    <ColorPicker  color={color} onChange={handleColor} />
    </div>
    
  );
}