import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from "react-i18next";
import { GlobalBoxContainer } from "../../Styles/Styles";
import { Actions, DataGridTable, Header } from "../../components";
import { getCompanyHeaderMapper, getCompanyMapper } from "./mapper";
import { companySelector } from "./redux/slice";

const Companys = ({ getCompanys, deleteCompany }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { companys } = useSelector(companySelector)
  useEffect(() => {
    getCompanys({account_type:'Company'})
  }, [])

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header viewAll={true}  title="Companys" subtitle={"Welcome to your " + "companys"} />
      </Box>
      <Button sx={{ bgcolor: '#0066CC' }} variant='contained' startIcon={<AddIcon />} onClick={() => { navigate('/companys/add') }}>{t('Add')}</Button>
      <GlobalBoxContainer>
        <DataGridTable rows={getCompanyMapper(companys)} columns={getCompanyHeaderMapper(companys).concat(Actions(deleteCompany, "edit/")).reverse()} />
      </GlobalBoxContainer>
    </Box>
  );
};

export default Companys;