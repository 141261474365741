import Joi from "joi-browser";

export const addTransactionSchema = {
    account_id: Joi.number()
    .required()
    .error(() => {
      return {
        message: "Please choose a account ",
      };
    }),

    account_name: Joi.any()
    .required()
    .error(() => {
      return {
        message: "Please choose a account ",
      };
    }),

      pay_price_sy: Joi.any()
      .error(() => {
        return {
          message: "Please enter price in syrian pounds ",
        };
      }),

      pay_price_dollar: Joi.when('pay_price_sy',{
        is:Joi.number(),
        then: Joi.any(),
        otherwise:Joi.number().required() 
      })
      .error(() => {
        return {
          message: "Please enter price in dollar ",
        };
      }),

    

    account_type: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please choose a account type ",
      };
    }),

    description: Joi.any(),
    date: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please choose a date ",
      };
    }),

    reverse_transaction: Joi.any()
    .error(() => {
      return {
        message: "Please enter price in syrian pounds ",
      };
    }),

    bank_transfer: Joi.any()
    .error(() => {
      return {
        message: "Please enter price in syrian pounds ",
      };
    }),

};

export const editTransactionSchema = {
    account_id: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please choose a account ",
      };
    }),

    pay_price_sy: Joi.number()
    .required()
    .error(() => {
      return {
        message: "Please enter price in syrian pounds ",
      };
    }),

    pay_price_dollar: Joi.number()
    .required()
    .error(() => {
      return {
        message: "Please enter price in dollar ",
      };
    }),

    account_type: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please choose a account type ",
      };
    }),

    description: Joi.any(),
    date: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please choose a date ",
      };
    }),
};
