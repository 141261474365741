import { createAsyncThunk } from "@reduxjs/toolkit";
import Fund from "../FundApi";

export const getFunds= createAsyncThunk(
  "fundSlice/getFunds",
  async ({ headers,params }, { rejectWithValue }) => {
    return await Fund.getFunds(headers,params, () => {
      return rejectWithValue();
    });
  }
);
export const deleteFund = createAsyncThunk(
  "fundSlice/deleteFund",
  async ({ headers, id }, { rejectWithValue }) => {
    return await Fund.deleteFund(headers, id, () => {
      throw new Error("Failed delete fund");
    });
  }
);

export const addFund = createAsyncThunk('fundSlice/addFund', async ({ headers,fund }, { rejectWithValue }) => {
  return await Fund.addFund(headers, fund,()=>{
    throw new Error("Failed store fund")});
})
export const addBalance = createAsyncThunk('fundSlice/addBalance', async ({ headers,balance }, { rejectWithValue }) => {
  return await Fund.addBalance(headers, balance,()=>{
    throw new Error("Failed store balance")});
})

export const getFund = createAsyncThunk(
  "fundSlice/getFund",
  async ({ headers,id }, { rejectWithValue }) => {
    return await Fund.getFund(headers,id, () => {
      return rejectWithValue();
    });
  }
);

export const editFund = createAsyncThunk('fundSlice/editFund', async ({ headers,fund,id }, { rejectWithValue }) => {
  return await Fund.editFund(headers, fund,id,()=>{
    throw new Error("Failed edit fund")});
})