import React, { useEffect, useRef } from "react";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from "react-i18next";
import { GlobalBoxContainer } from "../../Styles/Styles";
import { Actions, DataGridTable, Header, ReceivableDetails, useSidebarContext } from "../../components";
import { getRecivableDetails, getRecivableHeaderMapper, getRecivableMapper } from "./mapper";
import { RecivableSelector } from "./redux/slice";
import { deleteNullValues } from "../../services/functions";
import { useState } from "react";
import ReactToPrint from "react-to-print";

const Recivables = ({ getRecivables }) => {
  const navigate = useNavigate()
  const componentRef = useRef()

  const { t } = useTranslation()
  const { Recivables,transaction_total,
    receivable_details_total } = useSelector(RecivableSelector)
  const {sidebarWidth} = useSidebarContext();
  const [transactionPaginationModel, setTransactionPaginationModel] = useState({
    page:0,
    pageSize:100

  })

  const [receivableDetailsPaginationModel, setReceivableDetailsPaginationModel] = useState({
    page:0,
    pageSize:100

  })
  useEffect(() => {
  }, [])

  useEffect(() => {
    getRecivables({transactions_page:transactionPaginationModel.page+1,receivable_details_page:receivableDetailsPaginationModel.page+1,transactions_limit:transactionPaginationModel.pageSize,receivable_details_limit:receivableDetailsPaginationModel.pageSize,account_type:"Fund"})

  }, [transactionPaginationModel.page,transactionPaginationModel.pageSize])

  useEffect(() => {
    getRecivables({transactions_page:transactionPaginationModel.page+1,receivable_details_page:receivableDetailsPaginationModel.page+1,transactions_limit:transactionPaginationModel.pageSize,receivable_details_limit:receivableDetailsPaginationModel.pageSize,account_type:"Fund"})

  }, [receivableDetailsPaginationModel.page,receivableDetailsPaginationModel.pageSize])

//console.log(Recivables);
  return (
    <div style={{overflow: 'auto'}}>
         <ReactToPrint


trigger={() => (
  <Button sx={{marginRight:'5%'}} variant="contained" color="primary">
    {t("Print Recivables")}
  </Button>
)}
content={() => componentRef.current}
/>

    
    <Box ref={componentRef} sx={{width:`calc(100%-${sidebarWidth+'px'})`}} m="20px">
    
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header viewAll={true} title="Recivables" subtitle={"Welcome to your " + "Recivables"} />
      </Box>
      <Button sx={{ bgcolor: '#0066CC' }} variant='contained' startIcon={<AddIcon />} onClick={() => { navigate('/recivables/add') }}>{t('Add')}</Button>
      <GlobalBoxContainer>
       <ReceivableDetails title='Recivable Information' data={deleteNullValues(getRecivableDetails(Recivables)) }  />
        {/*Recivables?.transactions?.length&&
        <div>
    <h1 style={{color:"#535AC8"}} align="center" >{t('Transactions')}</h1>

        <DataGridTable setPaginationModel={setTransactionPaginationModel} paginationModel={transactionPaginationModel} total={transaction_total} rows={getRecivableMapper(Recivables?.transactions)} columns={getRecivableHeaderMapper(Recivables?.transactions)} />
        </div>
        } */}

{Recivables?.receivable_details?.length?
        <div>
    <h1 style={{color:"#535AC8"}} align="center" >{t('Receivable Details')}</h1>

      <DataGridTable setPaginationModel={setReceivableDetailsPaginationModel} paginationModel={receivableDetailsPaginationModel} total={receivable_details_total} rows={getRecivableMapper(Recivables?.receivable_details)} columns={getRecivableHeaderMapper(Recivables?.receivable_details).reverse()} />
        </div>
        :''
        }
      </GlobalBoxContainer>
    </Box>
    </div>
  );
};

export default Recivables;