import { createAsyncThunk } from "@reduxjs/toolkit";
import Supplier from "../SupplierApi";

export const getSuppliers= createAsyncThunk(
  "supplierSlice/getSuppliers",
  async ({ headers,params }, { rejectWithValue }) => {
    return await Supplier.getSuppliers(headers,params, () => {
      return rejectWithValue();
    });
  }
);
export const deleteSupplier = createAsyncThunk(
  "supplierSlice/deleteSupplier",
  async ({ headers, id }, { rejectWithValue }) => {
    return await Supplier.deleteSupplier(headers, id, () => {
      throw new Error("Failed delete supplier");
    });
  }
);

export const addSupplier = createAsyncThunk('supplierSlice/addSupplier', async ({ headers,supplier }, { rejectWithValue }) => {
  return await Supplier.addSupplier(headers, supplier,()=>{
    throw new Error("Failed store supplier")});
})

export const getSupplier = createAsyncThunk(
  "supplierSlice/getSupplier",
  async ({ headers,id }, { rejectWithValue }) => {
    return await Supplier.getSupplier(headers,id, () => {
      return rejectWithValue();
    });
  }
);

export const editSupplier = createAsyncThunk('supplierSlice/editSupplier', async ({ headers,supplier,id }, { rejectWithValue }) => {
  return await Supplier.editSupplier(headers, supplier,id,()=>{
    throw new Error("Failed edit supplier")});
})

export const getAccountStatment = createAsyncThunk(
  "customerSlice/getAccountStatment",
  async ({ headers,id ,params}, { rejectWithValue }) => {
    return await Supplier.getAccountStatement(headers,id,params, () => {
      return rejectWithValue();
    });
  }
);