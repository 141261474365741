import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useMediaQuery} from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {Form, Header } from "../../components";
import { EDIT_FUND_FORM } from "./form";
import { fundSelector } from "./redux/slice";
import { getOneFundMapper } from "./mapper";
import { useParams } from "react-router-dom";

const EditFund = ({editFund,getFund,errors,setErrors}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {fund}=useSelector(fundSelector)
  const {id}=useParams()
  const {t}=useTranslation()
  const [_fund, setFund] = useState({
  
  })
   const [fieldsData, setFieldsData] = useState({
  })

  const handleFormSubmit = () => {
    editFund(id,_fund)
  };
  const handleChange=(event)=>{
      const {name,value}=event.target
      setFund({..._fund,[name]:value})
      setErrors((prevErrors)=>(
        {...prevErrors,
        [name]:''
        }
    ))
  }

  const handleChangeSwitch=(event)=>{
    const {name,checked}=event.target
    setFund({..._fund,[name]:checked})
    setErrors((prevErrors)=>(
      {...prevErrors,
      [name]:''
      }
  ))
}

const handleColor=(color)=>{
     setFund({...fund,color:color.hex})
        setErrors((prevErrors)=>(
      {...prevErrors,
      color:''
      }
  ))
}

const handleChangeField=(event,itemName,index)=>{
    const {name,value}=event.target
    //console.log(name,value,index);
    const fields=fund[itemName]
    fields[index][name]=value
    setFund({...fund,[itemName]:fields})
}
const addField=(name)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]+1
    }
))
    const fields=fund[name]
    fields.push({})
    setFund((prevFund)=>(
      {...prevFund,
      [name]:fields
      }
  ))

}

const deleteField=(name,index)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]-1
    }
))
    const fields=fund[name]
    fields.splice(index,1)
    setFund({...fund,[name]:fields})
}

  useEffect(() => {
   getFund(id)
  }, [])

  useEffect(() => {
    setFund(getOneFundMapper(fund))
  }, [fund])
    
  return (
    <Box m="20px">
      <Header title="Fund" subtitle="Edit a Fund" />
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
           
            <Form handleColor={handleColor} handleChangeSwitch={handleChangeSwitch} errors={errors} data={_fund}  handleChange={handleChange}  form={EDIT_FUND_FORM}  />
          </Box>
         

          <Box display="flex" justifyContent="end" mt="20px">
            <Button onClick={()=>{handleFormSubmit()}} type="submit" color="secondary" variant="contained">
              {t("Edit Fund")}
            </Button>
          </Box>
     
  
    </Box>
  );
};

export default EditFund;
