export const ADD_SUPPLIER_FORM =
  [
    {
      feild:'textField',
      type: "text",
      label: "Name",
      name: "name",
      grid: "2",
    },

    {
      feild:'textField',
      type: "number",
      label: "Commission",
      name: "commission",
      grid: "2",
    },

    {
      feild:'textField',
      type: "text",
      label: "Nationality",
      name: "nationality",
      grid: "2",
    },

    {
      feild: "select",
      type: "text",
      label: "Currency",
      data: [],
      name: "currency_id",
      grid: "2",
    },
  ];

  export const EDIT_SUPPLIER_FORM =
  [
    {
      feild:'textField',
      type: "text",
      label: "Name",
      name: "name",
      grid: "2",
    },

    {
      feild:'textField',
      type: "number",
      label: "Commission",
      name: "commission",
      grid: "2",
    },

    {
      feild:'textField',
      type: "text",
      label: "Nationality",
      name: "nationality",
      grid: "2",
    },

    {
      feild: "select",
      type: "text",
      label: "Currency",
      data: [],
      name: "currency_id",
      grid: "2",
    },
  ];