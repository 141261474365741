import React, {  useEffect, useState } from "react";
import useHeaders from "../../hooks/useHeaders";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCustomer,deleteCustomer,editCustomer,getAccountStatment,getCustomer,getCustomers
} from "./redux/service";
import { customerSelector,customerSlice} from "./redux/slice";
import { useSidebarContext } from "../../components/global/sidebar/sidebarContext";
import Customers from "./Customers";
import AddCustomer from "./AddCustomer";
import EditCustomer from "./EditCustomer";
import { LoadingDialog } from "../../components";
import Validation from "../../services/Validation";
import { addCustomerSchema, editCustomerSchema } from "./validationSchema";
import AccountStatement from "./AccountStatement";
import { ToastContainer } from "react-toastify";

function CustomersController({ child }) {
  const { setSelected } = useSidebarContext();
  const { logging } = useSelector(customerSelector);
  const navigate = useNavigate();
  const headers = useHeaders();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({})
  const _getCustomers = async (params) => {
    dispatch(getCustomers({ headers,params }))
  };

  const _getAccountStatment = async (id,params) => {
    dispatch(getAccountStatment({ headers,id,params }))
  };
  const _deleteCustomer = async (id) => {
    dispatch(deleteCustomer({ headers, id })).then((res) => {
      if (res.error) {
      } else {
        dispatch(customerSlice.actions.delete(id));
      }
    });
  };
  const _addCustomer = async (customer) => {
  
     const customerValidationErrors = Validation.validation(addCustomerSchema, customer);
     if (customerValidationErrors) {
      setErrors(customerValidationErrors);
    } else {
    const _customer={
      ...customer
     }
       dispatch(addCustomer({ headers,customer:_customer })).then((res) => {
         if (res.error) {
         } else {
           navigate("/customers");
         }
       });
      }
  
    
  };



  const _getCustomer = async (id) => {
    dispatch(getCustomer({ headers, id }));
  };
  const _editCustomer = async (id, customer) => {
    const customerValidationErrors = Validation.validation(editCustomerSchema, customer);
    if (customerValidationErrors) {
     setErrors(customerValidationErrors);
   } else {
    const _customer={...customer}
      dispatch(editCustomer({ headers, customer: _customer,id})).then((res) => {
        if (res.error) {
        } else {
          navigate("/customers");
        }
      });
    }
    }


  useEffect(() => {
    setSelected&& setSelected("Customers");
  }, []);

  const initChild = () => {
    switch (child) {
      case "account-statement":{
        return <AccountStatement errors={errors} setErrors={setErrors} getAccountStatment={_getAccountStatment}/>
      }
      case "customers": {
        return <Customers  deleteCustomer={_deleteCustomer} getCustomers={_getCustomers}/>;
      }
      case "add-customer":
        return (
          <AddCustomer
        addCustomer={_addCustomer}
        errors={errors}
        setErrors={setErrors}
          />
        );

      case "edit-customer":
        return (
          <EditCustomer
          editCustomer={_editCustomer}
          getCustomer={_getCustomer}
          errors={errors}
          setErrors={setErrors}
          />
        );

      default:
    }
  };
  return (
    <div>
      <ToastContainer/>

      {logging && <LoadingDialog open={logging} />}

      {initChild()}
    </div>
  );
}

export default CustomersController;
