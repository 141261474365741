export const ADD_COMPANY_FORM =
  [
    {
      feild:'textField',
      type: "text",
      label: "Name",
      name: "name",
      grid: "2",
    },

   
  ];

  export const EDIT_COMPANY_FORM =
  [
    {
      feild:'textField',
      type: "text",
      label: "Name",
      name: "name",
      grid: "2",
    },

  ];