import i18n from "../../localization"

export const getExpanceHeaderMapper=(data)=>{
    const ExpanceHeaderMapper=[]
    const ExpanceDataMappar=getExpanceMapper(data)
    const keys=ExpanceDataMappar.length>0? Object.keys(ExpanceDataMappar[0]):[]
    keys.map((key)=>{
        let words = key.split("_");
        let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        let result = capitalizedWords.join(" ");
        ExpanceHeaderMapper.push({
            field:key,
            headerName:i18n.t(result),
                  width: (key=='name'||key=="account_name")?200:100,
        })
    })
    return ExpanceHeaderMapper
}

export const getExpanceMapper=(data)=>{
    const ExpanceMapperData=[]
    //console.log(data);
    data?.map((item)=>{
        ExpanceMapperData.push({
            ...item
        })
    })

    return ExpanceMapperData
}



export const getOneExpanceMapper=(data)=>{
   
    const oneExpanceMapperData={
           
        }
  
    return oneExpanceMapperData
}

export const getExpanceDetails = (ExpanceDetails) => {
    return {
        dollar_total_amount: ExpanceDetails.dollar_total_amount,
        sy_total_amount: ExpanceDetails.sy_total_amount
  
  
    }
  };
  