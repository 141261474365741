import { CURRENT_GENERALDAILY, DELETE_GENERALDAILY, EDIT_GENERALDAILY, GENERALDAILY, GENERALDAILYS, MIGRATE_GENERALDAILY, STORE_GENERALDAILY } from "../../constent/urls"
import Requests from "../../services/Requests"
import HandleError from "../../services/HandleError"

class GeneralDaily{
    static getGeneralDailys = async (headers,params,fail) => {
        let result=await Requests.get(GENERALDAILYS,headers,params );
        //console.log(result);
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static getGeneralDaily = async (headers,id,fail) => {
        let result=await Requests.get(GENERALDAILY+id,headers);
        
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static getCurrentGeneralDaily = async (headers,params,fail) => {
        let result=await Requests.get(CURRENT_GENERALDAILY,headers,params);
        //console.log(result);
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static addGeneralDaily = async (headers,body,fail) => {
        let result=await Requests.post(STORE_GENERALDAILY,headers,body)
        return HandleError.handleError(result,()=>{return result},fail)
    }

    static migrateGeneralDaily = async (headers,body,fail) => {
        let result=await Requests.post( MIGRATE_GENERALDAILY,headers,body)
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static editGeneralDaily = async (headers,body,id,fail) => {
        let result=await Requests.patch(EDIT_GENERALDAILY+id,headers,body)
        //console.log(result);
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static deleteGeneralDaily = async (headers,id,fail) => {
        let result=await Requests.delete(DELETE_GENERALDAILY+id,headers)
        return HandleError.handleError(result,()=>{return result},fail)
    }

}
export default GeneralDaily;