import i18n from "../../localization"

export const getSupplierHeaderMapper=(data)=>{
    const supplierHeaderMapper=[]
    const supplierDataMappar=getSupplierMapper(data)
    const keys=supplierDataMappar.length>0? Object.keys(supplierDataMappar[0]):[]
    keys.map((key)=>{
        let words = key.split("_");
        let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        let result = capitalizedWords.join(" ");
        supplierHeaderMapper.push({
            field:key,
            headerName:i18n.t(result),
                  width: (key=='name'||key=="account_name")?200:100,
        })
    })
    return supplierHeaderMapper
}

export const getSupplierMapper=(data)=>{
    const supplierMapperData=[]
    data.map((item)=>{
        supplierMapperData.push({
            id: item.id,
            name: item.name,
            nationality:item.nationality,
            currency:item.currency,

            // description:item.description,
          //   is_receivable: item.is_receivable,
            creator: item.creator,
            created_at: (item.created_at),
        })
    })

    return supplierMapperData
}

export const getOneSupplierMapper=(data)=>{
   
    const oneSupplierMapperData={
        name: data?.name,
        commission:data?.commission,
        account_type:'Supplier',
        nationality:data?.nationality,
        currency_id:data?.currency,
        currency:data?.currency_id,


        }
  
    return oneSupplierMapperData
}

export const getReceivableDetails = (receivableDetails) =>{
    console.log(receivableDetails.currency);
   if(receivableDetails)
  return ({
        // account_name: receivableDetails.account_name,
        syrian_price_to_be_paid_Supplier: receivableDetails.currency=='USD'||receivableDetails.currency=='SYP'||receivableDetails.currency==null?receivableDetails.syrian_price_to_be_paid:undefined,
        syrian_price_to_be_received_Supplier: (receivableDetails.currency=='USD'||receivableDetails.currency=='SYP'||receivableDetails.currency==null)?receivableDetails.syrian_price_to_be_received:undefined,
        dollar_price_to_be_paid_Supplier: receivableDetails.dollar_price_to_be_paid,
        dollar_price_to_be_received_Supplier: receivableDetails.dollar_price_to_be_received,
        foreign_price_to_be_paid: (receivableDetails.currency!='USD'&&receivableDetails.currency!='SYP'&&receivableDetails.currency!=null)?`${receivableDetails.currency} ${receivableDetails.foreign_price_to_be_paid} `.split(' ').reverse():undefined,
        foreign_price_to_be_receive: (receivableDetails.currency!='USD'&&receivableDetails.currency!='SYP'&&receivableDetails.currency!=null)?`${receivableDetails.currency} ${receivableDetails.foreign_price_to_be_receive} `.split(' ').reverse():undefined,
    
      });
    

}
  