import { createAsyncThunk } from "@reduxjs/toolkit";
import Employee from "../EmployeeApi";

export const getEmployees= createAsyncThunk(
  "employeeSlice/getEmployees",
  async ({ headers,params }, { rejectWithValue }) => {
    return await Employee.getEmployees(headers,params, () => {
      return rejectWithValue();
    });
  }
);

export const getRoles= createAsyncThunk(
  "employeeSlice/getRoles",
  async ({ headers,params }, { rejectWithValue }) => {
    return await Employee.getRoles(headers,params, () => {
      return rejectWithValue();
    });
  }
);
export const deleteEmployee = createAsyncThunk(
  "employeeSlice/deleteEmployee",
  async ({ headers, id }, { rejectWithValue }) => {
    return await Employee.deleteEmployee(headers, id, () => {
      throw new Error("Failed delete employee");
    });
  }
);

export const addEmployee = createAsyncThunk('employeeSlice/addEmployee', async ({ headers,employee }, { rejectWithValue }) => {
  return await Employee.addEmployee(headers, employee,()=>{
    throw new Error("Failed store employee")});
})

export const getEmployee = createAsyncThunk(
  "employeeSlice/getEmployee",
  async ({ headers,id }, { rejectWithValue }) => {
    return await Employee.getEmployee(headers,id, () => {
      return rejectWithValue();
    });
  }
);

export const editEmployee = createAsyncThunk('employeeSlice/editEmployee', async ({ headers,employee,id }, { rejectWithValue }) => {
  return await Employee.editEmployee(headers, employee,id,()=>{
    throw new Error("Failed edit employee")});
})