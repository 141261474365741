import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from "react-i18next";
import { GlobalBoxContainer } from "../../Styles/Styles";
import { Actions, DataGridTable, Header, ReceivableDetails, useSidebarContext } from "../../components";
import { getFundDetails, getFundHeaderMapper, getFundMapper } from "./mapper";
import { fundSelector } from "./redux/slice";
import { deleteNullValues } from "../../services/functions";
import { useState } from "react";
import ConfirmationDialgo from "../../components/ConfirmationDialgo";
import ConfirmationDialgoForm from "../../components/ConfirmationDialgoForm";
import { BALANCE_FORM } from "./form";

const Funds = ({ getFunds,title,errors,setErrors,submitBalance }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { funds,transaction_total,
    receivable_details_total } = useSelector(fundSelector)
  const {sidebarWidth} = useSidebarContext();
  const [transactionPaginationModel, setTransactionPaginationModel] = useState({
    page:0,
    pageSize:100

  })

  const [receivableDetailsPaginationModel, setReceivableDetailsPaginationModel] = useState({
    page:0,
    pageSize:100

  })
  const [dialog, setDialog] = useState(false)
  const [balanceData, setBalanceData] = useState({
    dollar_balance:"",
    syrian_balance:""
  })
  useEffect(() => {
  }, [])

  useEffect(() => {
    getFunds({transactions_page:transactionPaginationModel.page+1,receivable_details_page:receivableDetailsPaginationModel.page+1,transactions_limit:transactionPaginationModel.pageSize,receivable_details_limit:receivableDetailsPaginationModel.pageSize,account_type:title=="Funds"?"Fund":"Bank"})

  }, [transactionPaginationModel.page,transactionPaginationModel.pageSize])

  useEffect(() => {
    getFunds({transactions_page:transactionPaginationModel.page+1,receivable_details_page:receivableDetailsPaginationModel.page+1,transactions_limit:transactionPaginationModel.pageSize,receivable_details_limit:receivableDetailsPaginationModel.pageSize,account_type:title=="Funds"?"Fund":"Bank"})

  }, [receivableDetailsPaginationModel.page,receivableDetailsPaginationModel.pageSize])

  const openBalanceDialog=()=>{
    setDialog(true)
  }


  const closeBalanceDialog=()=>{
    setDialog(false)
  }

  const okBalance=()=>{
    submitBalance(balanceData)
    // closeBalanceDialog()

  }

  const handleChange=(event)=>{
    const {name,value}=event.target
    console.log(name);
    setBalanceData({...balanceData,[name]:value})
        setErrors((prevErrors)=>(
      {...prevErrors,
      [name]:''
      }
  ))
}
  return (
    <Box sx={{width:`calc(100%-${sidebarWidth+'px'})`}} m="20px">
     {dialog&& <ConfirmationDialgoForm  onOk={okBalance} onClose={closeBalanceDialog} open={dialog} data={balanceData} errors={errors} form={BALANCE_FORM} handleChange={handleChange} setValue={setBalanceData} />}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header viewAll={true} title={title} subtitle={"Welcome to your " + title?.toLowerCase()} />
      </Box>
      {/* <Button sx={{ bgcolor: '#0066CC' }} variant='contained' startIcon={<AddIcon />} onClick={() => { navigate('/funds/add') }}>{t('Add')}</Button> */}
      <GlobalBoxContainer>
      <Button sx={{ bgcolor: '#0066CC' }} variant='contained' startIcon={<AddIcon />} onClick={() => { openBalanceDialog() }}>{t('Add Balance')}</Button>
       
      <ReceivableDetails title={title+' Information'} data={deleteNullValues(getFundDetails(funds)) }  />
        {funds?.transactions?.length>0&&
        <div>
    <h1 style={{color:"#535AC8"}} align="center" >{t('Transactions')}</h1>

        {funds?.transactions?.length>0?<DataGridTable setPaginationModel={setTransactionPaginationModel} paginationModel={transactionPaginationModel} total={transaction_total} rows={getFundMapper(funds?.transactions)} columns={getFundHeaderMapper(funds?.transactions).reverse()} />:''}
        </div>
        }

      </GlobalBoxContainer>
    </Box>
  );
};

export default Funds;