import i18n from "../../localization"
import { getCurrentDate } from "../../services/functions"

export const getTicketHeaderMapper=(data)=>{

    const ticketHeaderMapper=[]
    const ticketDataMappar=getTicketMapper(data)
    const keys=ticketDataMappar.length>0? Object.keys(ticketDataMappar[0]):[]
    keys.map((key)=>{
        //console.log(key);
        if(key!=='is_refunded'){
            let words = key.split("_");
            let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
            let result = capitalizedWords.join(" ");
            ticketHeaderMapper.push({
                field:key,
                headerName:i18n.t(result),
                      width: (key=='name'||key=="account_name")?200:100,
            })
        }
       
    })
    return ticketHeaderMapper
}

export const getTicketMapper=(data)=>{
    const ticketMapperData=[]
    data.map((item)=>{
        
        ticketMapperData.push({
         ...item,

        })
    })

    return ticketMapperData
}

export const getOneTicketMapper=(data)=>{
   
    const oneTicketMapperData={
        customer_price:data.customer_price,
        travel:data.travel,
        description:data.description,
        release_date:data.release_date?data.release_date:getCurrentDate(),
        rest_amount:data?.rest_amount,
        ticket_price:data?.ticket_price,
        ticket_price_fare:data?.ticket_price_fare,
        payment_price_to_supplier:data?.payment_price_to_supplier,



        }
  
    return oneTicketMapperData
}