import { DELETE_CUSTOMER, EDIT_CUSTOMER, CUSTOMER, CUSTOMERS, STORE_CUSTOMER, ACCOUNT_STATEMENT } from "../../constent/urls"
import Requests from "../../services/Requests"
import HandleError from "../../services/HandleError"

class Customer{
    static getCustomers = async (headers,params,fail) => {
       
        let result=await Requests.get(CUSTOMERS,headers,params );
        //console.log(result);
        return HandleError.handleError(result,()=>{return result.data},fail)
    }

    static getCustomer = async (headers,id,fail) => {
        let result=await Requests.get(CUSTOMER+id,headers);
        
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static getAccountStatement = async (headers,id,params,fail) => {
        let result=await Requests.get(ACCOUNT_STATEMENT+id,headers,params);
        
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static addCustomer = async (headers,body,fail) => {
        let result=await Requests.post(STORE_CUSTOMER,headers,body)
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static editCustomer = async (headers,body,id,fail) => {
        let result=await Requests.patch(EDIT_CUSTOMER+id,headers,body)
        //console.log(result);
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static deleteCustomer = async (headers,id,fail) => {
        let result=await Requests.delete(DELETE_CUSTOMER+id,headers)
        return HandleError.handleError(result,()=>{return result},fail)
    }

}
export default Customer;