import {  EXPANCES } from "../../constent/urls"
import Requests from "../../services/Requests"
import HandleError from "../../services/HandleError"

class Expance{
    static getExpances = async (headers,params,fail) => {
        let result=await Requests.get(EXPANCES,headers,params );
        //console.log(result);
        return HandleError.handleError(result,()=>{return result.data},fail)
    }

   

}
export default Expance;