import { DELETE_CONSTANT, EDIT_CONSTANT, CONSTANT, CONSTANTS, STORE_CONSTANT } from "../../constent/urls"
import Requests from "../../services/Requests"
import HandleError from "../../services/HandleError"

class Constant{
    static getConstants = async (headers,params,fail) => {
        let result=await Requests.get(CONSTANTS,headers,params );
        //console.log(result);
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static getConstant = async (headers,id,fail) => {
        let result=await Requests.get(CONSTANT+id,headers);
        
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static addConstant = async (headers,body,fail) => {
        let result=await Requests.post(STORE_CONSTANT,headers,body)
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static editConstant = async (headers,body,id,fail) => {
        let result=await Requests.patch(EDIT_CONSTANT+id,headers,body)
        //console.log(result);
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static deleteConstant = async (headers,id,fail) => {
        let result=await Requests.delete(DELETE_CONSTANT+id,headers)
        return HandleError.handleError(result,()=>{return result},fail)
    }

}
export default Constant;