import { DELETE_FUND, EDIT_FUND, FUND, FUNDS, STORE_BALANCE, STORE_FUND } from "../../constent/urls"
import Requests from "../../services/Requests"
import HandleError from "../../services/HandleError"

class Fund{
    static getFunds = async (headers,params,fail) => {
        let result=await Requests.get(FUNDS,headers,params );
        //console.log(result);
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static getFund = async (headers,id,fail) => {
        let result=await Requests.get(FUND+id,headers);
        
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static addFund = async (headers,body,fail) => {
        let result=await Requests.post(STORE_FUND,headers,body)
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static editFund = async (headers,body,id,fail) => {
        let result=await Requests.patch(EDIT_FUND+id,headers,body)
        //console.log(result);
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static deleteFund = async (headers,id,fail) => {
        let result=await Requests.delete(DELETE_FUND+id,headers)
        return HandleError.handleError(result,()=>{return result},fail)
    }

    static addBalance = async (headers,body,fail) => {
        let result=await Requests.post(STORE_BALANCE,headers,body)
        return HandleError.handleError(result,()=>{return result},fail)
    }

}
export default Fund;