export const ar = {
  translation: {
    Active: "فعال",

    Employee: "موظف",
    "Is Active": "الحالة",
    Funds: " حساب صندوق",
    Bank: " حساب بنكي",

    "Syrian Price To Be Received": " SYP المبلغ الواجب استلامه من الزبون",
    Section: "قسم  ",
    "Dollar Price": "$ سعر تصريف ",

    "Account Name": "اسم الحساب",
    "Dollar Price To Be Received": "$ المبلغ الواجب استلامه من الزبون",
    "Ticket Number": "رقم التذكرة ",
    "Company Name": "اسم الشركة",
    "Supplier Name": "اسم المورد",
    "Customer Name": "اسم العميل",
    "Creator Name": "اسم المنشئ",
    "Creator Role": "دور المنشئ",
    "Rest Amount": "المبلغ المتبقي",
    "Transaction Date": "تاريخ انشاء معاملة",
    "Syrian Payed": "SYP المبلغ المدفوع",
    "Dollar Payed": "$ المبلغ المدفوع",
    Transactions: "المعاملات",

    "Welcome to your companys": "مرحبًا بك في شركاتك",
    "Welcome to your account statement": "مرحبًا بك في كشف حسابك",
    "Welcome to your customers": "مرحبًا بك في عملائك",
    "Welcome to your employees": "مرحبًا بك في موظفيك",
    "Welcome to your funds": "مرحبًا بك في أموالك",
    "Welcome to your bank": "مرحبًا بك في أموالك",

    "Welcome to your current general daily": "مرحبًا بك في القيد اليومي الحالي",
    "Welcome to your generalDailys": "مرحبًا بك في القيود اليومية الخاصة بك",
    "Welcome to your suppliers": "مرحبًا بك في مورديك",
    "Welcome to your tickets": "مرحبًا بك في تذاكرك",
    "Welcome to your transactions": "مرحبًا بك في معاملاتك",

    "Company ": "إنشاء شركة",
    "a New Company ": "إنشاء شركة جديدة",
    "Companys ": "الشركات",
    "EDIT Company ": "تعديل الشركة",
    "Edit a Company ": "تعديل الشركة",
    "Account statement ": "كشف الحساب",
    "Customer ": "إنشاء عميل",
    "a New Customer ": "إنشاء عميل جديد",
    "Customers ": "العملاء",
    "EDIT Customer ": "تعديل العميل",
    "Edit a Customer ": "تعديل العميل",
    "Employee ": "إنشاء موظف",
    "a New Employee ": "إنشاء موظف جديد",
    "EDIT Employee ": "تعديل الموظف",
    "Edit a Employee ": "تعديل الموظف",
    "Employees ": "الموظفون",
    "Fund ": "إنشاء صندوق",
    "a New Fund ": "إنشاء صندوق جديد",
    "EDIT Fund ": "تعديل الصندوق",
    "Edit a Fund ": "تعديل الصندوق",
    "Funds ": "الأموال",
    "GeneralDaily ": "إنشاء قيد يومية",
    "a New GeneralDaily ": "إنشاء قيد يومية جديدة",
    "Current General Daily ": "القيد اليومي الحالي",
    "EDIT GeneralDaily ": "تعديل القيد اليومي",
    "Edit a GeneralDaily ": "تعديل القيد اليومي",
    "GeneralDailys ": "القيود اليومية",
    "Supplier ": "إنشاء مورد",
    "a New Supplier ": "إنشاء مورد جديد",
    "EDIT Supplier ": "تعديل المورد",
    "Edit a Supplier ": "تعديل المورد",
    "Suppliers ": "الموردين",
    "Ticket ": "إنشاء تذكرة",
    "a New Ticket ": "إنشاء تذكرة جديدة",
    "EDIT Ticket ": "تعديل التذكرة",
    "Edit a Ticket ": "تعديل التذكرة",
    "Tickets ": "التذاكر",
    "Transaction ": "إنشاء معاملة",
    "a New Transaction ": "إنشاء معاملة جديدة",
    "EDIT Transaction ": "تعديل المعاملة",
    "Edit a Transaction ": "تعديل المعاملة",
    "Transactions ": "المعاملات",

    Action: "الإجراء",
    Edit: "تعديل",
    "Account statement": "كشف حساب",
    Delete: "حذف",
    Unban: "إلغاء الحظر",
    "Ban User": "حظر المستخدم",
    "Are you sure you want to ban the user?":
      "هل أنت متأكد أنك تريد حظر المستخدم؟",
    "Receivable Details": "تفاصيل الذمم",
    "New Company": "إنشاء شركة جديدة",
    "Edit Company": "تعديل الشركة",
    "New Customer": "إنشاء عميل جديد",
    "Edit Customer": "تعديل العميل",
    "New Employee": "إنشاء موظف جديد",
    "Edit Employee": "تعديل الموظف",
    "New Fund": "إنشاء صندوق جديد",
    "Edit Fund": "تعديل الصندوق",
    "New GeneralDaily": "إنشاء قيد يومية جديد",
    "Edit GeneralDaily": "تعديل القيد اليومي",
    "New Supplier": "إنشاء مورد جديد",
    "Edit Supplier": "تعديل المورد",
    "New Ticket": "إنشاء تذكرة جديدة",
    "Edit Ticket": "تعديل التذكرة",
    "New Transaction": "إنشاء معاملة جديدة",
    "Edit Transaction": "تعديل المعاملة",
    Currency: "عملة",
    Name: "الاسم",
    Commission: "العمولة",
    Receivable: "المستحقات",
    Username: "اسم المستخدم",
    "Foreign Ticket Price":'سعر التذكرة بالعملة الاجنبية',
    "Foreign Commission Price":'سعر العمولة بالعملة الاجنبية',
    "Foreign Currency":"العملة الأجنبية",
    "supplier_commission":"عمولة المورد",
    "ticket_fare":"سعر التذكرة بدون ضرائب",
    "currency_id":"عملة",
    "ticket_price":"سعر التذكرة",
    "account_name":'اسم الحساب',

    "supplier_deal_type":"طريقة التعامل مع المورد",

    Password: "كلمة المرور",
    Role: "الدور",
    "Doller price": "$ سعر ",
    Year: "السنة",
    "Start date": "تاريخ  بدء",
    Customer: "العميل",
    Travel: "السفر",
    "Ticket number": "رقم التذكرة او رقم الحجز",
    Description: "الوصف",
    "Release date": "تاريخ انشاء إصدار",
    Supplier: "المورد",
    Company: "الشركة",
    "ticket price syria": "SYP سعر التذكرة ",
    "Ticket price dollar": "$ سعر التذكرة ",
    "Pay price sy": "SYP المبلغ المدفوع ",
    "Pay price dollar": "$ المبلغ المدفوع ",
    "Account type": "نوع الحساب",
    Account: "الحساب",
    "pay price in syria": "SYP المبلغ المدفوع ",
    "pay price in dollar": "$ المبلغ المدفوع ",
    description: "الوصف",
    Date: "التاريخ",

    "Please enter a username": "ادخل اسم المستخدم",
    "Please enter a password at least 8 characters":
      "ادخل كلمة سر بحيث لا تقل عن 8 احرف",
    Customers: "الزبائن",
    Companys: "الشركات",
    Suppliers: "الموردين",
    Employees: "الموظفين",
    Tickets: "التذاكر",
    "Current General Daily": "اليومية العامة الحالية",
    transaction: " دفعة",

    "Account Balance": "رصيد الحساب",
    "Is Receivable": "عليه ذمة",
    Creator: "المنشئ",
    "Created At": "تاريخ الانشاء ",
    "Account statement": "كشف حساب",

    Dashboard: "لوحة التحكم",
    Data: "البيانات",
    "Manage Team": "ادارة الفريق",
    TEAM: "الفريق",
    "Welcome to your team": "اهلا وسهلا في فريقك",

    Id: "رقم التعريف",
    Name: "الاسم",
    Age: "العمر",
    Email: "ايميل",
    Action: "النشاط",
    Edit: "تعديل",
    Delete: "حذف",
    Add: "اضافة",
    Roles: "ادوار",
    "Welcome to your roles": "اهلا وسهلا في ادوارك",
    "Role name": "اسم الدور",
    "Guard name": "اسم الحارس ",
    Sections: "اقسام",
    Admins: "مدراء",
    Providers: "المزودين",
    Clients: "الزبائن",
    "Section name": "اسم القسم ",
    "Section description": "وصف القسم ",
    "Section visible": "روئية القسم ",
    "Welcome to your sections": "اهلا وسهلا في اقسامك",
    "Section name in arabic": "اسم القسم  في العربية",
    "Section name in english": "اسم القسم  في الانجليزية",
    "Section description in arabic": "وصف القسم  في العربية",
    "Section description in english": "وصف القسم  في الانجليزية",
    "Drag & Drop your files or": "اسحب الملفات وافلتها او ",
    Browse: "تصفح",
    Section: " قسم ",
    "a New Section": " قسم  جديد",
    "New Section": " قسم  جديد",
    "Edit Section": "تعديل القسم ",
    "ُEdit a  Section ": "تعديل القسم  ",
    SYP: "ل.س",
    Services: "خدمات",
    Protfolios: "اعمال",
    Offers: "عروض",
    "System notifications": "اشعارات النظام",
    Orders: "الطلبات",
    Requests: "الطلبات المنتظرة",
    "On going": "الطلبات قيد التنفيد",
    Pending: "انتظار",
    Notification: "اشعار",
    "Welcome to your services": "اهلا بك في خدماتك",
    "Service title": "عنوان الخدمة",
    "Service body": "محتوى الخدمة",
    "Service duration": "مدة الخدمة",
    "Service price": "سعر الخدمة",
    "Service visible": "رؤية الخدمة",
    Owner: "المالك",
    "Edit Service": "تعديل الخدمة",
    Visibility: "الرؤية",
    "Edit a  Service ": "تعديل خدمة ",
    "Portfolio tools": "ادوات العمل",
    "Portfolio title": "عنوان العمل",
    "Portfolio body": "محتوى العمل",
    "Portfolio visible": "رؤية العمل",
    "Edit a  portfoilio ": "تعديل عمل ",
    "Edit portfoilio": "تعديل العمل",
    Offers: "العروض",
    "Welcome to your offers": "اهلا وسهلا في عروضك",
    "Offer price": "سعر العرض",
    "Offer delivery term": "مدة توصيل العرض",
    "Offer description": "وصف العرض",
    "Offer edit price": "سعر تعديل العرض",
    "Offer edit delivery term": "المدة المعدلة للعرض",

    "Order requests": "طلبات المشاريع",
    "Welcome to your Order requests": "اهلا وسهلا بك في طلبات المشاريع",
    "Order request title": "عنوان طلب المشروع",
    "Order request start price": "السعر البدائي لطلب المشروع",
    "Order request start end": "السعر النهائي لطلب المشروع",
    "Order request delivery term": "مدة تسليم طلب المشروع",
    "Order request description": "وصف طلب المشروع",
    "Order request type": "نوع طلب المشروع",
    Orders: "المشاريع",
    "Welcome to your Orders": "اهلا وسهلا في مشاريعك",
    "Order title": "عنوان المشروع",
    "Order price": "السعر المشروع",
    "Order delivery term": "مدة تسليم المشروع",
    "Order description": "وصف المشروع",
    "Order type": "نوع المشروع",
    "Order status": "حالة المشروع",
    Designers: "المصممين",
    Status: "الحالات",
    "Order finished at from designer": "وقت انتهاء المشروع من المصمم",
    "Pending orders": "المشاريع المنتظرة",
    "Welcome to your Pending orders": "اهلا وسهلا بك في مشاريعك",
    "Order bill": "فاتورة المشروع",
    "Welcome to your system notifications": "اهلا وسهلا بك في اشعارات النظام",
    "Notification title": "عنوان الاشعار",
    "Notification body": "محتوى الاشعار",
    "Push notification": "ارسال اشعار",
    "Edit notification": "تعديل اشعار",
    "a New Notification": " اشعار جديد",
    Notification: " اشعار جديد",

    "Notification title in english": "عنوان الاشعار في الانجليزية",
    "Notification title in arabic": "عنوان الاشعار في العربية",
    "Notification body in english": "محتوى الاشعار في الانجليزية",
    "Notification body in arabic": "محتوى الاشعار في العربية",
    "Edit notification": "تعديل الاشعار",
    "Edit a  notification": "تعديل اشعار",
    notification: " اشعار",
    All: "الكل",
    "Specific User": "مستخدم معين",
    "Send notification to": "ارسال اشعار الى",

    "Welcome to your admins": "اهلا بك في المدراء",
    "Admin name": "اسم المدير",
    "Phone number": "رقم الموبايل",
    "Account type": "نوع الحساب",
    Country: "البلد",
    "Id number": "الرقم الوطني",
    Admin: " مدير",
    "a New Admin": " مدير جديد",
    "Admin password": "كلمة سر المدير",
    "Admin phone number": "رقم موبايل المدير",
    "Admin account type": "نوع حساب المدير",
    "Admin age": "عمر المدير",
    "Admin id number": "الرقم الوطني للمدير",
    "Admin country": "بلد المدير",
    "New Admin": " مدير جديد",
    "Admin email": "ايميل المدير",
    "Welcome to your providers": "اهلا وسهلا بك في المزودين",
    "Welcome to your clients": "اهلا وسهلا بك في الزبائن",
    "Ban User": "منع مستخدم",
    "Are you sure you want do ban user ": "هل انت متأكد من منع مستخدم",
    Cancel: "اغلاق",
    Ban: "منع",
    Blacklist: "قائمة المحظورين",
    "Welcome to your blacklist": "اهلا بك في قائمة المحظورين",
    Chats: "الدردشات",

    Password: "كلمة السر",
    Username: "اسم المستخدم",
    Login: "تسجيل الدخول",

    "Received Amount Dollar": "$ مبلغ الاستلام ",
    "Paid Amount Dollar": " $ مبلغ الدفع ",
    "Commission Dollar": "$ العمولة ",
    "Received Amount Sy": "SYP مبلغ الاستلام ",
    "Paid Amount Sy": "SYP مبلغ الدفع ",
    "Release Date": "تاريخ الإصدار",
    "Start Date": "تاريخ البداية",
    "End Date": "تاريخ الانتهاء",
    GeneralDailys: "اليوميات العامة",
    GeneralDaily: "اليوميات العامة",
    Transaction: "المعاملة",
    "Account Type": "نوع الحساب",
    "Syrian Receive": "SYP استلام ",
    "Dollar Receive": "$ استلام ",
    "Commission Sy": "SYP عمولة",
    "View last general dailies": "عرض آخر اليوميات العامة",
    "View current general daily": "عرض اليومية العامة الحالية",
    "Show Receivable Detial": "عرض تفاصيل الذمة",
    "Ticket Details": "تفاصيل التذكرة",
    Close: "اغلق",
    Ticket: "تذكرة",
    "ticket price dollar fare":"سعر التذكرة بلا ضرائب",
    "ticket price syria fare":" سعر التذكرة  بلا ضرائب ",
    "Constent":"الثوابت",
    "Save":"حفظ",
    "Print Account Statement":"طباعة كشف الحساب",
    "Funds Information":"معلومات الصندوق",
    "Bank Information":"معلومات حساب البنكي",

    "Expenses Dollar":"$ مدفوعات ",
    "Expenses Sy":"SYP مدفوعات ",
    "Profits Dollar":"$ الرصيد",
    "Profits Sy":"SYP الرصيد",
    "Rest Amount Receivable Dollar":"$ المبلغ الواجب استلامه ",
    "Rest Amount Receivable On Fund Dollar":"$ المبلغ الواجب دفعه ",
    "Rest Amount Receivable Sy":"SYP المبلغ الواجب استلامه ",
    "Rest Amount Receivable On Fund Sy":"SYP المبلغ الواجب دفعه ",
    "Dollar Price To Be Paid":" $  المبلغ الواجب دفعه للزبون",
    "Syrian Price To Be Paid":"SYP  المبلغ الواجب دفعه للزبون",
    "Expances":"المصاريف",
    "Expance Information":"معلومات المصاريف",
    "Dollar Total Amount":"$ مجموع المصاريف",
    "Sy Total Amount":"SYP مجموع المصاريف",
    "Are you sure you want to delete":"هل انت متأكد من حذف",
    "Delete Item?":"حذف عنصر؟",
    "Logout":"تسجيل الخروج",
    "Profile":"الملف الشخصي",
    "Ticket Price Fare":"سعر التذكرة دون ضرائب",
    "Ticket Price":"سعر التذكرة",
    "Payment Price To Supplier":"السعر الواجب دفعه للمورد",
    "Total Amount Received Sy":"SYP اجمالي المقبوض",
    "Total Amount Received Dollar":"$ اجمالي المقبوض",
    "Current General Daily Information":"معلومات اليومية العامة",
    "Total Profit Dollar":"$ مجموع الربح",
    "Total Profit Sy":"SYP مجموع الربح",
    "Enter Customer name or ticket number":"ادخل اسم الزبون او رقم التذكرة",
    "Search Customer name or ticket number":"ابحث ب اسم الزبون او رقم التذكرة",
    "Create Transaction":"انشاء دفعة",
    "Search Customer name":"ابحث باسم الزبون",
    "Enter Customer name":"ادخل اسم الزبون",
    "Expense":"المصاريف",
    "Filter":'فرز',
    "End date":'تاريخ النهاية',
    "Customer Price":"سعر ذمة الزبون",
    "Supplier commission price":"سعر العمولة المقطوعة من قبل المورد",
    "Bank transfer":"تحويل بنكي",
    "Reverse transaction":"دفعة عكسية",
    "Migrate":'تهجير',
    "Downlaod":"تحميل",
    "Recivables":"الذمم",
    "Dollar Price To Be Received Supplier": "$ المبلغ الواجب استلامه من المورد",
    "Syrian Price To Be Received Supplier": " SYP المبلغ الواجب استلامه من المورد",
    "Dollar Price To Be Paid Supplier":" $  المبلغ الواجب دفعه المورد",
    "Syrian Price To Be Paid Supplier":"SYP  المبلغ الواجب دفعه المورد",

    "Foreign Price To Be Receive": "المبلغ الواجب استلامه من المورد عملة اجنبية",
    "Foreign Price To Be Paid":"المبلغ الواجب دفعه المورد عملة اجنبية",
    "Type":"نوع التذكرة",
    travel: "سفر",
ticket_number: "رقم التذكرة او رقم الحجز",
customer_id: "معرف العميل",
customer_name: "اسم العميل",
supplier_id: "اسم المورد",
customer_price: "سعر تذكرة العميل",
supplier_commission_price: "سعر عمولة المورد",
company_id: "معرف الشركة",
ticket_price_dollar: "سعر التذكرة بالدولار",
ticket_price_sy: "سعر التذكرة بالليرة السورية",
pay_price_sy: "السعر المدفوع بالليرة السورية",
pay_price_dollar: "السعر المدفوع بالدولار",
release_date: "تاريخ الإصدار",
ticket_price_dollar_fare: "سعر التذكرة بالدولار (بلا ضرائب)",
ticket_price_sy_fare: "سعر التذكرة بالسوري (بلا ضرائب)",

  false:'لا',
  true:'نعم',
  account_id: "رقم الحساب",
  account_type: "نوع الحساب",
  date: "التاريخ",
  reverse_transaction: "عكس العملية",
  "Add Balance":'اضافة رصيد',
  "Syrian Balance":"رصيد سوري",
  "Dollar Balance":"رصيد دولار",
  bank_transfer: "تحويل بنكي",
  "Add new general daily":"اضاقة يومية عامة",
  "Edit password":"تعديل كلمة السر",
  "Old password":"كلمة السر القديمة",
  "New password":"كلمة السر الجدية ",
  "New password confirmation":"تاكيد كلمة السر",
  "Recivable Information":"معلومات الذمم",
  "Print Recivables":"طباعة الذمم",
  "Recivable":"ذمة",
  "New Recivable":"ذمة جديدة",
  "commission":"عمولة",
  "Add Returned Ticket":"اضافة تذكرة مرتجعة",
  "Payment price to supplier":"السعر الواجب دفعة للمورد",
  "Ticket price fare":"سعر التذكرة بلا ضرائب ",
  "Ticket price":"سعر التذكرة",

  "Rest amount":"المبلغ المتبقي على الزبون",
  "Fine price dollar":"$ سعر الضريبة",
  "Fine price syria":"SYP سعر الضريبة",
  "Normal":"عادية",
  "ReIssue":"إعادة إصدار",
  "Void":"الغاء",
  "Refund":"ارجاع",
  "Rest Amount Refund":"المبلغ الواجب على الزبون تسديده للتذكرة المرجعة",
  "Added_Receivable":"ذمة مضافة",
  "Nationality":"جنسية",
  "Constants":"الثوابت",
  "EDIT Constant":"تعديل الثابت",
  "Edit Constant":"تعديل الثابت",
  "Value":"قيمة",
  "CREATE Constant":"انشاء ثابت",
  "Create New Constant":"انشاء ثابت جديد",
  "PLUS_COMMISSION":"عمولة اضافية عن سعر التذكرة",
  "FLAT_COMMISSION":"العمولة المقطوعة من قبل المورد",
  "RATIO":"نسبة مقطوعة من سعر التذكرة",
  "Supplier deal type":"طريقة التعامل مع المورد ",
  "Ticket fare":"سعر التذكرة بدون ضرائب",
  "Commission(1-100)":"العمولة (1-100)",
  "Refund Ticket":"ارجاع تذكرة",
  "Customer fine":"ضريبة الزبون",
  "Supplier fine":"ضريبة المورد",
  "Reissue Ticket":"اعادة اصدار تذكرة",
  "Void Ticket":"الغاء تذكرة"



  },
};

export const en = {
  translation: {
    "Commission(1-100)":"Commission(1-100)",
    "Ticket fare":"Ticket fare",
    "Supplier deal type":"Supplier deal type",
    "PLUS_COMMISSION":"PLUS_COMMISSION",
    "FLAT_COMMISSION":"FLAT_COMMISSION",
    "RATIO":"RATIO",


    "CREATE Constant":"CREATE Constant",
    "Create New Constant":"Create New Constant",

    "Value":"Value",
    "EDIT Constant":"EDIT Constant",
    "Edit Constant":"Edit Constant",

    "Constants":"Constants",

    "Nationality":"Nationality",

    "Rest Amount Refund":"Rest Amount Refund",
    "Added_Receivable":"Added_Receivable",
    "Normal":"Normal",
    "ReIssue":"ReIssue",
    "Void":"Void",
    "Refund":"Refund",



    "Fine price dollar":"Fine price dollar",
    "Fine price syria":"Fine price syria",

    "Add Returned Ticket":"Add Returned Ticket",
    "Ticket price fare":"Ticket price fare",

    "Ticket price":"Ticket price",

    "Rest amount":"Rest amount",


    "Payment price to supplier":"Payment price to supplier",

    "Commission":"Commission",
    "commission":"commission",

    "Recivable":"Recivable",
    "New Recivable":"New Recivable",

    "Print Recivables":"Print Recivables",
    "Recivable Information":"Recivable Information",
    "Old password":"Old password",
    "New password":"New password",
    "New password confirmation":"New password confirmation",


"Edit password":"Edit password",
    "Add new general daily":"Add new general daily",
    Bank: "Bank",
    
    travel: "Travel",
ticket_number: "Ticket Number",
customer_id: "Customer ID",
customer_name: "Customer Name",
supplier_id: "Supplier ID",
customer_price: "Customer Price",
supplier_commission_price: "Supplier Commission Price",
company_id: "Company ID",
ticket_price_dollar: "Ticket Price (Dollar)",
ticket_price_sy: "Ticket Price (Syrian Pound)",
pay_price_sy: "Payment Price (Syrian Pound)",
pay_price_dollar: "Payment Price (Dollar)",
account_id: "Account ID",

description: "Description",
account_type: "Account Type: Customer",
date: "Date: Current Date",
reverse_transaction: "Reverse Transaction: No",
bank_transfer: "Bank Transfer: No",
release_date: "Release Date",
ticket_price_dollar_fare: "Ticket Price (Dollar, Fare)",
ticket_price_sy_fare: "Ticket Price (SYP, Fare)",
    "Type":"Type",
    "Dollar Price To Be Received Supplier": "Dollar Price To Be Received Supplier",
    "Syrian Price To Be Received Supplier": "Syrian Price To Be Received Supplier",
    "Dollar Price To Be Paid Supplier":"Dollar Price To Be Paid Supplier",
    "Syrian Price To Be Paid Supplier": "Syrian Price To Be Paid Supplier",
    "Recivables":"Recivables",
    "Downlaod":"Downlaod",
    "Migrate":'Migrate',
    "Bank transfer":"Bank transfer",
    "Reverse transaction":"Reverse transaction",

    "Supplier commission price":"Supplier commission price",
    "Customer Price":"Customer Price",
    "End date":'End date',
    "Filter":'Filter',
    "Expense":"Expense",
    "Search Customer name":"Search Customer name",
    "Enter Customer name":"Enter Customer name",

    "Create Transaction":"Create Transaction",
    "Enter Customer name or ticket number":"Enter Customer name or ticket number",
    "Search Customer name or ticket number":"Search Customer name or ticket number",

    "Current General Daily Information":"Current General Daily Information",
    "Total Profit Dollar":"Total Profit Dollar",
    "Total Profit Sy":"Total Profit Sy",

    "Total Amount Received Sy":"Total Amount Received Sy",
    "Total Amount Received Dollar":"Total Amount Received Dollar",

    "Payment Price To Supplier":"Payment Price To Supplier",
    "Ticket Price Fare":"Ticket Price Fare",
    "Ticket Price":"Ticket Price",

    "Logout":"Logout",
    "Profile":"Profile",

    "Delete Item?":"Delete Item?",

    "Are you sure you want to delete":"Are you sure you want to delete",
    "Expances":"Expances",
    "Expance Information":"Expance Information",
    "Dollar Total Amount":"Dollar Total Amount",
    "Sy Total Amount":"Sy Total Amount",


    

    "Syrian Price To Be Paid":"Syrian Price To Be Paid",
    "Dollar Price To Be Paid":"Dollar Price To Be Paid",

    "Expenses Dollar":"Expenses Dollar",
    "Expenses Sy":"Expenses Sy",
    "Profits Dollar":"Profits Dollar",
    "Profits Sy":"Profits Sy",
    "Rest Amount Receivable Dollar":"Rest Amount Receivable Dollar",
    "Rest Amount Receivable On Fund Dollar":"Rest Amount Receivable On Fund Dollar",
    "Rest Amount Receivable Sy":"Rest Amount Receivable Sy",
    "Rest Amount Receivable On Fund Sy":"Rest Amount Receivable On Fund Sy",







    "Fund Information":"Fund Information",
    "Print Account Statement":"Print Account Statement",
    "Save":"Save",
    "Constent":"Constent",
    "ticket price dollar fare":"ticket price dollar fare",
    "ticket price syria fare":"ticket price syria fare",

    Ticket: "Ticket",

    Close: "Close",
    "Ticket Details": "Ticket Details",

    "Show Receivable Detial": "Show Receivable Detial",

    "Commission Sy": "Commission Sy",

    Active: "Active",
    Funds: "Funds",
    "Is Active": "Is Active",
    Employee: "Employee",

    "Account Name": "Account Name",
    "Dollar Price To Be Received": "Dollar Price To Be Received",
    "Ticket Number": "Ticket Number",
    "Company Name": "Company Name",
    "Supplier Name": "Supplier Name",
    "Customer Name": "Customer Name",
    "Creator Name": "Creator Name",
    "Creator Role": "Creator Role",
    "Rest Amount": "Rest Amount",
    "Transaction Date": "Transaction Date",
    "Syrian Payed": "Syrian Payed",
    "Dollar Payed": "Dollar Payed",
    Transactions: "Transactions",
    "Dollar Price": "Dollar Price",
    Section: "Section ",

    Currency: "Currency",
    "Received Amount Dollar": "Received Amount Dollar",
    "Paid Amount Dollar": "Paid Amount Dollar",
    "Commission Dollar": "Commission Dollar",
    "Received Amount Sy": "Received Amount Sy",
    "Paid Amount Sy": "Paid Amount Sy",
    "Release Date": "Release Date",
    "Start Date": "Start Date",
    "End Date": "End Date",
    GeneralDailys: "GeneralDailys",
    GeneralDaily: "GeneralDaily",
    Transaction: "Transaction",
    "Account Type": "Account Type",
    "Syrian Receive": "Syrian Receive",
    "Dollar Receive": "Dollar Receive",

    "View Last General Dailies": "View Last General Dailies",
    "View Current General Daily": "View Current General Daily",

    "Welcome to your companys": "Welcome to your companys",
    "Welcome to your account statement": "Welcome to your account statement",
    "Welcome to your customers": "Welcome to your customers",
    "Welcome to your employees": "Welcome to your employees",
    "Welcome to your funds": "Welcome to your funds",
    "Welcome to your current general daily":
      "Welcome to your current general daily",
    "Welcome to your generalDailys": "Welcome to your generalDailys",
    "Welcome to your suppliers": "Welcome to your suppliers",
    "Welcome to your tickets": "Welcome to your tickets",
    "Welcome to your transactions": "Welcome to your transactions",

    "Company ": "Company ",
    "a New Company ": "a New Company ",
    "Companys ": "Companys ",
    "EDIT Company ": "EDIT Company ",
    "Edit a Company ": "Edit a Company ",
    "Account statement ": "Account statement ",
    "Customer ": "Customer ",
    "a New Customer ": "a New Customer ",
    "Customers ": "Customers ",
    "EDIT Customer ": "EDIT Customer ",
    "Edit a Customer ": "Edit a Customer ",
    "Employee ": "Employee ",
    "a New Employee ": "a New Employee ",
    "EDIT Employee ": "EDIT Employee ",
    "Edit a Employee ": "Edit a Employee ",
    "Employees ": "Employees ",
    "Fund ": "Fund ",
    "a New Fund ": "a New Fund ",
    "EDIT Fund ": "EDIT Fund ",
    "Edit a Fund ": "Edit a Fund ",
    "Funds ": "Funds ",
    "GeneralDaily ": "GeneralDaily ",
    "a New GeneralDaily ": "a New GeneralDaily ",
    "Current General Daily ": "Current General Daily ",
    "EDIT GeneralDaily ": "EDIT GeneralDaily ",
    "Edit a GeneralDaily ": "Edit a GeneralDaily ",
    "GeneralDailys ": "GeneralDailys ",
    "Supplier ": "Supplier ",
    "a New Supplier ": "a New Supplier ",
    "EDIT Supplier ": "EDIT Supplier ",
    "Edit a Supplier ": "Edit a Supplier ",
    "Suppliers ": "Suppliers ",
    "Ticket ": "Ticket ",
    "a New Ticket ": "a New Ticket ",
    "EDIT Ticket ": "EDIT Ticket ",
    "Edit a Ticket ": "Edit a Ticket ",
    "Tickets ": "Tickets ",
    "Transaction ": "Transaction ",
    "a New Transaction ": "a New Transaction ",
    "EDIT Transaction ": "EDIT Transaction ",
    "Edit a Transaction ": "Edit a Transaction ",
    "Transactions ": "Transactions ",

    Action: "Action",
    Edit: "Edit",
    "Account statement": "Account statement",
    Delete: "Delete",
    Unban: "Unban",
    "Ban User": "Ban User",
    "Are you sure you want to ban the user?":
      "Are you sure you want to ban the user?",
    "Receivable Details": "Receivable Details",
    "New Company": "New Company",
    "Edit Company": "Edit Company",
    "New Customer": "New Customer",
    "Edit Customer": "Edit Customer",
    "New Employee": "New Employee",
    "Edit Employee": "Edit Employee",
    "New Fund": "New Fund",
    "Edit Fund": "Edit Fund",
    "New GeneralDaily": "New GeneralDaily",
    "Edit GeneralDaily": "Edit GeneralDaily",
    "New Supplier": "New Supplier",
    "Edit Supplier": "Edit Supplier",
    "New Ticket": "New Ticket",
    "Edit Ticket": "Edit Ticket",
    "New Transaction": "New Transaction",
    "Edit Transaction": "Edit Transaction",
    " Name": " Name",
    " Commission": " Commission",
    " Receivable": " Receivable",
    " Username": " Username",
    " Password": " Password",
    " Role": " Role",
    " Active": " Active",
    " Doller price": " Doller price",
    " Year": " Year",
    " Start date": " Start date",
    " Customer": " Customer",
    " Travel": " Travel",
    " Ticket number": " Ticket number",
    " Description": " Description",
    " Release date": " Release date",
    " Supplier": " Supplier",
    " Company": " Company",
    " ticket price syria": " ticket price syria",
    " Ticket price dollar": " Ticket price dollar",
    " Pay price sy": " Pay price sy",
    " Pay price dollar": " Pay price dollar",
    " Account type": " Account type",
    " Account": " Account",
    " pay price in syria": " pay price in syria",
    " pay price in dollar": " pay price in dollar",
    " description": " description",
    " Date": " Date",

    Customers: "Customers",
    Companys: "Companys",
    Suppliers: "Suppliers",
    Employees: "Employees",
    Tickets: "Tickets",
    "Current General Daily": "Current General Daily",
    transaction: "transaction",
    "Account Balance": "Account Balance",
    "Is Receivable": "Is Receivable",
    Creator: "Creator",
    "Created At": "Created At",
    "Account statement": "Account statement",

    Username: "Username",
    "Please enter a username": "Please enter a username",
    "Please enter a password at least 8 characters":
      "Please enter a password at least 8 characters",

    Dashboard: "Dashboard",
    Data: "Data",
    "Manage Team": "Manage Team",
    "Welcome to your team": "Welcome to your team",
    TEAM: "Team",
    Id: "Id",
    Name: "Name",
    Age: "Age",
    Email: "Email",
    Action: "Action",
    Edit: "Edit",
    Delete: "Delete",
    Add: "Add",
    Roles: "Roles",
    "Role name": "Role name",
    "Guard name": "Guard name",
    Sections: "Sections",
    Admins: "Admins",
    Providers: "Providers",
    Clients: "Clients",
    "Section name": "Section name",
    "Section description": "Section description",
    "Section visible": "Section visable",
    "Welcome to your sections": "Welcome to your sections",
    "Section name in arabic": "Section name in arabic",
    "Section name in english": "Section name in english",
    "Section description in arabic": "Section description in arabic",
    "Section description in english": "Section description in english",
    "Drag & Drop your files or": "Drag & Drop your files or",
    Browse: "Browse",
    Section: "Section",
    "a New Section": "a New Section",
    "New Section": "New Section",
    "Edit Section": "Edit Section",
    "ُEdit a  Section ": "Edit a  Section ",
    "Welcome to your admins": "Welcome to your admins",
    SYP: "SYP",
    Services: "Services",
    Protfolios: "Protfolios",
    Offers: "Offers",
    "System notifications": "System notifications",
    Orders: "Orders",
    Requests: "Requests",
    "On going": "On going",
    Pending: "Pending",
    Notification: "Notification",
    "Welcome to your services": "Welcome to your services",
    "Service title": "Service title",
    "Service body": "Service body",
    "Service duration": "Service duration",
    "Service price": "Service price",
    "Service visible": "Service visible",
    "Service tools": "Service tools",
    Owner: "Owner",
    "Edit Service": "Edit Service",
    Visibility: "Visibility",
    "Edit a  Service ": "Edit a  Service ",
    "Portfolio title": "Portfolio title",
    "Portfolio body": "Portfolio body",
    "Portfolio visible": "Portfolio visible",
    "Portfolio tools": "Portfolio tools",
    "Edit a  portfoilio ": "Edit a  portfoilio ",
    "Edit portfoilio": "Edit portfoilio",
    Offers: "Offers",
    "Welcome to your offers": "Welcome to your offers",
    "Offer price": "Offer price",
    "Offer delivery term": "Offer delivery term",
    "Offer description": "Offer description",
    "Offer edit price": "Offer edit price",
    "Offer edit delivery term": "Offer edit delivery term",
    "Order requests": "Order requests",
    "Welcome to your Order requests": "Welcome to your Order requests",
    "Order request title": "Order request title",
    "Order request start price": "Order request start price",
    "Order request start end": "Order request start end",
    "Order request delivery term": "Order request delivery term",
    "Order request description": "Order request description",
    "Order request type": "Order request type",
    Orders: "Orders",
    "Welcome to your Orders": "Welcome to your Orders",
    "Order title": "Order title",
    "Order price": "Order price",
    "Order delivery term": "Order delivery term",
    "Order description": "Order description",
    "Order type": "Order type",
    "Order status": "Order status",
    Designers: "Designers",
    Status: "Status",
    "Order finished at from designer": "Order finished at from designer",
    "Pending orders": "Pending orders",
    "Welcome to your Pending orders": "Welcome to your Pending orders",
    "Order bill": "Order bill",
    "Welcome to your system notifications":
      "Welcome to your system notifications",
    "Notification title": "Notification title",
    "Notification body": "Notification body",
    "Push notification": "Push notification",
    "Notification title": "Notification title",
    "Edit notification": "Edit notification",

    "Edit a  notification": "Edit a  notification",
    "Notification title in english": "Notification title in english",
    "Notification title in arabic": "Notification title in arabic",
    "Notification body in english": "Notification body in english",
    "Notification body in arabic": "Notification body in arabic",
    notification: "notification",
    All: "All",
    "Specific User": "Specific User",
    "a New Notification": "a New Notification",
    "Send notification to": "Send notification to",
    "Welcome to your admins": "Welcome to your admins",
    "Admin name": "Admin name",
    "Phone number": "Phone number",
    "Account type": "Account type",
    Country: "Country",
    "Id number": "Id number",
    Admin: "Admin",
    "a New Admin": "a New Admin",
    "Admin password": "Admin password",
    "Admin phone number": "Admin phone number",
    "Admin account type": "Admin account type",
    "Admin age": "Admin age",
    "Admin id number": "Admin id number",
    "Admin country": "Admin country",
    "New Admin": "New Admin",
    "Admin name": "Admin name",
    "Admin email": "Admin email",
    "Welcome to your providers": "Welcome to your providers",
    "Welcome to your clients": "Welcome to your clients",
    "Ban User": "Ban User",
    "Are you sure you want do ban user ": "Are you sure you want do ban user ",
    Cancel: "Cancel",
    Ban: "Ban",
    Blacklist: "Blacklist",
    "Welcome to your blacklist": "Welcome to your blacklist",
    Chats: "Chats",
  },
};
