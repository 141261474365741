import React, {  useEffect, useState } from "react";
import useHeaders from "../../hooks/useHeaders";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
 editExchange,getExchange
} from "./redux/service";
import { exchangeSelector} from "./redux/slice";


import EditExchange from "./EditExchange";
import { LoadingDialog } from "../../components";
import Validation from "../../services/Validation";
import {exchangeSchema } from "./validationSchema";
import { ToastContainer } from "react-toastify";

function ExchangeController({ child,open,setOpen }) {

  const { logging } = useSelector(exchangeSelector);
  const headers = useHeaders();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({})
  const _getExchange = async (params) => {
    dispatch(getExchange({ headers,params }))
  };
 
 




  const _editExchange = async (exchange) => {
    const exchangeValidationErrors = Validation.validation(exchangeSchema, exchange);
    if (exchangeValidationErrors) {
     setErrors(exchangeValidationErrors);
   } else {
    const _exchange={...exchange}
      dispatch(editExchange({ headers, exchange: _exchange}))
    
    }
    }



  const initChild = () => {
    switch (child) {
   

      case "edit-exchange":
        return (
          <EditExchange
          editExchagne={_editExchange}
          getExchange={_getExchange}
          open={open}
          setOpen={setOpen}
          errors={errors}
          setErrors={setErrors}
          />
        );

      default:
    }
  };
  return (
    <div>
      <ToastContainer/>

      {logging && <LoadingDialog open={logging} />}

      {initChild()}
    </div>
  );
}

export default ExchangeController;
