import i18n from "../../localization";

export const getEmployeeHeaderMapper = (data) => {
  const employeeHeaderMapper = [];
  const employeeDataMappar = getEmployeeMapper(data);
  const keys =
    employeeDataMappar.length > 0 ? Object.keys(employeeDataMappar[0]) : [];
  keys.map((key) => {
    let words = key.split("_");
    let capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    let result = capitalizedWords.join(" ");
    employeeHeaderMapper.push({
      field: key,
      headerName: i18n.t(result),
            width: (key=='name'||key=="account_name")?200:100,
    });
  });
  return employeeHeaderMapper;
};

export const getEmployeeMapper = (data) => {
  const employeeMapperData = [];
  data.map((item) => {
    employeeMapperData.push({
      id: item.id,
      name: item.name,
      username: item.username,
      is_active: item.is_active,
      role: item.role,
      created_at: (item.created_at)
    });
  });

  return employeeMapperData;
};

export const getOneEmployeeMapper = (data) => {
  console.log(data);

  const oneEmployeeMapperData = {
    name: data.name,
    username: data.username,
    is_active: data.is_active?true:false,
    role: data.role,
  };

  return oneEmployeeMapperData;
};
