import React, {  useEffect, useState } from "react";
import useHeaders from "../../hooks/useHeaders";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addExpance,deleteExpance,editExpance,getExpance,getExpances
} from "./redux/service";
import { ExpanceSelector,ExpanceSlice} from "./redux/slice";
import { useSidebarContext } from "../../components/global/sidebar/sidebarContext";
import Expances from "./Expances";

import { LoadingDialog } from "../../components";
import { ToastContainer } from "react-toastify";


function ExpancesController({ child }) {
  const { setSelected } = useSidebarContext();
  const { logging } = useSelector(ExpanceSelector);
  const navigate = useNavigate();
  const headers = useHeaders();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({})
  const _getExpances = async (params) => {
    dispatch(getExpances({ headers,params }))
  };
 


  useEffect(() => {
    setSelected&& setSelected("Expances");
  }, []);

  const initChild = () => {
    switch (child) {
      case "Expances": {
        return <Expances  getExpances={_getExpances}/>;
      }
    
      default:
    }
  };
  return (
    <div>
      <ToastContainer/>

      {logging && <LoadingDialog open={logging} />}

      {initChild()}
    </div>
  );
}

export default ExpancesController;
