import { Button, Container } from "@mui/material";

export default function FullWidthButton({ label, onClick }) {
    return (
        <Container
            sx={{ padding: { lg: '15px 20px', md: '15px 10px', sm: '15px 0px', xs: '15px 0px' }, }}
        >
            <Button type='primary'
                // color="secondary"
                sx={{borderRadius:'15px'}}
                variant="text"
                onClick={() => onClick()}
                className='btn-color'
                fullWidth
            >
                {label}
            </Button>
        </Container>
    )
}