import React, {  useEffect, useState } from "react";
import useHeaders from "../../hooks/useHeaders";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addReturnedTicket,
  addTicket,deleteTicket,editTicket,getCustomerRecivable,getTicket,getTickets, refundTicket, reissueTicket, voidTicket
} from "./redux/service";
import { ticketSelector,ticketSlice} from "./redux/slice";
import { useSidebarContext } from "../../components/global/sidebar/sidebarContext";
import Tickets from "./Tickets";
import AddTicket from "./AddTicket";
import EditTicket from "./EditTicket";
import { LoadingDialog } from "../../components";
import Validation from "../../services/Validation";
import { addReturnedTicketSchema, addTicketSchema, editTicketSchema, refundTicketSchema, reissueTicketSchema } from "./validationSchema";
import { getCompanys } from "../company/redux/service";
import { getCustomers } from "../customer/redux/service";
import {  getSuppliers } from "../supplier/redux/service";
import { ToastContainer } from "react-toastify";
import ConfirmationDialgo from "../../components/ConfirmationDialgo";
import AddReturnedTicket from "./AddReturnedTicket";
import { deleteEmptyValues } from "../../services/functions";
import { getConstants } from "../constant/redux/service";
import { constantSelector } from "../constant/redux/slice";

function TicketsController({ child }) {

  const { setSelected } = useSidebarContext();
  const { logging } = useSelector(ticketSelector);
  const navigate = useNavigate();
  const headers = useHeaders();
  const dispatch = useDispatch();
  const {constants}=useSelector(constantSelector)
  const [open, setOpen] = useState(false)
  const [accept, setAccept] = useState(false)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const _getTickets = async (params) => {
    dispatch(getTickets({ headers,params }))
  };
  const _deleteTicket = async (id) => {
    
    dispatch(deleteTicket({ headers,id })).then((res) => {
      if (res.error) {
      } else {
        dispatch(getTickets({ headers }))
        dispatch(ticketSlice.actions.delete(id));
        

      }
    });
  };

  const _voidTicket = async (ticket) => {
    
    dispatch(voidTicket({ headers, ticket:deleteEmptyValues(ticket) })).then((res) => {
      if (res.error) {
      } else {
        dispatch(getTickets({ headers }))

      }
    });
  };

  const _refundTicket = async (ticket) => {
    
    const ticketValidationErrors = Validation.validation(refundTicketSchema, ticket);
    if (ticketValidationErrors) {
     setErrors(ticketValidationErrors);
   } else {
    dispatch(refundTicket({ headers, ticket })).then((res) => {
      if (res.error) {
      } else {
        dispatch(getTickets({ headers }))

      }
    });
  }
  };

  const _reissueTicket = async (ticket) => {
    
    const ticketValidationErrors = Validation.validation(reissueTicketSchema, ticket);
    if (ticketValidationErrors) {
     setErrors(ticketValidationErrors);
   } else {
    dispatch(reissueTicket({ headers, ticket })).then((res) => {
      if (res.error) {
      } else {
        dispatch(getTickets({ headers }))
      }
    });
  }
  };
  const _addTicket = async (ticket,suppliers,companies) => {


  
     const ticketValidationErrors = Validation.validation(addTicketSchema, ticket);
     if (ticketValidationErrors) {
      console.log(ticketValidationErrors);
      setErrors(ticketValidationErrors);
    } else {

      setOpen(true)
      setData({
        ticket,suppliers,companies,constants
      })

      }
  
    
  };

  const _addReturnedTicket = async (ticket,suppliers,customers) => {


  
    const ticketValidationErrors = Validation.validation(addReturnedTicketSchema, ticket);
    if (ticketValidationErrors) {
     setErrors(ticketValidationErrors);
   } else {

     setOpen(true)
     setData({
       ticket,suppliers,customers,constants
     })

     }
 
   
 };



  const _getTicket = async (id) => {
    dispatch(getTicket({ headers, id }));
  };
  const _editTicket = async (id, ticket) => {
    const ticketValidationErrors = Validation.validation(editTicketSchema, ticket);
    console.log(ticket);
    if (ticketValidationErrors) {
      console.log(ticket);
     setErrors(ticketValidationErrors);
   } else {
    const _ticket={...ticket}
      dispatch(editTicket({ headers, ticket: _ticket,id})).then((res) => {
        if (res.error) {
        } else {
          navigate("/tickets");
        }
      });
    }
    }


    const _getCompanies=()=>{
      dispatch(getCompanys({headers,params:{account_type:'Company'}}))
    }

    const _getCustomers=(params)=>{
      dispatch(getCustomers({headers,params}))
    }

    const _getConstants=(params)=>{
      dispatch(getConstants({headers,params}))
    }

    const _getSuppliers=()=>{
      dispatch(getSuppliers({headers,params:{account_type:'Supplier'}}))
    }

    const _getCustomerRecivable=(id)=>{
      dispatch(getCustomerRecivable({headers,id}))
    }

    const onClose=()=>{
      setOpen(false)
      setAccept(false)
      setData({})
    }

    const onOk=()=>{
      setAccept(true)
      setOpen(false)
      // setData({})
    }

    


  useEffect(() => {
    setSelected&& setSelected("Tickets");
  }, []);

  useEffect(() => {
    if(accept){
      setAccept(false)
      const _ticket = Object.fromEntries(
        Object.entries(data?.ticket).reduce((acc, [key, value]) => {
          if (value&&value !== null && value !== " ") {
            acc.push([key, value]);
          }
          return acc;
        }, [])
      );
        
    
      _ticket.supplier_id=data?.suppliers.filter((supplier)=>(supplier.name==_ticket.supplier_id))[0].id
      // _ticket.customer_id=data?.customers?.filter((customer)=>(customer.name==customer.customer_id))[0].id

      _ticket.company_id=data?.companies.filter((company)=>(company.name==_ticket.company_id))[0].id
      _ticket.currency_id=data?.constants.filter((constant)=>(constant.name==_ticket.currency_id))[0].id
      if (_ticket["customer_id"]) {
        _ticket["customer_name"] = null;
    }
   
     
     //console.log(_ticket);
       dispatch( _ticket.company_id?
        addTicket({ headers,ticket:_ticket }):addReturnedTicket({ headers,ticket:_ticket })).then((res) => {
         if (res.error) {
         } else {
           navigate("/tickets");
         }
       });
       setData({})
    }
  }, [accept])


  useEffect(() => {
    Promise.all([_getCustomers({}), _getCompanies(), _getSuppliers(),_getConstants()]);
    
  }, [])
  
  

  const initChild = () => {
    switch (child) {
      case "tickets": {
        return <Tickets reissueTicketSubmit={_reissueTicket} refindTicketSubmit={_refundTicket} errors={errors} setErrors={setErrors} voidTicketSubmit={_voidTicket} getTickets={_getTickets} deleteTicket={_deleteTicket}/>;
      }
      case "add-ticket":
        return (
          <AddTicket
        getCustomerRecivable={_getCustomerRecivable}
        addTicket={_addTicket}
        errors={errors}
        setErrors={setErrors}
        getCompanies={_getCompanies}
        getSuppliers={_getSuppliers}
        getCustomers={_getCustomers}


          />
        );

        case "add-returned-ticket":
          return (
            <AddReturnedTicket
          addReturnedTicket={_addReturnedTicket}
          errors={errors}
          setErrors={setErrors}
          getSuppliers={_getSuppliers}
          getCustomers={_getCustomers}
          getTickets={_getTickets}
            />
          );

      case "edit-ticket":
        return (
          <EditTicket

          editTicket={_editTicket}
          getTicket={_getTicket}
          errors={errors}
          setErrors={setErrors}
          />
        );

      default:
    }
  };
  return (
    <div>
      <ToastContainer/>
      {open&&<ConfirmationDialgo keyValueObject={data?.ticket} onClose={onClose} open={open} onOk={onOk} />}
      {logging && <LoadingDialog open={logging} />}

      {initChild()}
    </div>
  );
}

export default TicketsController;
