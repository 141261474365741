import { DELETE_TICKET, EDIT_TICKET, TICKET, TICKETS, STORE_TICKET, CUSTOMER_RECEIVABLE, STORE_RETURNED_TICKET } from "../../constent/urls"
import Requests from "../../services/Requests"
import HandleError from "../../services/HandleError"

class Ticket{
    static getTickets = async (headers,params,fail) => {
        let result=await Requests.get(TICKETS,headers,params );
        //console.log(result);
        return HandleError.handleError(result,()=>{return result.data},fail)
    }

    static getCustomerRecivable = async (headers,id,fail) => {
        let result=await Requests.get(CUSTOMER_RECEIVABLE+id+"/receivable-details",headers );
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static getTicket = async (headers,params,fail) => {
        let result=await Requests.get(TICKET,headers,params);
        //console.log(result);
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static addTicket = async (headers,body,fail) => {
        let result=await Requests.post(STORE_TICKET,headers,body)
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static addReturnedTicket = async (headers,body,fail) => {
        let result=await Requests.post(STORE_RETURNED_TICKET,headers,body)
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static editTicket = async (headers,body,id,fail) => {
        let result=await Requests.patch(EDIT_TICKET+id,headers,body)
        //console.log(result);
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static deleteTicket = async (headers,id,fail) => {
        let result=await Requests.delete(DELETE_TICKET+id,headers)
        return HandleError.handleError(result,()=>{return result},fail)
    }

    static voidTicket = async (headers,ticket,fail) => {
        let result=await Requests.post(DELETE_TICKET+ticket.id+'/void',headers,ticket)
        return HandleError.handleError(result,()=>{return result},fail)
    }

    static refundTicket = async (headers,ticket,fail) => {
        let result=await Requests.post(DELETE_TICKET+ticket.id+'/refund',headers,ticket)
        return HandleError.handleError(result,()=>{return result},fail)
    }

    static reissueTicket = async (headers,ticket,fail) => {
        let result=await Requests.post(DELETE_TICKET+ticket.id+'/reissue',headers,ticket)
        return HandleError.handleError(result,()=>{return result},fail)
    }

}
export default Ticket;