import React, {  useEffect, useState } from "react";
import useHeaders from "../../hooks/useHeaders";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addTransaction,deleteTransaction,editTransaction,getTransaction,getTransactions
} from "./redux/service";
import { transactionSelector,transactionSlice} from "./redux/slice";
import { useSidebarContext } from "../../components/global/sidebar/sidebarContext";
import Transactions from "./Transactions";
import AddTransaction from "./AddTransaction";
import EditTransaction from "./EditTransaction";
import { LoadingDialog } from "../../components";
import Validation from "../../services/Validation";
import { addTransactionSchema, editTransactionSchema } from "./validationSchema";
import { getCustomers } from "../customer/redux/service";
import { getCompany, getCompanys } from "../company/redux/service";
import { companySelector } from "../company/redux/slice";
import { ToastContainer } from "react-toastify";
import ConfirmationDialgo from "../../components/ConfirmationDialgo";

function TransactionsController({ child }) {
  const { setSelected } = useSidebarContext();
  const { logging } = useSelector(transactionSelector);
  const navigate = useNavigate();
  const headers = useHeaders();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({})
  const [open, setOpen] = useState(false)
  const [accept, setAccept] = useState(false)
  const [data, setData] = useState({})
  const {companys:accounts}=useSelector(companySelector)

  const _getTransactions = async (params) => {
    dispatch(getTransactions({ headers,params }))
  };

  const _getAccounts = async (params) => {
    //console.log(params);
    dispatch(getCompanys({ headers,params }))
  };
  const _deleteTransaction = async (id) => {
    dispatch(deleteTransaction({ headers, id })).then((res) => {
      if (res.error) {
      } else {
        dispatch(transactionSlice.actions.delete(id));
      }
    });
  };
  const _addTransaction = async (transaction) => {
      if(transaction.account_type=='Expense')
      transaction.account_id=1

     const transactionValidationErrors = Validation.validation(addTransactionSchema, transaction);
     if (transactionValidationErrors) {
      console.log(transactionValidationErrors);
      setErrors(transactionValidationErrors);
    } else {
      setOpen(true)
      setData({
        transaction
      })
      }
  
    
  };



  const _getTransaction = async (id) => {
    dispatch(getTransaction({ headers, id }));
  };
  const _editTransaction = async (id, transaction) => {
    const transactionValidationErrors = Validation.validation(editTransactionSchema, transaction);
    if (transactionValidationErrors) {
     setErrors(transactionValidationErrors);
   } else {
    const _transaction={...transaction}
      dispatch(editTransaction({ headers, transaction: _transaction,id})).then((res) => {
        if (res.error) {
        } else {
          navigate("/transactions");
        }
      });
    }
    }

    const onClose=()=>{
      setAccept(false)
      setOpen(false)
      setData({})
    }

    const onOk=()=>{
      setAccept(true)
      setOpen(false)
      // setData({})
    }


  useEffect(() => {
    setSelected&& setSelected("Transactions");
  }, []);

  useEffect(() => {
    if(accept){
      setAccept(false)
      console.log(data?.transaction);
      const _transaction=data?.transaction
         dispatch(addTransaction({ headers,transaction:_transaction })).then((res) => {
           if (res.error) {
           } else {
             navigate("/funds");
           }
         });
       setData({})
    }
  }, [accept])

  const initChild = () => {
    switch (child) {
      case "transactions": {
        return <Transactions deleteTransaction={_deleteTransaction} getTransactions={_getTransactions}/>;
      }
      case "add-transaction":
        return (
          <AddTransaction
          getAccounts={_getAccounts}
        addTransaction={_addTransaction}
        errors={errors}
        setErrors={setErrors}
          />
        );

      case "edit-transaction":
        return (
          <EditTransaction
          editTransaction={_editTransaction}
          getTransaction={_getTransaction}
          errors={errors}
          setErrors={setErrors}
          />
        );

      default:
    }
  };
  return (
    <div>
      <ToastContainer/>
      {open&&<ConfirmationDialgo keyValueObject={data?.transaction} onClose={onClose} open={open} onOk={onOk} />}

      {logging && <LoadingDialog open={logging} />}

      {initChild()}
    </div>
  );
}

export default TransactionsController;
