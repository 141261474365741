import { createSlice } from "@reduxjs/toolkit"
import { addTransaction, deleteTransaction, editTransaction, getTransactions, getTransaction } from "./service"
import { fulfilledReducer, pendingReducer, rejectedReducer } from "../../../redux/globalReducer"
const initialState = {
    status: '',
    logging: false,
    transactions:[],
    transaction:{},
}
export const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        delete(state,action){
            return {
                ...state,transactions:[...state.transactions].filter((transaction)=>transaction.id!=action.payload)
            }
       
        }
    },
    extraReducers: {
        [getTransactions.pending]: pendingReducer,
        [getTransactions.fulfilled]: (state, { payload }) => {
            state.transactions= payload?payload:[]
            state.status = 'fulfilled'
            state.logging = false
        },
        [getTransactions.rejected]: rejectedReducer,

        [deleteTransaction.pending]: pendingReducer,
        [deleteTransaction.fulfilled]: fulfilledReducer,
        [deleteTransaction.rejected]: rejectedReducer,

        
        [addTransaction.pending]: pendingReducer,
        [addTransaction.fulfilled]: fulfilledReducer,
        [addTransaction.rejected]: rejectedReducer,

        [getTransaction.pending]: pendingReducer,
        [getTransaction.fulfilled]: (state, { payload }) => {
           
            state.transaction= payload?payload:{}
            state.status = 'fulfilled'
            state.logging = false
        },
        [getTransaction.rejected]: pendingReducer,

        [editTransaction.pending]: pendingReducer,
        [editTransaction.fulfilled]: fulfilledReducer,
        [editTransaction.rejected]: rejectedReducer,
 
    }
})

export const transactionSelector = (state) => state.transaction;