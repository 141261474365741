import { createSlice } from "@reduxjs/toolkit"
import { addTicket, deleteTicket, editTicket, getTickets, getTicket, getCustomerRecivable, addReturnedTicket, refundTicket, reissueTicket, voidTicket } from "./service"
import { fulfilledReducer, pendingReducer, rejectedReducer } from "../../../redux/globalReducer"
const initialState = {
    status: '',
    logging: false,
    tickets:[],
    ticket:{},
    total:0,
    receivableDetails:[]
}
export const ticketSlice = createSlice({
    name: 'ticket',
    initialState,
    reducers: {
        delete(state,action){
            return {
                ...state,tickets:[...state.tickets].filter((ticket)=>ticket.id!=action.payload)
            }
       
        },
        clearReceivableDetails(state,action){
            return {
                ...state,receivableDetails:[]
            }
       
        }
    },
    extraReducers: {
        [getTickets.pending]: pendingReducer,
        [getTickets.fulfilled]: (state, { payload }) => {
            state.tickets= payload?payload.data:[]
            state.total=payload.total?payload.total:state.total
            state.status = 'fulfilled'
            state.logging = false
        },
        [getTickets.rejected]: rejectedReducer,

        [getCustomerRecivable.pending]: pendingReducer,
        [getCustomerRecivable.fulfilled]: (state, { payload }) => {
            state.receivableDetails= payload?payload:[]
            state.status = 'fulfilled'
            state.logging = false
        },
        [getCustomerRecivable.rejected]: rejectedReducer,

        [deleteTicket.pending]: pendingReducer,
        [deleteTicket.fulfilled]:  (state, ) => {
            state.status = 'fulfilled'
        },
        [deleteTicket.rejected]: rejectedReducer,

        [voidTicket.pending]: pendingReducer,
        [voidTicket.fulfilled]:  (state, ) => {
            state.status = 'fulfilled'
        },
        [voidTicket.rejected]: rejectedReducer,

        [refundTicket.pending]: pendingReducer,
        [refundTicket.fulfilled]:  (state, ) => {
            state.status = 'fulfilled'
        },
        [refundTicket.rejected]: rejectedReducer,

        [reissueTicket.pending]: pendingReducer,
        [reissueTicket.fulfilled]:  (state, ) => {
            state.status = 'fulfilled'
        },
        [reissueTicket.rejected]: rejectedReducer,

        
        [addTicket.pending]: pendingReducer,
        [addTicket.fulfilled]: fulfilledReducer,
        [addTicket.rejected]: rejectedReducer,

        [addReturnedTicket.pending]: pendingReducer,
        [addReturnedTicket.fulfilled]: fulfilledReducer,
        [addReturnedTicket.rejected]: rejectedReducer,

        [getTicket.pending]: pendingReducer,
        [getTicket.fulfilled]: (state, { payload }) => {
           
            state.ticket= payload?payload:{}
            state.status = 'fulfilled'
            state.logging = false
        },
        [getTicket.rejected]: pendingReducer,

        [editTicket.pending]: pendingReducer,
        [editTicket.fulfilled]: fulfilledReducer,
        [editTicket.rejected]: rejectedReducer,
 
    }
})

export const ticketSelector = (state) => state.ticket;