import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { t } from 'i18next';

const ConfirmationDialgo = ({ open, onClose,onOk, keyValueObject }) => {
  
console.log(keyValueObject);
  const handleClose = () => {

    onClose();
  };

  const handleOk = () => {

    onOk();
  };

  //console.log(keyValueObject);
  return (
    <Dialog fullWidth  open={open} onClose={handleClose}>
      <DialogTitle sx={{textAlign:'center',fontSize:'40px',fontWeight:'bold'}}>تاكيد العملية</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{direction:'rtl',fontSize:'22px'}}>
          هل انت متاكد من تنفيذ العملية
        </DialogContentText>
        <ul style={{direction:'rtl'}}>
          {Object.entries(keyValueObject).map(([key, value]) => (
            value&&<li style={{fontSize:'22px'}} key={key}>
              <strong>{t(key)}:</strong> {t(value)}
            </li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("Close")}
        </Button>
        <Button onClick={handleOk} color="primary">
          {("OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialgo;
