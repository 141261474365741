import { createAsyncThunk } from "@reduxjs/toolkit";
import Ticket from "../TicketApi";

export const getTickets= createAsyncThunk(
  "ticketSlice/getTickets",
  async ({ headers,params }, { rejectWithValue }) => {
    return await Ticket.getTickets(headers,params, () => {
      return rejectWithValue();
    });
  }
);

export const getCustomerRecivable= createAsyncThunk(
  "ticketSlice/getCustomerRecivable",
  async ({ headers,id }, { rejectWithValue }) => {
    return await Ticket.getCustomerRecivable(headers,id, () => {
      throw new Error("Failed ")
    });
  }
);
export const deleteTicket = createAsyncThunk(
  "ticketSlice/deleteTicket",
  async ({ headers, id }, { rejectWithValue }) => {
    return await Ticket.deleteTicket(headers, id, () => {
      throw new Error("Failed delete ticket");
    });
  }
);

export const voidTicket = createAsyncThunk(
  "ticketSlice/voidTicket",
  async ({ headers, ticket }, { rejectWithValue }) => {
    return await Ticket.voidTicket(headers, ticket, () => {
      throw new Error("Failed void ticket");
    });
  }
);

export const refundTicket = createAsyncThunk(
  "ticketSlice/refundTicket",
  async ({ headers, ticket }, { rejectWithValue }) => {
    return await Ticket.refundTicket(headers, ticket, () => {
      throw new Error("Failed refund ticket");
    });
  }
);

export const reissueTicket = createAsyncThunk(
  "ticketSlice/reissueTicket",
  async ({ headers, ticket }, { rejectWithValue }) => {
    return await Ticket.reissueTicket(headers, ticket, () => {
      throw new Error("Failed reissue ticket");
    });
  }
);

export const addTicket = createAsyncThunk('ticketSlice/addTicket', async ({ headers,ticket }, { rejectWithValue }) => {
  return await Ticket.addTicket(headers, ticket,()=>{
    throw new Error("Failed store ticket")});
})

export const addReturnedTicket = createAsyncThunk('ticketSlice/addReturnedTicket', async ({ headers,ticket }, { rejectWithValue }) => {
  return await Ticket.addReturnedTicket(headers, ticket,()=>{
    throw new Error("Failed store returned ticket")});
})

export const getTicket = createAsyncThunk(
  "ticketSlice/getTicket",
  async ({ headers,id }, { rejectWithValue }) => {
    return await Ticket.getTicket(headers,{id}, () => {
      return rejectWithValue();
    });
  }
);

export const editTicket = createAsyncThunk('ticketSlice/editTicket', async ({ headers,ticket,id }, { rejectWithValue }) => {
  return await Ticket.editTicket(headers, ticket,id,()=>{
    throw new Error("Failed edit ticket")});
})