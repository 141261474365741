import Joi from "joi-browser";

export const addCompanySchema = {
    name: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a customer name ",
      };
    }),


    account_type: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a account type ",
      };
    }),
};

export const editCompanySchema = {
    name: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a customer name ",
      };
    }),

  

    account_type: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a account type ",
      };
    }),
};
