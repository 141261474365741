import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useMediaQuery} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Header } from "../../components";
import { ADD_SUPPLIER_FORM } from "./form";
import { useSelector } from "react-redux";
import { constantSelector } from "../constant/redux/slice";
import { getConstantData } from "../../services/functions";

const AddSupplier = ({addSupplier,errors,setErrors,getConstants}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {t}=useTranslation()
  const { constants } = useSelector(constantSelector);

  const [supplier, setSupplier] = useState({
    name:'',
    account_type:'Supplier',
    commission:''
  })
   const [fieldsData, setFieldsData] = useState({
  })

  const [selectData, setSelectData] = useState({
    
    currency_id: [],
  });
  
  const handleFormSubmit = () => {
    addSupplier(supplier)
  };
  const handleChange=(event)=>{
      const {name,value}=event.target
      setSupplier({...supplier,[name]:value})
          setErrors((prevErrors)=>(
        {...prevErrors,
        [name]:''
        }
    ))
  }

  const handleChangeSwitch=(event)=>{
    const {name,checked}=event.target
    setSupplier({...supplier,[name]:checked})
    setErrors((prevErrors)=>(
      {...prevErrors,
      [name]:''
      }
  ))
}

const handleColor=(color)=>{
     setSupplier({...supplier,color:color.hex})
        setErrors((prevErrors)=>(
      {...prevErrors,
      color:''
      }
  ))
}

const handleChangeField=(event,itemName,index)=>{
    const {name,value}=event.target
    //console.log(name,value,index);
    const fields=supplier[itemName]
    fields[index][name]=value
    setSupplier({...supplier,[itemName]:fields})
}
const addField=(name)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]+1
    }
))
    const fields=supplier[name]
    fields.push({})
    setSupplier((prevSupplier)=>(
      {...prevSupplier,
      [name]:fields
      }
  ))

}

const deleteField=(name,index)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]-1
    }
))
    const fields=supplier[name]
    fields.splice(index,1)
    setSupplier({...supplier,[name]:fields})
}

useEffect(() => {
 
  setSelectData({
    currency_id: getConstantData(constants),
 
  });
}, [constants]);


  return (
    <Box m="20px">
      <Header title="Supplier" subtitle="a New Supplier" />
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
           
            <Form selectData={selectData} handleColor={handleColor} handleChangeSwitch={handleChangeSwitch}  errors={errors} data={supplier}  handleChange={handleChange} type='add' form={ADD_SUPPLIER_FORM}  />
            
          </Box>

          <Box display="flex" justifyContent="end" mt="20px">
            <Button onClick={()=>{handleFormSubmit()}} type="submit" color="secondary" variant="contained">
              {t("New Supplier")}
            </Button>
          </Box>
     
  
    </Box>
  );
};

export default AddSupplier;
