import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useMediaQuery} from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {Form, Header } from "../../components";
import { EDIT_GENERALDAILY_FORM } from "./form";
import { generalDailySelector } from "./redux/slice";
import { getOneGeneralDailyMapper } from "./mapper";
import { useParams } from "react-router-dom";

const EditGeneralDaily = ({editGeneralDaily,getGeneralDaily,errors,setErrors}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {generalDaily}=useSelector(generalDailySelector)
  const {id}=useParams()
  const {t}=useTranslation()
  const [_generalDaily, setGeneralDaily] = useState({
    name:"",
    year:"",
    start_date:"",
    end_date:"",

  })
   const [fieldsData, setFieldsData] = useState({
  })

  const handleFormSubmit = () => {
    editGeneralDaily(id,_generalDaily)
  };
  const handleChange=(event)=>{
      const {name,value}=event.target
      setGeneralDaily({..._generalDaily,[name]:value})
      setErrors((prevErrors)=>(
        {...prevErrors,
        [name]:''
        }
    ))
  }

  const handleChangeSwitch=(event)=>{
    const {name,checked}=event.target
    setGeneralDaily({..._generalDaily,[name]:checked})
    setErrors((prevErrors)=>(
      {...prevErrors,
      [name]:''
      }
  ))
}

const handleColor=(color)=>{
     setGeneralDaily({...generalDaily,color:color.hex})
        setErrors((prevErrors)=>(
      {...prevErrors,
      color:''
      }
  ))
}

const handleChangeField=(event,itemName,index)=>{
    const {name,value}=event.target
    //console.log(name,value,index);
    const fields=generalDaily[itemName]
    fields[index][name]=value
    setGeneralDaily({...generalDaily,[itemName]:fields})
}
const addField=(name)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]+1
    }
))
    const fields=generalDaily[name]
    fields.push({})
    setGeneralDaily((prevGeneralDaily)=>(
      {...prevGeneralDaily,
      [name]:fields
      }
  ))

}

const deleteField=(name,index)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]-1
    }
))
    const fields=generalDaily[name]
    fields.splice(index,1)
    setGeneralDaily({...generalDaily,[name]:fields})
}

  useEffect(() => {
   getGeneralDaily(id)
  }, [])

  useEffect(() => {
    setGeneralDaily(getOneGeneralDailyMapper(generalDaily))
  }, [generalDaily])
    //console.log(_generalDaily);
  return (
    <Box m="20px">
      <Header title="GeneralDaily" subtitle="Edit a GeneralDaily" />
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
           
            <Form handleColor={handleColor} handleChangeSwitch={handleChangeSwitch} errors={errors} data={_generalDaily}  handleChange={handleChange}  form={EDIT_GENERALDAILY_FORM}  />
          </Box>
         

          <Box display="flex" justifyContent="end" mt="20px">
            <Button onClick={()=>{handleFormSubmit()}} type="submit" color="secondary" variant="contained">
              {t("Edit GeneralDaily")}
            </Button>
          </Box>
     
  
    </Box>
  );
};

export default EditGeneralDaily;
