import React, { useState, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { t } from 'i18next';

// a new React context for the delete dialog
const DeleteDialogContext = React.createContext();

// a wrapper component that provides the context to its children
export const DeleteDialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [onSubmitDelete, setOnSubmitDelete] = useState(null);

  // Define a function to handle the opening and closing of the dialog
  const handleClickOpen = (item,onSubmit) => {
    setOnSubmitDelete(() => onSubmit);
    setDeleteItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setDeleteItem(null);
    setOpen(false);
  };

  // Define a function to handle the deletion of the item when the "Delete" button is clicked
  const handleDelete = () => {
    // logic for deleting the item
    if (onSubmitDelete) {
      onSubmitDelete(deleteItem);
    }
    handleClose();
  };

  return (
    <DeleteDialogContext.Provider value={{ handleClickOpen }}>
      {children}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("Delete Item?")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
           {t( "Are you sure you want to delete")} {deleteItem && `"${deleteItem}"`}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
          <Button onClick={handleDelete}>{t("Delete")}</Button>
        </DialogActions>
      </Dialog>
    </DeleteDialogContext.Provider>
  );
};

// a custom hook to access the delete dialog context
export const useDeleteDialog = () => useContext(DeleteDialogContext);
