import { DELETE_TRANSACTION, EDIT_TRANSACTION, TRANSACTION, TRANSACTIONS, STORE_TRANSACTION } from "../../constent/urls"
import Requests from "../../services/Requests"
import HandleError from "../../services/HandleError"

class Transaction{
    static getTransactions = async (headers,params,fail) => {
        let result=await Requests.get(TRANSACTIONS,headers,params );
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static getTransaction = async (headers,id,fail) => {
        let result=await Requests.get(TRANSACTION+id,headers);
        
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static addTransaction = async (headers,body,fail) => {
        let result=await Requests.post(STORE_TRANSACTION,headers,body)
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static editTransaction = async (headers,body,id,fail) => {
        let result=await Requests.patch(EDIT_TRANSACTION+id,headers,body)
        //console.log(result);
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static deleteTransaction = async (headers,id,fail) => {
        let result=await Requests.delete(DELETE_TRANSACTION+id,headers)
        return HandleError.handleError(result,()=>{return result},fail)
    }

}
export default Transaction;