import React, { useState, createContext, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProSidebarProvider } from "react-pro-sidebar";
import MyProSidebar from "./MyProSidebar";

const SidebarContext = createContext({});

export const MyProSidebarProvider = ({ children }) => {
  const {i18n}=useTranslation()
  const [selected, setSelected] = useState("Dashboard");
  const [sidebarWidth, setSideBarWidth] = useState(0);

  const [sidebarRTL, setSidebarRTL] = useState(i18n.language=='en'?false:true);
  const [sidebarBackgroundColor, setSidebarBackgroundColor] =
    useState(undefined);
  const [sidebarImage, setSidebarImage] = useState(undefined);
  return (
    <ProSidebarProvider>
      <SidebarContext.Provider
        value={{
          sidebarBackgroundColor,
          setSidebarBackgroundColor,
          selected,
          setSelected,
          sidebarImage,
          setSidebarImage,
          sidebarRTL,
          setSidebarRTL,
          sidebarWidth,
          setSideBarWidth
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: sidebarRTL ? "row-reverse" : "row",
          }}
        >
          <MyProSidebar  />
          {children}
        </div>
      </SidebarContext.Provider>
    </ProSidebarProvider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);
