import { createSlice } from "@reduxjs/toolkit"
import { addGeneralDaily, deleteGeneralDaily, editGeneralDaily, getGeneralDailys, getGeneralDaily, getCurrentGeneralDaily, migrateGeneralDaily } from "./service"
import { fulfilledReducer, pendingReducer, rejectedReducer } from "../../../redux/globalReducer"
const initialState = {
    status: '',
    logging: false,
    generalDailys:[],
    total:0,
    currentGeneralDaily:[],
    generalDaily:{},
}
export const generalDailySlice = createSlice({
    name: 'generalDaily',
    initialState,
    reducers: {
        delete(state,action){
            return {
                ...state,generalDailys:[...state.generalDailys].filter((generalDaily)=>generalDaily.id!=action.payload)
            }
       
        }
    },
    extraReducers: {
        [getGeneralDailys.pending]: pendingReducer,
        [getGeneralDailys.fulfilled]: (state, { payload }) => {
            state.generalDailys= payload?payload:[]
           
            state.status = 'fulfilled'
            state.logging = false
        },
        [getGeneralDailys.rejected]: rejectedReducer,

        [deleteGeneralDaily.pending]: pendingReducer,
        [deleteGeneralDaily.fulfilled]: fulfilledReducer,
        [deleteGeneralDaily.rejected]: rejectedReducer,

        
        [addGeneralDaily.pending]: pendingReducer,
        [addGeneralDaily.fulfilled]: fulfilledReducer,
        [addGeneralDaily.rejected]: rejectedReducer,

        [migrateGeneralDaily.pending]: pendingReducer,
        [migrateGeneralDaily.fulfilled]: fulfilledReducer,
        [migrateGeneralDaily.rejected]: rejectedReducer,

        [getGeneralDaily.pending]: pendingReducer,
        [getGeneralDaily.fulfilled]: (state, { payload }) => {
           
            state.generalDaily= payload?payload:{}
            state.status = 'fulfilled'
            state.logging = false
        },
        [getGeneralDaily.rejected]: pendingReducer,

        [getCurrentGeneralDaily.pending]: pendingReducer,
        [getCurrentGeneralDaily.fulfilled]: (state, { payload }) => {
           
            state.currentGeneralDaily= payload?payload:[]
            state.total=payload?payload.total:state.total
            payload&&!payload?.tickets?localStorage.setItem('general-daily',"Migrated"):localStorage.setItem('general-daily',"Opened")
            state.status = 'fulfilled'
            state.logging = false
        },
        [getCurrentGeneralDaily.rejected]:  (state) => {
           
            

            state.status = 'rejected'
            state.logging = false
        },

        [editGeneralDaily.pending]: pendingReducer,
        [editGeneralDaily.fulfilled]: fulfilledReducer,
        [editGeneralDaily.rejected]: rejectedReducer,
 
    }
})

export const generalDailySelector = (state) => state.generalDaily;