import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Form } from '../../components';
import { CURRENCY_FORM, EDIT_PASSWORD_FORM } from './form';
import { useSelector } from 'react-redux';
import { exchangeSelector } from './redux/slice';
import { t } from 'i18next';

function EditPassword({setOpen, open,errors,setErrors,editPassword}) {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [_password, setPassword] = useState({
    old_password: "",
    new_password:'',
    new_password_confirmation:''

  });



    const handleClose = () => {
        setOpen(false);
      };
   
  

      const handleChange = (event) => {
        // //console.log(event);
        const { name, value } = event.target;
   
        setPassword({ ..._password, [name]: value });
          setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        
      };

      const SubmitEditPassword=()=>{
        editPassword(_password)
       handleClose()
      }

    

 
      
      
  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose}>
    <DialogTitle sx={{ fontSize: '3rem', fontWeight: 'bold', py: '1.5rem', textAlign: 'center' }}>{t('Edit password')}</DialogTitle>
    <DialogContent sx={{ width: '100%' }}>
    <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
        <Form  data={_password} errors={errors} handleChange={handleChange} form={EDIT_PASSWORD_FORM} />
        </Box>
    </DialogContent>
    <DialogActions sx={{ justifyContent: 'center', pb: '1.5rem' }}>
      <Button variant="contained" onClick={handleClose} sx={{ fontSize: '0.8rem', px: '1.2rem', py: '0.6rem',background:'red' }}>{t("Close")}</Button>
      <Button variant="contained" onClick={SubmitEditPassword} sx={{ fontSize: '0.8rem', px: '1.2rem', py: '0.6rem' }}>{t("Save")}</Button>

    </DialogActions>
  </Dialog>
  )
}

export default EditPassword