import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useMediaQuery} from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {Form, Header } from "../../components";
import { EDIT_EMPLOYEE_FORM } from "./form";
import { employeeSelector } from "./redux/slice";
import { getOneEmployeeMapper } from "./mapper";
import { useParams } from "react-router-dom";
import { getRolesData } from "../../services/functions";

const EditEmployee = ({editEmployee,getEmployee,errors,setErrors,getRoles}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {employee}=useSelector(employeeSelector)
  const {id}=useParams()
  const {t}=useTranslation()
  const { roles } = useSelector(employeeSelector)

  const [_employee, setEmployee] = useState({
    name:"",
    username:"",
    password:null,
    role:"",
    is_active:false,
  })

  const [selectData, setSelectData] = useState({
    role:[]
  })
   const [fieldsData, setFieldsData] = useState({
  })

  const handleFormSubmit = () => {
    editEmployee(id,_employee)
  };
  const handleChange=(event)=>{
      const {name,value}=event.target
      setEmployee({..._employee,[name]:value})
      setErrors((prevErrors)=>(
        {...prevErrors,
        [name]:''
        }
    ))
  }

  const handleChangeSwitch=(event)=>{
    const {name,checked}=event.target
    setEmployee({..._employee,[name]:checked})
    setErrors((prevErrors)=>(
      {...prevErrors,
      [name]:''
      }
  ))
}

const handleColor=(color)=>{
     setEmployee({...employee,color:color.hex})
        setErrors((prevErrors)=>(
      {...prevErrors,
      color:''
      }
  ))
}

const handleChangeField=(event,itemName,index)=>{
    const {name,value}=event.target
    //console.log(name,value,index);
    const fields=employee[itemName]
    fields[index][name]=value
    setEmployee({...employee,[itemName]:fields})
}
const addField=(name)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]+1
    }
))
    const fields=employee[name]
    fields.push({})
    setEmployee((prevEmployee)=>(
      {...prevEmployee,
      [name]:fields
      }
  ))

}

const deleteField=(name,index)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]-1
    }
))
    const fields=employee[name]
    fields.splice(index,1)
    setEmployee({...employee,[name]:fields})
}

  useEffect(() => {
   getEmployee(id)
  }, [])

  useEffect(() => {
    setEmployee(getOneEmployeeMapper(employee))
  }, [employee])

  useEffect(() => {
    setSelectData({
      role:getRolesData(roles)
    })
  }, [roles])
  
  useEffect(() => {
    getRoles({total:true,needPagination:true})
  }, [])  
    
  return (
    <Box m="20px">
      <Header title="Employee" subtitle="Edit a Employee" />
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
           
            <Form selectData={selectData} handleColor={handleColor} handleChangeSwitch={handleChangeSwitch} errors={errors} data={_employee}  handleChange={handleChange}  form={EDIT_EMPLOYEE_FORM}  />
          </Box>
         

          <Box display="flex" justifyContent="end" mt="20px">
            <Button onClick={()=>{handleFormSubmit()}} type="submit" color="secondary" variant="contained">
              {t("Edit Employee")}
            </Button>
          </Box>
     
  
    </Box>
  );
};

export default EditEmployee;
