import React, {  useEffect, useState } from "react";
import useHeaders from "../../hooks/useHeaders";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addBalance,
  addFund,deleteFund,editFund,getFund,getFunds
} from "./redux/service";
import { fundSelector,fundSlice} from "./redux/slice";
import { useSidebarContext } from "../../components/global/sidebar/sidebarContext";
import Funds from "./Funds";
import AddFund from "./AddFund";
import EditFund from "./EditFund";
import { LoadingDialog } from "../../components";
import Validation from "../../services/Validation";
import { addFundSchema, editFundSchema } from "./validationSchema";
import { ToastContainer } from "react-toastify";
import { deleteEmptyValues } from "../../services/functions";

function FundsController({ child }) {
  const { setSelected } = useSidebarContext();
  const { logging } = useSelector(fundSelector);
  const navigate = useNavigate();
  const headers = useHeaders();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({})
  const _getFunds = async (params) => {
    dispatch(getFunds({ headers,params }))
  };
  const _deleteFund = async (id) => {
    dispatch(deleteFund({ headers, id })).then((res) => {
      if (res.error) {
      } else {
        dispatch(fundSlice.actions.delete(id));
      }
    });
  };
  const _addFund = async (fund) => {
  
     const fundValidationErrors = Validation.validation(addFundSchema, fund);
     if (fundValidationErrors) {
      setErrors(fundValidationErrors);
    } else {
    const _fund={
      ...fund
     }
       dispatch(addFund({ headers,fund:_fund })).then((res) => {
         if (res.error) {
         } else {
           navigate("/funds");
         }
       });
      }
  
    
  };

  const _addBalance = async (balance) => {
  
  //   const fundValidationErrors = Validation.validation(addFundSchema, fund);
  //   if (fundValidationErrors) {
  //    setErrors(fundValidationErrors);
  //  } else {
   const _balance={
     ...balance
    }
    
      dispatch(addBalance({ headers,balance:deleteEmptyValues(_balance) })).then((res) => {
        if (res.error) {
        } else {
          window.location='/funds'
        }
      });
    //  }
 
   
 };



  const _getFund = async (id) => {
    dispatch(getFund({ headers, id }));
  };
  const _editFund = async (id, fund) => {
    const fundValidationErrors = Validation.validation(editFundSchema, fund);
    if (fundValidationErrors) {
     setErrors(fundValidationErrors);
   } else {
    const _fund={...fund}
      dispatch(editFund({ headers, fund: _fund,id})).then((res) => {
        if (res.error) {
        } else {
          navigate("/funds");
        }
      });
    }
    }


  useEffect(() => {
    setSelected&& setSelected("Funds");
  }, []);

  const initChild = () => {
    switch (child) {
      case "funds": {
        return <Funds  errors={errors} setErrors={setErrors}  submitBalance={_addBalance} title={"Funds"}  getFunds={_getFunds}/>;
      }

      case "bank": {
        return <Funds title={"Bank"}  getFunds={_getFunds} errors={errors} setErrors={setErrors}/>;
      } 
      case "add-fund":
        return (
          <AddFund
        addFund={_addFund}
        errors={errors}
        setErrors={setErrors}
          />
        );

      case "edit-fund":
        return (
          <EditFund
          editFund={_editFund}
          getFund={_getFund}
          errors={errors}
          setErrors={setErrors}
          />
        );

      default:
    }
  };
  return (
    <div>
      <ToastContainer/>

      {logging && <LoadingDialog open={logging} />}

      {initChild()}
    </div>
  );
}

export default FundsController;
