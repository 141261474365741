import Requests from "../../services/Requests"
import HandleError from "../../services/HandleError"
import { EDIT_PASSWORD, LOGIN, LOGOUT, RESET_PASSWORD, SEND_CODE_TO_EMAIL } from "../../constent/urls"

class Auth{
    static login = async (headers,body,fail) => {
        // body['remember_token']='dsfasdfsdfsdaf'
        let result=await Requests.post(LOGIN,headers,body)
         return HandleError.handleError(result,()=>{return result},fail)
    }

    static logout = async (headers,fail) => {
        let result=await Requests.delete(LOGOUT,headers)
         return HandleError.handleError(result,()=>{return result},fail)
    }
    

    static editPassword = async (headers,body,fail) => {
        
        let result=await Requests.patch(EDIT_PASSWORD,headers,body)
        return HandleError.handleError(result,()=>{return result},fail)
    }

    
    static resetPassword = async (headers,body,fail) => {
       
        let result=await Requests.post(RESET_PASSWORD,headers,body)
        return HandleError.handleError(result,()=>{return result},fail)
    }
    
}
export default Auth;