import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from "react-i18next";
import { GlobalBoxContainer } from "../../Styles/Styles";
import { Actions, DataGridTable, Header } from "../../components";
import { getGeneralDailyHeaderMapper, getGeneralDailyMapper } from "./mapper";
import { generalDailySelector } from "./redux/slice";
import MigrateDialog from "../../components/MigrateDialog";

const GeneralDailys = ({ getGeneralDailys, migrateGeneralDaily }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { generalDailys } = useSelector(generalDailySelector)
  const [open, setOpen] = useState(false)
  const [id, setId] = useState('')
  const downloadGeneralDaily=(url)=>{
    //console.log(url);
    window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  const migrateSubmit=(id)=>{
    setId(id)
    setOpen(true)
  }
  const closeMigrateDialog=()=>{
    setOpen(false)
  }
  const okMigrateDialog=()=>{
    migrateGeneralDaily(id)
  }
  useEffect(() => {
    getGeneralDailys()
  }, [])
  console.log(generalDailys);

  return (
    <Box m="20px">
      {open&&<MigrateDialog onClose={closeMigrateDialog} open={open} onOk={okMigrateDialog} />}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header viewAll={true} title="GeneralDailys" subtitle={"Welcome to your " + "generalDailys"} />
      </Box>
      {localStorage.getItem('general-daily')=="Migrated"&&<Button sx={{ bgcolor: '#0066cc' }} variant='contained' startIcon={<AddIcon />} onClick={() => { navigate('/general-daily/add') }}>{t('Add new general daily')}</Button>}

      <Button sx={{ bgcolor: '#0066cc',right:'2%' }} variant='contained'  onClick={() => { navigate('/general-daily/current-general-daily') }}>{t('View current general daily')}</Button>
      <GlobalBoxContainer>
        <DataGridTable rows={getGeneralDailyMapper(generalDailys)} columns={getGeneralDailyHeaderMapper(generalDailys).concat(Actions("", "edit/",'','','',migrateSubmit,downloadGeneralDaily)).reverse()} />
      </GlobalBoxContainer>
    </Box>
  );
};

export default GeneralDailys;