import React from 'react';
import TextField from '@mui/material/TextField';
import { t } from 'i18next';


const SearchField = ({ label, placeholder, value,setValue,getSearchResults }) => {
    
    const handleChange=(event)=>{
        setValue(event.target.value)
    }
   

    const handleKeyDown=(event)=>{
        if (event.key === 'Enter') {
        if (value.trim()!==''){
            getSearchResults()
        }
    }
}
//console.log(t(placeholder));
  return (
    <div style={{ gridColumn: "span "+2,width:'20%',float:'right' }}>
    <TextField
      variant='filled'
      InputProps={{   disableUnderline: true,style: {border:'0', background: "#F7F8F8"}}}
    //   onMouseDown={handleMouseLeave}
      onKeyDown={handleKeyDown}
      label={t(label)}
      placeholder={t(placeholder) }
      value={value}
      onChange={handleChange}
      sx={{ gridColumn: "span "+1,    }}
                style={{
                    
                    fontWeight:'bold',
                    

            }}
      fullWidth
    />
    </div>
  );
};

export default SearchField;
