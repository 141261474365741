import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from "react-i18next";
import { GlobalBoxContainer } from "../../Styles/Styles";
import { Actions, DataGridTable, Header } from "../../components";
import { getSupplierHeaderMapper, getSupplierMapper } from "./mapper";
import { supplierSelector } from "./redux/slice";
import { useState } from "react";

const Suppliers = ({ getSuppliers, deleteSupplier }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [paginationModel, setPaginationModel] = useState({
    page:0,
    pageSize:100
  })
  const { suppliers,total } = useSelector(supplierSelector)
  useEffect(() => {
    getSuppliers({account_type:'Supplier',page:paginationModel.page+1,limit:paginationModel.pageSize})
  }, [])

  useEffect(() => {
    getSuppliers({account_type:'Supplier',page:paginationModel.page+1,limit:paginationModel.pageSize})

  }, [paginationModel.page,paginationModel.pageSize])
//console.log(total);
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header viewAll={true} title="Suppliers" subtitle={"Welcome to your " + "suppliers"} />
      </Box>
      <Button sx={{ bgcolor: '#0066CC' }} variant='contained' startIcon={<AddIcon />} onClick={() => { navigate('/suppliers/add') }}>{t('Add')}</Button>
      <GlobalBoxContainer>
        <DataGridTable total={total} paginationModel={paginationModel} setPaginationModel={setPaginationModel} rows={getSupplierMapper(suppliers)} columns={getSupplierHeaderMapper(suppliers).concat(Actions(deleteSupplier, "edit/","account-statement/")).reverse()} />
      </GlobalBoxContainer>
    </Box>
  );
};

export default Suppliers;