import Joi from "joi-browser";

export const addGeneralDailySchema = {
    name: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a customer name ",
      };
    }),
    year: Joi.number()
    .required()
    .error(() => {
      return {
        message: "Please enter a year ",
      };
    }),
    start_date: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please choose start date ",
      };
    }),

   
};

export const editGeneralDailySchema = {
    name: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a customer name ",
      };
    }),
    year: Joi.number()
    .required()
    .error(() => {
      return {
        message: "Please enter a year ",
      };
    }),
    start_date: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please choose start date ",
      };
    }),

    end_date: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please choose start date ",
      };
    }),
};
