import { DELETE_SUPPLIER, EDIT_SUPPLIER, SUPPLIER, SUPPLIERS, STORE_SUPPLIER,SUPPLIER_ACCOUNT_STATEMENT} from "../../constent/urls"
import Requests from "../../services/Requests"
import HandleError from "../../services/HandleError"

class Supplier{
    static getSuppliers = async (headers,params,fail) => {
        let result=await Requests.get(SUPPLIERS,headers,params );
        return HandleError.handleError(result,()=>{return result.data},fail)
    }

    static getSupplier = async (headers,id,fail) => {
        let result=await Requests.get(SUPPLIER+id,headers);
        
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static addSupplier = async (headers,body,fail) => {
        let result=await Requests.post(STORE_SUPPLIER,headers,body)
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static editSupplier = async (headers,body,id,fail) => {
        let result=await Requests.patch(EDIT_SUPPLIER+id,headers,body)
        //console.log(result);
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static deleteSupplier = async (headers,id,fail) => {
        let result=await Requests.delete(DELETE_SUPPLIER+id,headers)
        return HandleError.handleError(result,()=>{return result},fail)
    }

    static getAccountStatement = async (headers,id,params,fail) => {
        let result=await Requests.get(SUPPLIER_ACCOUNT_STATEMENT+id,headers,params);
        
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

}
export default Supplier;