import { createAsyncThunk } from "@reduxjs/toolkit";
import Constant from "../ConstantApi";

export const getConstants= createAsyncThunk(
  "constantSlice/getConstants",
  async ({ headers,params }, { rejectWithValue }) => {
    return await Constant.getConstants(headers,params, () => {
      return rejectWithValue();
    });
  }
);
export const deleteConstant = createAsyncThunk(
  "constantSlice/deleteConstant",
  async ({ headers, id }, { rejectWithValue }) => {
    return await Constant.deleteConstant(headers, id, () => {
      throw new Error("Failed delete constant");
    });
  }
);

export const addConstant = createAsyncThunk('constantSlice/addConstant', async ({ headers,constant }, { rejectWithValue }) => {
  return await Constant.addConstant(headers, constant,()=>{
    throw new Error("Failed store constant")});
})

export const getConstant = createAsyncThunk(
  "constantSlice/getConstant",
  async ({ headers,id }, { rejectWithValue }) => {
    return await Constant.getConstant(headers,id, () => {
      return rejectWithValue();
    });
  }
);

export const editConstant = createAsyncThunk('constantSlice/editConstant', async ({ headers,constant,id }, { rejectWithValue }) => {
  return await Constant.editConstant(headers, constant,id,()=>{
    throw new Error("Failed edit constant")});
})