import React, {  useEffect, useState } from "react";
import useHeaders from "../../hooks/useHeaders";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addGeneralDaily,deleteGeneralDaily,editGeneralDaily,getCurrentGeneralDaily,getGeneralDaily,getGeneralDailys, migrateGeneralDaily
} from "./redux/service";
import { generalDailySelector,generalDailySlice} from "./redux/slice";
import { useSidebarContext } from "../../components/global/sidebar/sidebarContext";
import GeneralDailys from "./GeneralDailys";
import AddGeneralDaily from "./AddGeneralDaily";
import EditGeneralDaily from "./EditGeneralDaily";
import { LoadingDialog } from "../../components";
import Validation from "../../services/Validation";
import { addGeneralDailySchema, editGeneralDailySchema } from "./validationSchema";
import CurrentGeneralDaily from "./CurrentGeneralDaily";
import { ToastContainer } from "react-toastify";

function GeneralDailysController({ child }) {
  const { setSelected } = useSidebarContext();
  const { logging } = useSelector(generalDailySelector);
  const navigate = useNavigate();
  const headers = useHeaders();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({})
  const _getGeneralDailys = async (params) => {
    dispatch(getGeneralDailys({ headers,params }))
  };

  const _getCurrentGeneralDaily = async (params) => {
    dispatch(getCurrentGeneralDaily({ headers,params }))
  };
  const _deleteGeneralDaily = async (id) => {
    dispatch(deleteGeneralDaily({ headers, id })).then((res) => {
      if (res.error) {
      } else {
        dispatch(generalDailySlice.actions.delete(id));
      }
    });
  };
  const _addGeneralDaily = async (generalDaily) => {
  
     const generalDailyValidationErrors = Validation.validation(addGeneralDailySchema, generalDaily);
     if (generalDailyValidationErrors) {
      setErrors(generalDailyValidationErrors);
    } else {
    const _generalDaily={
      ...generalDaily
     }
       dispatch(addGeneralDaily({ headers,generalDaily:_generalDaily })).then((res) => {
         if (res.error) {
         } else {
           navigate("/general-daily");
         }
       });
      }
  
    
  };

  const _migrateGeneralDaily = async () => {
  
   
      dispatch(migrateGeneralDaily({ headers})).then((res) => {
        if (res.error) {
        } else {
          window.location="/general-daily"
        }
      });
     
 
   
 };



  const _getGeneralDaily = async (id) => {
    dispatch(getGeneralDaily({ headers, id }));
  };
  const _editGeneralDaily = async (id, generalDaily) => {
    const generalDailyValidationErrors = Validation.validation(editGeneralDailySchema, generalDaily);
    if (generalDailyValidationErrors) {
     setErrors(generalDailyValidationErrors);
   } else {
    const _generalDaily={...generalDaily}
      dispatch(editGeneralDaily({ headers, generalDaily: _generalDaily,id})).then((res) => {
        if (res.error) {
        } else {
          navigate("/general-daily");
        }
      });
    }
    }


  useEffect(() => {
    setSelected&& setSelected("GeneralDailys");
  }, []);

  const initChild = () => {
    switch (child) {

      case "current-general-daily": {
        return <CurrentGeneralDaily setErrors={setErrors} errors={errors} deleteGeneralDaily={_deleteGeneralDaily} getCurrentGeneralDaily={_getCurrentGeneralDaily}/>;
      }
      case "generalDailys": {
        return <GeneralDailys migrateGeneralDaily={_migrateGeneralDaily} deleteGeneralDaily={_deleteGeneralDaily} getGeneralDailys={_getGeneralDailys}/>;
      }
      case "add-generalDaily":
        return (
          <AddGeneralDaily
        addGeneralDaily={_addGeneralDaily}
        errors={errors}
        setErrors={setErrors}
          />
        );

      case "edit-generalDaily":
        return (
          <EditGeneralDaily
          editGeneralDaily={_editGeneralDaily}
          getGeneralDaily={_getGeneralDaily}
          errors={errors}
          setErrors={setErrors}
          />
        );

      default:
    }
  };
  return (
    <div>
      <ToastContainer/>

      {logging && <LoadingDialog open={logging} />}

      {initChild()}
    </div>
  );
}

export default GeneralDailysController;
