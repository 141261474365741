import { createSlice } from "@reduxjs/toolkit"
import { addSupplier, deleteSupplier, editSupplier, getSuppliers, getSupplier, getAccountStatment } from "./service"
import { fulfilledReducer, pendingReducer, rejectedReducer } from "../../../redux/globalReducer"
const initialState = {
    status: '',
    logging: false,
    total:0,
    suppliers:[],
    supplier:{},
    transaction_total:0,
    tickets_total:0,
    accountStatement:{
        transactions:[],
        receivable_details:[],
        tickets:[]
    }
}
export const supplierSlice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {
        delete(state,action){
            return {
                ...state,suppliers:[...state.suppliers].filter((supplier)=>supplier.id!=action.payload)
            }
       
        }
    },
    extraReducers: {

        [getAccountStatment.pending]: pendingReducer,
        [getAccountStatment.fulfilled]: (state, { payload }) => {
            state.accountStatement= payload?payload:{}
            state.transaction_total=payload?payload.transaction_total:state.transaction_total
            state.tickets_total=payload?payload.tickets_total:state.tickets_total
            state.status = 'fulfilled'
            state.logging = false
        },
        [getAccountStatment.rejected]: rejectedReducer,
        [getSuppliers.pending]: pendingReducer,
        [getSuppliers.fulfilled]: (state, { payload }) => {
            //console.log(payload);
            state.suppliers= payload?payload.data:[]
            state.total=payload.total?payload.total:state.total
            state.status = 'fulfilled'
            state.logging = false
        },
        [getSuppliers.rejected]: rejectedReducer,

        [deleteSupplier.pending]: pendingReducer,
        [deleteSupplier.fulfilled]: fulfilledReducer,
        [deleteSupplier.rejected]: rejectedReducer,

        
        [addSupplier.pending]: pendingReducer,
        [addSupplier.fulfilled]: fulfilledReducer,
        [addSupplier.rejected]: rejectedReducer,

        [getSupplier.pending]: pendingReducer,
        [getSupplier.fulfilled]: (state, { payload }) => {
           
            state.supplier= payload?payload:{}
            state.status = 'fulfilled'
            state.logging = false
        },
        [getSupplier.rejected]: pendingReducer,

        [editSupplier.pending]: pendingReducer,
        [editSupplier.fulfilled]: fulfilledReducer,
        [editSupplier.rejected]: rejectedReducer,
 
    }
})

export const supplierSelector = (state) => state.supplier;