import React from "react";
import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";

const Header = ({ title, subtitle,id,viewAll}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const type=window.location.pathname.includes('edit')||window.location.pathname.includes('details')?'':'New'
  return (
    <div style={{width:'100%',marginBottom:'30px'}}>

      <Typography
        variant="h1"
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ mb: "5px",textAlign:'center',fontWeight:'900',fontSize:'64px',fontFamily:'Roboto,Helvetica,Arial,sans-serif' }}
      >
       {viewAll? <div><span>{t("Section")}</span><span style={{color:'#4FA1CF'}}>{t(title)}</span> </div>:<div> <span> </span><span style={{color:'#4FA1CF'}}>{t(title)}</span> </div>}
      </Typography>
      </div>
     /** {/* <Typography sx={{fontWeight:600}} variant="h5" color={colors.greenAccent[400]}>
        {t(subtitle)}{id?id:''}
      </Typography> */ 
 
  );
};

export default Header;
