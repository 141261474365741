import i18n from "../../localization"

export const getRecivableHeaderMapper=(data)=>{
    const RecivableHeaderMapper=[]
    const RecivableDataMappar=getRecivableMapper(data)
    const keys=RecivableDataMappar.length>0? Object.keys(RecivableDataMappar[0]):[]
    keys.map((key)=>{
        let words = key.split("_");
        let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        let result = capitalizedWords.join(" ");
        RecivableHeaderMapper.push({
            field:key,
            headerName:i18n.t(result),
                  width: (key=='name'||key=="account_name")?200:100,
        })
    })
    return RecivableHeaderMapper
}

export const getRecivableMapper=(data)=>{
    const RecivableMapperData=[]
    //console.log(data);
    data?.map((item)=>{
        RecivableMapperData.push({
            ...item
        })
    })

    return RecivableMapperData
}



export const getOneRecivableMapper=(data)=>{
   
    const oneRecivableMapperData={
           
        }
  
    return oneRecivableMapperData
}

export const getRecivableDetails = (RecivableDetails) => {
    return {

      
    //   rest_amount_receivable_dollar:RecivableDetails.rest_amount_receivable_dollar,
    //   rest_amount_receivable_on_Recivable_dollar:RecivableDetails.rest_amount_receivable_on_Recivable_dollar,
    //   rest_amount_receivable_sy:RecivableDetails.rest_amount_receivable_sy,
    //   rest_amount_receivable_on_Recivable_sy:RecivableDetails.rest_amount_receivable_on_Recivable_sy,
    
      rest_amount_receivable_on_fund_sy:RecivableDetails.rest_amount_receivable_on_fund_sy,
      rest_amount_receivable_on_fund_dollar:RecivableDetails.rest_amount_receivable_on_fund_dollar,
      rest_amount_receivable_sy:RecivableDetails.rest_amount_receivable_sy,
      rest_amount_receivable_dollar:RecivableDetails.rest_amount_receivable_dollar,

  
  
    }
  };
  