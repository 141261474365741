import { DELETE_Recivable, EDIT_Recivable, RECIVABLE, RecivableS, STORE_Recivable } from "../../constent/urls"
import Requests from "../../services/Requests"
import HandleError from "../../services/HandleError"

class Recivable{
    static getRecivables = async (headers,params,fail) => {
        let result=await Requests.get(RecivableS,headers,params );
        //console.log(result);
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static getRecivable = async (headers,id,fail) => {
        let result=await Requests.get(Recivable+id,headers);
        
        return HandleError.handleError(result,()=>{return result.data.data},fail)
    }

    static addRecivable = async (headers,body,fail) => {
        let result=await Requests.post(STORE_Recivable,headers,body)
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static editRecivable = async (headers,body,id,fail) => {
        let result=await Requests.patch(EDIT_Recivable+id,headers,body)
        //console.log(result);
        return HandleError.handleError(result,()=>{return result},fail)
    }
    static deleteRecivable = async (headers,id,fail) => {
        let result=await Requests.delete(DELETE_Recivable+id,headers)
        return HandleError.handleError(result,()=>{return result},fail)
    }

}
export default Recivable;