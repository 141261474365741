import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {ar,en} from './resources'
const resources={
ar,en
}




i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lang: 'ar',
        fallbackLng: 'ar',
        debug: false,


    })
export default i18n