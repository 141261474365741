import i18n from "../../localization"

export const getConstantHeaderMapper=(data)=>{
    const constantHeaderMapper=[]
    const constantDataMappar=getConstantMapper(data)
    const keys=constantDataMappar.length>0? Object.keys(constantDataMappar[0]):[]
    keys.map((key)=>{
        let words = key.split("_");
        let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        let result = capitalizedWords.join(" ");
        constantHeaderMapper.push({
            field:key,
            headerName:i18n.t(result),
            width: 100,
        })
    })
    return constantHeaderMapper
}

export const getConstantMapper=(data)=>{
    const constantMapperData=[]
    data.map((item)=>{
        constantMapperData.push({
            id:item?.id,
            name:item?.name,
            value:item?.value,  
        })
    })

    return constantMapperData
}

export const getOneConstantMapper=(data)=>{
   
    const oneConstantMapperData={
        name:data?.name,
        value:data?.value, 
        }
  
    return oneConstantMapperData
}