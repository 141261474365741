import { useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import { logout } from "../redux/auth/service";
import useHeaders from "../hooks/useHeaders";
// import { authSelector } from "../redux/auth/slice";
import { CircularProgress } from "@mui/material";
import { authSelector } from "../features/auth/redux/slice";
import { logout } from "../features/auth/redux/service";

export default function ProfileList({onClick}) {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch=useDispatch()
    const {logging}=useSelector(authSelector)
    const [cookies,setCookies, removeCookie] = useCookies([
        "token",
        "user",
        
      ]);
    const open = Boolean(anchorEl);
    const {i18n,t}=useTranslation()
    const headers=useHeaders()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logoutSubmit=()=>{
        dispatch(logout({headers})).then((res)=>{
            if(!res.error){
                document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";       
                window.location='/auth/login' 
            }
        })
    }



    return (
        <div>
          
                <PersonOutlinedIcon  onClick={handleClick} />
           
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem  onClick={onClick}> <span style={{color:i18n.language!='ar'?'#70d8bd':''}} >{t('Edit password')}</span></MenuItem>
                <MenuItem onClick={()=>{logoutSubmit()}}><span style={{color:i18n.language!='en'?'#70d8bd':''}} >{logging?      <CircularProgress />:t('Logout')}</span></MenuItem>
            </Menu>
        </div>
    )
}
