export const pendingReducer = (state) => {
    //console.log("diab");
    state.logging = true;
    state.status = "pending";
  };

  export const rejectedReducer = (state) => {
    state.logging = false;
    state.status = "rejected";
  };

  export const fulfilledReducer = (state) => {
    //console.log("diab");
    state.logging = false;
    state.status = "rejected";
  };



  