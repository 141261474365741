import { Box, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'


export default function TextInput({ label,name, type, handleChange, defaultValue ,InputProps,grid,value,multiline,rows}) {
    let { i18n,t } = useTranslation()
    console.log(value);
    return (
        <Box
            sx={{
                // padding: { lg: '15px 20px', md: '15px 10px', sm: '15px 0px', xs: '15px 0px' },
                direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
                // width:'100%',
                // maxWidth:'100%'
            }}
        >
            <TextField
    variant='filled'
    InputProps={{ disableUnderline: true, style: { border: '0', background: "#F7F8F8" } }}
    fullWidth
    label={t(label)}
    format=""
    name={name}
    value={value}
    defaultValue={defaultValue !== null && defaultValue !== undefined ? defaultValue : ''}
    type={type}
    onChange={handleChange}
    className='inputs'
    multiline={multiline}
    rows={rows}
    sx={{ gridColumn: "span " + grid, direction: 'rtl' }}
    style={{
        fontWeight: 'bold',
        fontSize: '18px'
    }}
    InputLabelProps={{
        sx: {
            color: "#c7c8c8",
            fontWeight: 600,
            fontSize: '16px',
            "&.MuiInputLabel-root.Mui-focused": {
                color: "#c7c8c8",
            },
            "&.MuiInputLabel-root": {
               left:'inherit !important',
               right:'1.75rem !important',
               transformOrigin:'right !important'
                // left:'calc(100% - 20%);'
            }
        },
    }}
/>


        </Box>
    )
}