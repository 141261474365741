import  Joi  from "joi-browser";

export const LoginSchema={
  username:Joi.string().required().error(() => {
  return {
    message: 'Please enter a username',
  }}),
  password:Joi.string().required().min(8).error(() => {
  return {
    message: 'Please enter a password at least 8 characters',
  }}),
}