import { createContext, useState, useMemo } from "react";

import { createTheme } from "@mui/material/styles";
import i18n from "./localization";

export const tokens = (mode) => ({
    ...(mode === "dark"
      ? {
          grey: {
            100: "#e0e0e0",
            200: "#c2c2c2",
            300: "#a3a3a3",
            400: "#858585",
            500: "#666666",
            600: "#525252",
            700: "#3d3d3d",
            800: "#292929",
            900: "#141414",
          },
          primary: {
            100: "#0066CC",
            200: "#a1a4ab",
            300: "#727681",
            400: "#1A1A1A", 
            500: "#1A1A1A",
            600: "#101624",
            700: "#0c101b",
            800: "#080b12",
            900: "#040509",
          },
          greenAccent: {
            100: "#dbf5ee",
            200: "#b7ebde",
            300: "#94e2cd",
            400: "#70d8bd",
            500: "#4FA1CF",
            600: "#3da58a",
            700: "#2e7c67",
            800: "#1e5245",
            900: "#0f2922",
          },
          redAccent: {
            100: "#f8dcdb",
            200: "#f1b9b7",
            300: "#e99592",
            400: "#e2726e",
            500: "#db4f4a",
            600: "#af3f3b",
            700: "#832f2c",
            800: "#58201e",
            900: "#2c100f",
          },
          blueAccent: {
            100: "#e1e2fe",
            200: "#c3c6fd",
            300: "#a4a9fc",
            400: "#868dfb",
            500: "#6870fa",
            600: "#535ac8",
            700: "#ff0000",
            800: "#2a2d64",
            900: "#151632",
          },
        }
      : {
          grey: {
            100: "#141414",
            200: "#292929",
            300: "#3d3d3d",
            400: "#525252",
            500: "#666666",
            600: "#858585",
            700: "#a3a3a3",
            800: "#c2c2c2",
            900: "#e0e0e0",
          },
          primary: {
            100: "#040509",
            200: "#080b12",
            300: "#0c101b",
            400: "#E0E0E0", // manually changed
            500: "#FCFCFC",
            600: "red",
            700: "#727681",
            800: "#a1a4ab",
            900: "#d0d1d5",
          },
          greenAccent: {
            100: "#90BE6D",
            200: "#1e5245",
            300: "#2e7c67",
            400: "#3da58a",
            500: "#4FA1CF",
            600: "#70d8bd",
            700: "#94e2cd",
            800: "#b7ebde",
            900: "#dbf5ee",
          },
          redAccent: {
            100: "#2c100f",
            200: "#58201e",
            300: "#832f2c",
            400: "#af3f3b",
            500: "#db4f4a",
            600: "#e2726e",
            700: "#e99592",
            800: "#f1b9b7",
            900: "#f8dcdb",
          },
          blueAccent: {
            100: "#151632",
            200: "#2a2d64",
            300: "#ff0000",
            400: "#535ac8",
            500: "#6870fa",
            600: "#868dfb",
            700: "#a4a9fc",
            800: "#c3c6fd",
            900: "#e1e2fe",
          },
        }),
  });

// mui theme settings
export const themeSettings = (mode, ln) => {
  const cookies = document.cookie.split(';');
  const cookieObj = {};
  
  cookies.forEach((cookie) => {
    const [name, value] = cookie.split('=');
    cookieObj[name] = value;
  });
  

  const colors = tokens(mode);
  
  return {
    palette: {
    
      type: mode,
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.primary[100],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500]
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
                default: colors.primary[500]
            },
          }),
    },
    typography: {
      // fontFamily: ["Poppins", "sans-serif"].join(","),
      fontSize: 12,
        
      h1: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: "700",
            fontSize: "12px",
            background: "#0066cc",
            color: "#fff",
            "&:hover": {
              background: "#4FA1dF",
            },
          },
          contained: {
            background: "#0066cc",
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            direction:i18n.language=='ar'?'rtl':'ltr',
            fontWeight:'bold',
            border:cookieObj.token?'1px solid':'',

             borderRadius:'15px',
            backgroundColor: mode=='dark'?colors.grey[100]:'#e0e0e0',
            color:'#000',
            "&.Mui-focused": {
              color:'#000',
              
            },   
            "&:hover": {
                backgroundColor: mode=='dark'?"#808080":'#f0f0f0'
              },
            
          },

         
        }
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
  setDarkMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");
  const [ln, setLn] = useState("ar");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prevMode) => (prevMode === "dark" ? "light" : "dark")),
    }),
    []
  );

  const darkMode = useMemo(
    () => ({
      setDarkMode: () => setMode("dark"),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode, ln)), [mode, ln]);

  return [theme, colorMode, darkMode,mode];
};
