import { createSlice } from "@reduxjs/toolkit"
import { addConstant, deleteConstant, editConstant, getConstants, getConstant } from "./service"
import { fulfilledReducer, pendingReducer, rejectedReducer } from "../../../redux/globalReducer"
const initialState = {
    status: '',
    logging: false,
    constants:[],
    constant:{},
}
export const constantSlice = createSlice({
    name: 'constant',
    initialState,
    reducers: {
        delete(state,action){
            return {
                ...state,constants:[...state.constants].filter((constant)=>constant.id!=action.payload)
            }
       
        }
    },
    extraReducers: {
        [getConstants.pending]: pendingReducer,
        [getConstants.fulfilled]: (state, { payload }) => {
            state.constants= payload?payload:[]
            state.status = 'fulfilled'
            state.logging = false
        },
        [getConstants.rejected]: rejectedReducer,

        [deleteConstant.pending]: pendingReducer,
        [deleteConstant.fulfilled]: fulfilledReducer,
        [deleteConstant.rejected]: rejectedReducer,

        
        [addConstant.pending]: pendingReducer,
        [addConstant.fulfilled]: fulfilledReducer,
        [addConstant.rejected]: rejectedReducer,

        [getConstant.pending]: pendingReducer,
        [getConstant.fulfilled]: (state, { payload }) => {
           
            state.constant= payload?payload:{}
            state.status = 'fulfilled'
            state.logging = false
        },
        [getConstant.rejected]: pendingReducer,

        [editConstant.pending]: pendingReducer,
        [editConstant.fulfilled]: fulfilledReducer,
        [editConstant.rejected]: rejectedReducer,
 
    }
})

export const constantSelector = (state) => state.constant;