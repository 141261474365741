import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Header } from "../../components";
import { ADD_RETURNED_TICKET_FORM, ADD_TICKET_FORM } from "./form";
import { useDispatch, useSelector } from "react-redux";
import { companySelector } from "../company/redux/slice";
import { customerSelector } from "../customer/redux/slice";
import { supplierSelector } from "../supplier/redux/slice";
import {
  getCompanyData,
  getCustomerData,
  getSupplierData,
  getTicketsNumbersData,
} from "../../services/functions";
import { ticketSelector, ticketSlice } from "./redux/slice";
import CustomerReceivableDetailsDialog from "../../components/CustomerReceivableDetailsDialog";

const AddReturnedTicket = ({
  addReturnedTicket,
  errors,
  setErrors,
  getCustomers,
  getSuppliers,
  getTickets
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { customers } = useSelector(customerSelector);
  const { tickets } = useSelector(ticketSelector);


  const { suppliers } = useSelector(supplierSelector);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [ticket, setTicket] = useState({
    ticket_number: "",
    description: "",
    customer_id: "",
    supplier_id: "",
    returned_amount_supplier_sy: "",
    returned_amount_supplier_dollar: "",
    returned_amount_customer_sy: "",
    returned_amount_customer_dollar: "",
  });

  const [selectData, setSelectData] = useState({
    customer_id: [],
    ticket_number:[],
    supplier_id: [],
  });

  const handleFormSubmit = () => {
    addReturnedTicket(ticket, suppliers);
  };

  const handleChange = (event) => {
    // //console.log(event);
    const { name, value } = event.target;

    setTicket({ ...ticket, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleChangeSwitch = (event) => {
    const { name, checked } = event.target;
    setTicket({ ...ticket, [name]: checked });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleColor = (color) => {
    setTicket({ ...ticket, color: color.hex });
    setErrors((prevErrors) => ({ ...prevErrors, color: "" }));
  };

  useEffect(() => {
    //console.log(customers);
    setSelectData({
      customer_id: getCustomerData(customers),
      ticket_number: getTicketsNumbersData(tickets),

      supplier_id: getSupplierData(suppliers),
    });
  }, [ customers, suppliers]);

  useEffect(() => {
    Promise.all([getCustomers({}), getSuppliers(),getTickets()]);
  }, []);

  return (
    <Box m="20px">
   
      <Header title="Ticket" subtitle="a New Ticket" />
      
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        width="100% "
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Form
          setValue={setTicket}
          selectData={selectData}
          handleColor={handleColor}
          handleChangeSwitch={handleChangeSwitch}
          errors={errors}
          data={ticket}
          handleChange={handleChange}
          type="add"
          form={ADD_RETURNED_TICKET_FORM}
        />
      </Box>

      <Box display="flex" justifyContent="end" mt="20px">
        <Button
          onClick={() => {
            handleFormSubmit();
          }}
          type="submit"
          color="secondary"
          variant="contained"
        >
          {t("New Returned Ticket")}
        </Button>
      </Box>
    </Box>
  );
};

export default AddReturnedTicket;
