import React from 'react';
import { Typography } from '@mui/material';

const GeneralLabel = ({ children, variant, color, ...otherProps }) => {
  return (
    <Typography
    style={{fontWeight:'bold',marginLeft:'.5%',paddingBottom:'8px'}}
      variant={variant || 'h6'}
      color={color || 'text.primary'}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};

export default GeneralLabel;