import React, {  useEffect, useState } from "react";
import useHeaders from "../../hooks/useHeaders";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addSupplier,deleteSupplier,editSupplier,getAccountStatment,getSupplier,getSuppliers
} from "./redux/service";
import { supplierSelector,supplierSlice} from "./redux/slice";
import { useSidebarContext } from "../../components/global/sidebar/sidebarContext";
import Suppliers from "./Suppliers";
import AddSupplier from "./AddSupplier";
import EditSupplier from "./EditSupplier";
import { LoadingDialog } from "../../components";
import Validation from "../../services/Validation";
import { addSupplierSchema, editSupplierSchema } from "./validationSchema";
import AccountStatement from "./AccountStatement";
import { ToastContainer } from "react-toastify";
import {  getConstants } from "../constant/redux/service";
import { constantSelector } from "../constant/redux/slice";

function SuppliersController({ child }) {
  const { setSelected } = useSidebarContext();
  const { logging } = useSelector(supplierSelector);
  const {constants}=useSelector(constantSelector)
  const navigate = useNavigate();
  const headers = useHeaders();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({})
  const _getSuppliers = async (params) => {
    dispatch(getSuppliers({ headers,params }))
  };

  const _getConstants=(params)=>{
    dispatch(getConstants({headers,params}))
  }

  const _getAccountStatment = async (id,params) => {
    dispatch(getAccountStatment({ headers,id,params }))
  };
  const _deleteSupplier = async (id) => {
    dispatch(deleteSupplier({ headers, id })).then((res) => {
      if (res.error) {
      } else {
        dispatch(supplierSlice.actions.delete(id));
      }
    });
  };
  const _addSupplier = async (supplier) => {
  
     const supplierValidationErrors = Validation.validation(addSupplierSchema, supplier);
     if (supplierValidationErrors) {
      setErrors(supplierValidationErrors);
    } else {
    const _supplier={
      ...supplier,
      currency_id:constants.filter((constant)=>(constant.name==supplier.currency_id))[0].id
     }
       dispatch(addSupplier({ headers,supplier:_supplier })).then((res) => {
         if (res.error) {
         } else {
           navigate("/suppliers");
         }
       });
      }
  
    
  };



  const _getSupplier = async (id) => {
    dispatch(getSupplier({ headers, id }));
  };
  const _editSupplier = async (id, supplier) => {
    const supplierValidationErrors = Validation.validation(editSupplierSchema, supplier);
    if (supplierValidationErrors) {
     setErrors(supplierValidationErrors);
   } else {
    const _supplier={...supplier,
      currency_id:constants.filter((constant)=>(constant.name==supplier.currency_id))[0].id
    
    }
      dispatch(editSupplier({ headers, supplier: _supplier,id})).then((res) => {
        if (res.error) {
        } else {
          navigate("/suppliers");
        }
      });
    }
    }


  useEffect(() => {
    setSelected&& setSelected("Suppliers");
  }, []);

  useEffect(() => {
   _getConstants()
  }, [])
  

  const initChild = () => {
    switch (child) {

      case "account-statement":{
        return <AccountStatement  errors={errors} setErrors={setErrors} getAccountStatment={_getAccountStatment}/>
      }
      case "suppliers": {
        return <Suppliers deleteSupplier={_deleteSupplier} getSuppliers={_getSuppliers}/>;
      }
      case "add-supplier":
        return (
          <AddSupplier
        getConstants={_getConstants}
        addSupplier={_addSupplier}
        errors={errors}
        setErrors={setErrors}
          />
        );

      case "edit-supplier":
        return (
          <EditSupplier
          getConstants={_getConstants}

          editSupplier={_editSupplier}
          getSupplier={_getSupplier}
          errors={errors}
          setErrors={setErrors}
          />
        );

      default:
    }
  };
  return (
    <div>
      <ToastContainer/>

      {logging && <LoadingDialog open={logging} />}

      {initChild()}
    </div>
  );
}

export default SuppliersController;
