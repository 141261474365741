import React, { useState } from 'react'
import ReactDOM from 'react-dom'

// Import React FilePond
import { FilePond, File, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { useTranslation } from 'react-i18next'
import { ParagraphErrorStyle } from '../Styles/Styles'

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

// Our app
export default function File_Pond({files,setFiles,maxFiles,multiple,handleError,errors,name}) {
  //console.log(errors);
  const { t } = useTranslation()
  return (
    <div className="App">
      <FilePond
        onaddfile={()=>{handleError(name)}}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={multiple}
        maxFiles={maxFiles}
        // server="/api"
        name="files" 
        credits={""}
        labelIdle={t('Drag & Drop your files or')+ `<span class="filepond--label-action">${t('Browse')}</span>`}
      />
       {errors?.[name]&&<ParagraphErrorStyle>{t(errors[name])}</ParagraphErrorStyle>}
    </div>
  )
}