import React from 'react';
import logo from '../assets/img/logo.png';
import { CircularProgress } from '@mui/material';
// import x from '@mui/material/'
function LoadingDialog({ open }) {
  //console.log(open);

  if (!open) {
    return null;
  }

  return (
    <div className="loading-container">
      {/* <img src={logo} alt="Logo" className="loading-logo" /> */}
      <CircularProgress sx={{color:'#4FA1CF'}} />
    </div>
  );
}

export default LoadingDialog;