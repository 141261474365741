import { EDIT_EXCHAGNE, EXCHAGNE } from "../../constent/urls";
import HandleError from "../../services/HandleError";
import Requests from "../../services/Requests";

class Exchange{
static editExchange = async (headers,body,fail) => {
    let result=await Requests.patch(EDIT_EXCHAGNE,headers,body)
    return HandleError.handleError(result,()=>{return result},fail)
}

static getExchange = async (headers,params,fail) => {
    let result=await Requests.get(EXCHAGNE,headers,params );
    return HandleError.handleError(result,()=>{return result.data.data},fail)
}

}

export default Exchange;