import { createSlice } from "@reduxjs/toolkit"
import { editExchange,getExchange } from "./service"
import { fulfilledReducer, pendingReducer, rejectedReducer } from "../../../redux/globalReducer"
const initialState = {
    status: '',
    logging: false,
    exchange:{},
}
export const exchagneSlice = createSlice({
    name: 'exchange',
    initialState,
    reducers: {
      
    },
    extraReducers: {


       

        
  

        [getExchange.pending]: pendingReducer,
        [getExchange.fulfilled]: (state, { payload }) => {
           
            state.exchange= payload?payload:{}
            state.status = 'fulfilled'
            state.logging = false
        },
        [getExchange.rejected]: pendingReducer,

        [editExchange.pending]: pendingReducer,
        [editExchange.fulfilled]: fulfilledReducer,
        [editExchange.rejected]: rejectedReducer,
 
    }
})

export const exchangeSelector = (state) => state.exchange;