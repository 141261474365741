import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MyProSidebarProvider, useSidebarContext } from '../../components/global/sidebar/sidebarContext'
import Topbar from '../../components/global/Topbar'

function Index({ children }) {
    const {i18n}=useTranslation()
    const [ln, setln] = useState(i18n.language)

    useEffect(() => {
      //console.log('diab');
      //console.log(i18n.language);
        setln(i18n.language)
    }, [i18n.language])

    const {sidebarWidth} = useSidebarContext();
    //console.log(sidebarWidth+'px');
  return (
   
    
      
        <div  style={{ height: "100%", width: `calc(100% - ${sidebarWidth+'px'})`,direction:ln=='ar'?'rtl':'ltr' }}>
          <main>
            <Topbar />
                {children}
          
            </main>
          </div>
       


  )
}

export default Index