export const ADD_CONSTANT_FORM =
  [
    {
      feild:'textField',
      type: "text",
      label: "Name",
      name: "name",
      grid: "2",
    },

    {
      feild:'textField',
      type: "text",
      label: "Value",
      name: "value",
      grid: "2",
    },

  ];

  export const EDIT_CONSTANT_FORM =
  [
    {
      feild:'textField',
      type: "text",
      label: "Name",
      name: "name",
      grid: "2",
    },

    {
      feild:'textField',
      type: "text",
      label: "Value",
      name: "value",
      grid: "2",
    },
  ];