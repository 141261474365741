import React from 'react'
import { useTranslation } from 'react-i18next';
import GeneralLabel from './GeneralLabel';
import { Button, TextField } from '@mui/material';
import GeneralSelect from './GeneralSelect';

function VarientFields({item,count,data,handleChangeField,deleteField,addField,selectData}) {
    const {t}=useTranslation()
    const fields = [];
      fields.push(<GeneralLabel>{item.label}</GeneralLabel>)
    for (let i = 0; i < count; i++) {
      fields.push(<GeneralLabel>{t('Item '+(i+1))}</GeneralLabel>)
      fields.push(
        <div>
{        item.data.map((field,index)=>(
            field.feild==='select'? <div style={{ gridColumn: "span "+item.grid,paddingBottom:'10px' }}>
            <GeneralSelect index={i} itemName={item.name} data={data[item.name][i]} handleChange={handleChangeField} item={field} multiple={false} selectData={selectData}  />
            </div>:
            <TextField
              fullWidth
              variant="filled"
              type={field.type}
              label={field.label}
              onChange={(event)=>{handleChangeField(event,item.name,i)}}
              value={data[item.name][i][field.name]}
              name={field.name}
              InputProps={{
                style: { backgroundColor: '#fff',borderRadius:'12px',border:'1px solid' },
                disableUnderline: true
              }}
              InputLabelProps={{
                
                style: { color: '#000',fontSize:'16px',fontWeight:'bold' },
              }}

              sx={{paddingBottom:'10px'}}
            />
       
           
           
     
      
 
   
      
      
     
      )
        )}

<div>
      
       {i>0&& <Button sx={{marginBottom:'10px'}}  onClick={()=>{deleteField(item.name,i)}}>{t('Delete field')}</Button>}

      </div>
        </div>
      
      )
      
    }
    fields.push(  <Button  onClick={()=>{addField(item.name)}}>{t('Add field')}</Button>)

    return <div>{fields}</div>
}

export default VarientFields