import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from "react-i18next";
import { GlobalBoxContainer } from "../../Styles/Styles";
import { Actions, DataGridTable, Header } from "../../components";
import { getTransactionHeaderMapper, getTransactionMapper } from "./mapper";
import { transactionSelector } from "./redux/slice";

const   Transactions = ({ getTransactions, deleteTransaction }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { transactions } = useSelector(transactionSelector)
  useEffect(() => {
    getTransactions()
  }, [])

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header viewAll={true} title="Transactions" subtitle={"Welcome to your " + "transactions"} />
      </Box>
      <Button sx={{ bgcolor: '#0066CC' }} variant='contained' startIcon={<AddIcon />} onClick={() => { navigate('/transactions/add') }}>{t('Add')}</Button>
      <GlobalBoxContainer>
        <DataGridTable rows={getTransactionMapper(transactions)} columns={getTransactionHeaderMapper(transactions).concat(Actions(deleteTransaction, "edit/")).reverse()} />
      </GlobalBoxContainer>
    </Box>
  );
};

export default Transactions;