export const EDIT_PASSWORD_FORM =
[
  {
    feild:'textField',
    type: "password",
    label: "Old password",
    name: "old_password",
    grid: "2",
  },

  {
    feild:'textField',
    type: "password",
    label: "New password",
    name: "new_password",
    grid: "2",
  },

  {
    feild:'textField',
    type: "password",
    label: "New password confirmation",
    name: "new_password_confirmation",
    grid: "2",
  },
];