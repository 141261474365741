import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from "react-i18next";
import { GlobalBoxContainer } from "../../Styles/Styles";
import { Actions, DataGridTable, Header, ReceivableDetails } from "../../components";
import { getCurrentGeneralDailyDetails, getCurrentGeneralDailyHeaderMapper, getCurrentGeneralDailyMapper, getGeneralDailyHeaderMapper, getGeneralDailyMapper } from "./mapper";
import { generalDailySelector } from "./redux/slice";
import SearchField from "../../components/SearchField";
import { deleteNullValues } from "../../services/functions";
import { useDeleteDialog } from "../../components/DeleteDialog";
import { Filter } from "../../components/Filter";
import { GENERAL_DAILY_FILTER_FORM } from "./form";
const currentDate = new Date();

const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const day = String(currentDate.getDate()).padStart(2, '0');

const formattedDate = `${year}-${month}-${day}`;

const CurrentGeneralDaily = ({ getCurrentGeneralDaily,errors,setErrors }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { currentGeneralDaily,total } = useSelector(generalDailySelector)
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState({
    min_date:formattedDate,
    max_date:formattedDate
  })
  const [open, setOpen] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    page:0,
    pageSize:100
  })


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleFilter=()=>{
    setPaginationModel({...paginationModel,page:1})
    setOpen(false)
    getCurrentGeneralDaily({search:search?search:undefined,page:1,limit:paginationModel.pageSize,min_date:filter.min_date?filter.min_date:undefined,max_date:filter.max_date?filter.max_date:undefined})
    
  }

 
  const getSearchResults=()=>{

    // setPaginationModel({...paginationModel,page:0})
    getCurrentGeneralDaily({
      //limit:paginationModel.pageSize,page:paginationModel.page+1,
      search})

  }

  const handleFilterOpen = (item) => {
    // //console.log(deleteSubmit);
  
    handleClickOpen();
  };


  useEffect(() => {
    if(search)
    getCurrentGeneralDaily({search,page:1,limit:paginationModel.pageSize})
    else
    getCurrentGeneralDaily({page:paginationModel.page+1,limit:paginationModel.pageSize})

  }, [search])

  useEffect(() => {
    getCurrentGeneralDaily({page:paginationModel.page+1,limit:paginationModel.pageSize,search:search?search:undefined})

  }, [paginationModel.page,paginationModel.pageSize])

//console.log(currentGeneralDaily);

  return (
    <Box m="20px">
      <Filter open={open} setOpen={setOpen}  data={filter} errors={errors} setErrors={setErrors} setData={setFilter} form={GENERAL_DAILY_FILTER_FORM} handleFilter={handleFilter}  />

     
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header viewAll={true} title="Current General Daily" subtitle={"Welcome to your " + "current general daily"} />
      </Box>
      {localStorage.getItem('general-daily')=="Migrated"&&<Button sx={{ bgcolor: '#0066cc',top:'10px' }} variant='contained' startIcon={<AddIcon />} onClick={() => { navigate('/general-daily/add') }}>{t('Add new general daily')}</Button>}
      <Button sx={{ bgcolor: '#0066cc',marginRight:'2%',top:'10px'}} variant='contained'  onClick={() => { navigate('/general-daily') }}>{t('View last general dailies')}</Button>
      <Button sx={{ bgcolor: '#0066cc',marginRight:'2%',top:'10px'}} variant='contained'  onClick={ handleFilterOpen}>{t('Filter')}</Button>

      <SearchField getSearchResults={getSearchResults}  label={"Search Customer name or ticket number"} placeholder={'Enter Customer name or ticket number'} setValue={setSearch} value={search} />
      <ReceivableDetails title='Current General Daily Information' data={deleteNullValues(getCurrentGeneralDailyDetails(currentGeneralDaily)) }  />
      <GlobalBoxContainer>
       {localStorage.getItem('general-daily')=="Opened"&& <DataGridTable total={total} paginationModel={paginationModel} setPaginationModel={setPaginationModel} rows={getCurrentGeneralDailyMapper(currentGeneralDaily.tickets?currentGeneralDaily.tickets:[])} columns={getCurrentGeneralDailyHeaderMapper(currentGeneralDaily.tickets?currentGeneralDaily.tickets:[]).reverse()} />}
      </GlobalBoxContainer>
    </Box>
  );
};

export default CurrentGeneralDaily;