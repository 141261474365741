import Joi from "joi-browser";

export const exchangeSchema = {
    dollar_price: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a doller price ",
      };
    }),

    
};