import { createSlice } from "@reduxjs/toolkit"
import { addCustomer, deleteCustomer, editCustomer, getCustomers, getCustomer, getAccountStatment } from "./service"
import { fulfilledReducer, pendingReducer, rejectedReducer } from "../../../redux/globalReducer"
const initialState = {
    status: '',
    logging: false,
    customers:[],
    customer:{},
    total:0,
    transaction_total:0,
    tickets_total:0,
    accountStatement:{
        transactions:[],
        receivable_details:[],
        tickets:[]
    }
}
export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        delete(state,action){
            return {
                ...state,customers:[...state.customers].filter((customer)=>customer.id!=action.payload)
            }
       
        }
    },
    extraReducers: {
        [getCustomers.pending]: pendingReducer,
        [getCustomers.fulfilled]: (state, { payload }) => {
            state.customers= payload?payload?.data:[]
            state.total=payload.total?payload.total:state.total
            state.status = 'fulfilled'
            state.logging = false
        },
        [getCustomers.rejected]: rejectedReducer,

        [getAccountStatment.pending]: pendingReducer,
        [getAccountStatment.fulfilled]: (state, { payload }) => {
            state.accountStatement= payload?payload:{}
            state.transaction_total=payload?payload.transaction_total:state.transaction_total
            state.tickets_total=payload?payload.tickets_total:state.tickets_total
            state.status = 'fulfilled'
            state.logging = false
        },
        [getAccountStatment.rejected]: rejectedReducer,

        [deleteCustomer.pending]: pendingReducer,
        [deleteCustomer.fulfilled]: fulfilledReducer,
        [deleteCustomer.rejected]: rejectedReducer,

        
        [addCustomer.pending]: pendingReducer,
        [addCustomer.fulfilled]: fulfilledReducer,
        [addCustomer.rejected]: rejectedReducer,

        [getCustomer.pending]: pendingReducer,
        [getCustomer.fulfilled]: (state, { payload }) => {
           
            state.customer= payload?payload:{}
            state.status = 'fulfilled'
            state.logging = false
        },
        [getCustomer.rejected]: pendingReducer,

        [editCustomer.pending]: pendingReducer,
        [editCustomer.fulfilled]: fulfilledReducer,
        [editCustomer.rejected]: rejectedReducer,
 
    }
})

export const customerSelector = (state) => state.customer;