// export const apiUrl = "https://accounting-be.taj-alsham.com/api";

// export const apiUrl = "https://8d55-89-45-142-54.ngrok-free.app/api";


export const apiUrl = "https://staging-taj-alcham.quantumnexhub.com/api";
// export const apiUrl = "https://accounting-taj-alsham.quantumnexhub.com/api";


// export const storageUrl =
//   "https://design-back.futurecode-projects.com/storage/";
//   export const UPLOAD_FILE = apiUrl+'/localFile/uploadLocalFile/'

// Auth API 

export const SEND_CODE_TO_EMAIL = `${apiUrl}checkE`;
export const CONFIRM_VERIFY_ACCOUNT = `${apiUrl}confirm_phone`;
export const RESET_PASSWORD = `${apiUrl}reset`;
export const EDIT_PASSWORD = `${apiUrl}/auth/update-password`;

export const LOGIN = `${apiUrl}/auth/login`;
export const LOGOUT = `${apiUrl}/auth/logout`;

export const REFRESH_TOKEN = `${apiUrl}refresh_token`;
export const PROFILE = `${apiUrl}me`;
export const EDIT_PROFILE = `${apiUrl}edit`;
export const CHANGE_PASSWORD = `${apiUrl}password`;
export const CHANGE_EMAIL = `${apiUrl}email`;

// Customers api 
//DELETE_CUSTOMER, EDIT_CUSTOMER, CUSTOMER, CUSTOMERS, STORE_CUSTOMER

export const CUSTOMERS = `${apiUrl}/accounts/customers`;
export const CUSTOMER = `${apiUrl}/accounts/`;
export const ACCOUNT_STATEMENT = `${apiUrl}/accounts/customer-account-statement/`;

export const STORE_CUSTOMER = `${apiUrl}/accounts`;
export const EDIT_CUSTOMER = `${apiUrl}/accounts/`;
export const DELETE_CUSTOMER = `${apiUrl}/accounts/`;

// Companies api 
//DELETE_COMPANY, EDIT_COMPANY, COMPANY, COMPANYS, STORE_COMPANY

export const COMPANYS = `${apiUrl}/accounts`;
export const COMPANY = `${apiUrl}/accounts/`;
export const STORE_COMPANY = `${apiUrl}/accounts`;
export const EDIT_COMPANY = `${apiUrl}/accounts/`;
export const DELETE_COMPANY = `${apiUrl}/accounts/`;

// Suppliers api 
//DELETE_SUPPLIER, EDIT_SUPPLIER, SUPPLIER, SUPPLIERS, STORE_SUPPLIER

export const SUPPLIERS = `${apiUrl}/accounts`;
export const SUPPLIER = `${apiUrl}/accounts/`;
export const STORE_SUPPLIER = `${apiUrl}/accounts`;
export const EDIT_SUPPLIER = `${apiUrl}/accounts/`;
export const DELETE_SUPPLIER = `${apiUrl}/accounts/`;
export const SUPPLIER_ACCOUNT_STATEMENT = `${apiUrl}/accounts/supplier-account-statement/`;


/// Employee Api 
//DELETE_EMPLOYEE, EDIT_EMPLOYEE, EMPLOYEE, EMPLOYEES, STORE_EMPLOYEE,ROLES
export const EMPLOYEES = `${apiUrl}/employees`;
export const EMPLOYEE = `${apiUrl}/employees/`;
export const STORE_EMPLOYEE = `${apiUrl}/employees`;
export const EDIT_EMPLOYEE = `${apiUrl}/employees/`;
export const DELETE_EMPLOYEE = `${apiUrl}/employees/`;
export const ROLES = `${apiUrl}/auth/get-roles`;




// Ticket Api
//DELETE_TICKET, EDIT_TICKET, TICKET, TICKETS, STORE_TICKET


export const TICKETS = `${apiUrl}/tickets`;
export const CUSTOMER_RECEIVABLE = `${apiUrl}/customers/`;

export const TICKET = `${apiUrl}/tickets/show`;
export const STORE_TICKET = `${apiUrl}/tickets`;
export const STORE_RETURNED_TICKET = `${apiUrl}/tickets/add-returned-ticket`;

export const EDIT_TICKET = `${apiUrl}/tickets/`;
export const DELETE_TICKET = `${apiUrl}/tickets/`


/// EXCHAGNE Api
export const EDIT_EXCHAGNE = `${apiUrl}/constants`;
export const EXCHAGNE = `${apiUrl}/constants`;

// DELETE_GENERALDAILY, EDIT_GENERALDAILY, GENERALDAILY, GENERALDAILYS, STORE_GENERALDAILY
export const GENERALDAILYS = `${apiUrl}/general_dailies`;
export const GENERALDAILY = `${apiUrl}/general_dailies/`;
export const CURRENT_GENERALDAILY = `${apiUrl}/current-general-daily/details`;

export const STORE_GENERALDAILY = `${apiUrl}/general_dailies/open-new`;
export const MIGRATE_GENERALDAILY = `${apiUrl}/current-general-daily/migrate`;

export const EDIT_GENERALDAILY = `${apiUrl}/general_dailies/`;
export const DELETE_GENERALDAILY = `${apiUrl}/general_dailies/`;


//DELETE_TRANSACTION, EDIT_TRANSACTION, TRANSACTION, TRANSACTIONS, STORE_TRANSACTION
//Transactions Api

export const TRANSACTIONS = `${apiUrl}/transactions`;

export const TRANSACTION = `${apiUrl}/transactions/`;
export const STORE_TRANSACTION = `${apiUrl}/transactions`;
export const EDIT_TRANSACTION = `${apiUrl}/transactions/`;
export const DELETE_TRANSACTION = `${apiUrl}/transactions/`;


// DELETE_FUND, EDIT_FUND, FUND, FUNDS, STORE_FUND
export const FUNDS = `${apiUrl}/accounts/fund-account-statement`;

export const FUND = `${apiUrl}/transactions/`;
export const STORE_FUND = `${apiUrl}/transactions`;
export const STORE_BALANCE = `${apiUrl}/transactions/update-fund-balance`;

export const EDIT_FUND = `${apiUrl}/transactions/`;
export const DELETE_FUND = `${apiUrl}/transactions/`;

//DELETE_Recivable, EDIT_Recivable, RECIVABLE, RecivableS, STORE_Recivable
export const RecivableS = `${apiUrl}/accounts/fund-account-statement`;
export const RECIVABLE = `${apiUrl}/transactions/`;
export const STORE_Recivable = `${apiUrl}/receivables/add-receivable`;
export const EDIT_Recivable = `${apiUrl}/transactions/`;
export const DELETE_Recivable = `${apiUrl}/transactions/`;


//Expance
export const EXPANCES = `${apiUrl}/accounts/expense-account-statement`;

//Constant
//DELETE_CONSTANT, EDIT_CONSTANT, CONSTANT, CONSTANTS, STORE_CONSTANT

export const CONSTANTS = `${apiUrl}/constants`;
export const CONSTANT = `${apiUrl}/constants/`;
export const STORE_CONSTANT = `${apiUrl}/constants`;
export const EDIT_CONSTANT = `${apiUrl}/constants/`;
export const DELETE_CONSTANT = `${apiUrl}/constants/`;

































