import React, { useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    
    margin: theme.spacing(1),
    width:'80%'
  },
}));

const FilterBar = ({statues,clients,providers}) => {
  const classes = useStyles();
  const {t,i18n}=useTranslation()
  const navigate=useNavigate()
  const [searchParams]=useSearchParams()
  const [status, setStatus] = useState("");
  const [provider, setProvider] = useState("");
  const [client, setClient] = useState("");

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setClient('');
    setProvider('');
    navigate({
        pathname: "/orders",
        search: createSearchParams({
          status:event.target.value,
          user:null
        }).toString()
    });

  };

  const handleProviderChange = (event) => {
    setProvider(event.target.value);
    setClient('');
    setStatus('');
    navigate({
        pathname: "/orders",
        search: createSearchParams({
          status:null,
          user:event.target.value,
          type:'designer',

        }).toString()
    });
  };

  const handleClientChange = (event) => {
    setClient(event.target.value);
    setProvider('');
    setStatus('');

    navigate({
        pathname: "/orders",
        search: createSearchParams({
          status:null,
          user:event.target.value,
          type:'client',
        }).toString()
    });
  };

  useEffect(() => {
    if(searchParams.get('type')){
        if(searchParams.get('type')=='client'){
            setClient(searchParams.get('user'))
        }
        else
        setProvider(searchParams.get('user'))
    }
    else if(searchParams.get('status')&&searchParams.get('status')!='null')
    setStatus(searchParams.get('status'))
    
  }, [searchParams])
  

  return (
 
        <Grid sx={{marginTop:'10px'}} className={classes.root} spacing={5} container>
            <Grid lg={4} md={4} sm={12} xs={12}>
      <FormControl className={classes.formControl}>
        <InputLabel id="category-label">{t('Status')}</InputLabel>
        <Select
          sx={{width:'100%'}}
          labelId="category-label"
          id="category-select"
          value={status}
          onChange={handleStatusChange}
        >
         
          {statues.map((item)=>(
             <MenuItem value={item.id}>{item?.['name_'+i18n.language]}</MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>
      <Grid lg={4} md={4} sm={12} xs={12}>
      <FormControl className={classes.formControl}>
        <InputLabel id="price-label">{t('Clients')}</InputLabel>
        <Select
          labelId="price-label"
          id="price-select"
          value={client}
          onChange={handleClientChange}
        >
          {clients.map((item)=>(
            <MenuItem value={item.id}>{item?.['name']}</MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>
      <Grid lg={4} md={4} sm={12} xs={12}>
      <FormControl className={classes.formControl}>
        <InputLabel id="rating-label">{t('Designers')}</InputLabel>
        <Select
          labelId="rating-label"
          id="rating-select"
          value={provider}
          onChange={handleProviderChange}
        >
        {providers.map((item)=>(
            <MenuItem value={item.id}>{item?.['name']}</MenuItem>
          ))}
        </Select>
      </FormControl>
      </Grid>
      </Grid>


  );
};

export default FilterBar;
