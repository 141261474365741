import axios from 'axios'
class Requests {
    
    static get = async (url, headers, params = undefined) => {
        
        let result = null
        await axios.get(url, { headers: {...headers,"ngrok-skip-browser-warning": true}, params: params })
            .then((response) => { result = response })
            .catch((error) => { result = error.response })
        return result
    }
    static post = async (url, headers, body,params) => {
        let result = null
        await axios.post(url, body, { headers: {...headers,"ngrok-skip-browser-warning": true} ,params})
            .then((response) => { result = response })
            .catch((error) => { result = error.response })
            //console.log(result);
        return result
    }
    static put = async (url, headers, body) => {
        let result = null
        await axios.put(url, body, { headers: {...headers,"ngrok-skip-browser-warning": true} })
            .then((response) => { result = response })
            .catch((error) => { result = error.response })
        return result
    }

    static patch = async (url, headers, body) => {
        let result = null
        await axios.patch(url, body, { headers: {...headers,"ngrok-skip-browser-warning": true} })
            .then((response) => { result = response })
            .catch((error) => { result = error.response })
        return result
    }
    static delete = async (url, headers) => {
        let result = null
        await axios.delete(url, { headers: {...headers,"ngrok-skip-browser-warning": true} })
            .then((response) => { result = response })
            .catch((error) => {
                result = error.response
            })
        return result
    }
}
export default Requests;