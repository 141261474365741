import i18n from "../../localization"

export const getTransactionHeaderMapper=(data)=>{
    const transactionHeaderMapper=[]
    const transactionDataMappar=getTransactionMapper(data)
    const keys=transactionDataMappar.length>0? Object.keys(transactionDataMappar[0]):[]
    keys.map((key)=>{
        let words = key.split("_");
        let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        let result = capitalizedWords.join(" ");
        transactionHeaderMapper.push({
            field:key,
            headerName:i18n.t(result),
                  width: (key=='name'||key=="account_name")?200:100,
        })
    })
    return transactionHeaderMapper
}

export const getTransactionMapper=(data)=>{
    const transactionMapperData=[]
    data.map((item)=>{
        transactionMapperData.push({
            
        })
    })

    return transactionMapperData
}

export const getOneTransactionMapper=(data)=>{
   
    const oneTransactionMapperData={
           
        }
  
    return oneTransactionMapperData
}