import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useMediaQuery} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Header } from "../../components";
import { ADD_CUSTOMER_FORM } from "./form";

const AddCustomer = ({addCustomer,errors,setErrors}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {t}=useTranslation()
  const [customer, setCustomer] = useState({
      name:'',
      account_type:'Customer'
  })
   const [fieldsData, setFieldsData] = useState({
  })
  
  const handleFormSubmit = () => {
    addCustomer(customer)
  };
  const handleChange=(event)=>{
      const {name,value}=event.target
      setCustomer({...customer,[name]:value})
          setErrors((prevErrors)=>(
        {...prevErrors,
        [name]:''
        }
    ))
  }

  const handleChangeSwitch=(event)=>{
    const {name,checked}=event.target
    setCustomer({...customer,[name]:checked})
    setErrors((prevErrors)=>(
      {...prevErrors,
      [name]:''
      }
  ))
}

const handleColor=(color)=>{
     setCustomer({...customer,color:color.hex})
        setErrors((prevErrors)=>(
      {...prevErrors,
      color:''
      }
  ))
}

const handleChangeField=(event,itemName,index)=>{
    const {name,value}=event.target
    //console.log(name,value,index);
    const fields=customer[itemName]
    fields[index][name]=value
    setCustomer({...customer,[itemName]:fields})
}
const addField=(name)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]+1
    }
))
    const fields=customer[name]
    fields.push({})
    setCustomer((prevCustomer)=>(
      {...prevCustomer,
      [name]:fields
      }
  ))

}

const deleteField=(name,index)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]-1
    }
))
    const fields=customer[name]
    fields.splice(index,1)
    setCustomer({...customer,[name]:fields})
}
  return (
    <Box m="20px">
      <Header title="Customer" subtitle="a New Customer" />
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
           
            <Form handleColor={handleColor} handleChangeSwitch={handleChangeSwitch}  errors={errors} data={customer}  handleChange={handleChange} type='add' form={ADD_CUSTOMER_FORM}  />
            
          </Box>

          <Box display="flex" justifyContent="end" mt="20px">
            <Button onClick={()=>{handleFormSubmit()}} type="submit" color="secondary" variant="contained">
              {t("New Customer")}
            </Button>
          </Box>
     
  
    </Box>
  );
};

export default AddCustomer;
