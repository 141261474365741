import React, {  useEffect, useState } from "react";
import useHeaders from "../../hooks/useHeaders";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompany,deleteCompany,editCompany,getCompany,getCompanys
} from "./redux/service";
import { companySelector,companySlice} from "./redux/slice";
import { useSidebarContext } from "../../components/global/sidebar/sidebarContext";
import Companys from "./Companys";
import AddCompany from "./AddCompany";
import EditCompany from "./EditCompany";
import { LoadingDialog } from "../../components";
import Validation from "../../services/Validation";
import { addCompanySchema, editCompanySchema } from "./validationSchema";
import { ToastContainer } from "react-toastify";

function CompanysController({ child }) {
  const { setSelected } = useSidebarContext();
  const { logging } = useSelector(companySelector);
  const navigate = useNavigate();
  const headers = useHeaders();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({})
  const _getCompanys = async (params) => {
    dispatch(getCompanys({ headers,params }))
  };
  const _deleteCompany = async (id) => {
    dispatch(deleteCompany({ headers, id })).then((res) => {
      if (res.error) {
      } else {
        dispatch(companySlice.actions.delete(id));
      }
    });
  };
  const _addCompany = async (company) => {
  
     const companyValidationErrors = Validation.validation(addCompanySchema, company);
     if (companyValidationErrors) {
      setErrors(companyValidationErrors);
    } else {
    const _company={
      ...company
     }
       dispatch(addCompany({ headers,company:_company })).then((res) => {
         if (res.error) {
         } else {
           navigate("/companys");
         }
       });
      }
  
    
  };



  const _getCompany = async (id) => {
    dispatch(getCompany({ headers, id }));
  };
  const _editCompany = async (id, company) => {
    const companyValidationErrors = Validation.validation(editCompanySchema, company);
    if (companyValidationErrors) {
     setErrors(companyValidationErrors);
   } else {
    const _company={...company}
      dispatch(editCompany({ headers, company: _company,id})).then((res) => {
        if (res.error) {
        } else {
          navigate("/companys");
        }
      });
    }
    }


  useEffect(() => {
    setSelected&& setSelected("Companys");
  }, []);

  const initChild = () => {
    switch (child) {
      case "companys": {
        return <Companys deleteCompany={_deleteCompany} getCompanys={_getCompanys}/>;
      }
      case "add-company":
        return (
          <AddCompany
        addCompany={_addCompany}
        errors={errors}
        setErrors={setErrors}
          />
        );

      case "edit-company":
        return (
          <EditCompany
          editCompany={_editCompany}
          getCompany={_getCompany}
          errors={errors}
          setErrors={setErrors}
          />
        );

      default:
    }
  };
  return (
    <div>
      <ToastContainer/>

      {logging && <LoadingDialog open={logging} />}

      {initChild()}
    </div>
  );
}

export default CompanysController;
