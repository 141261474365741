import { Navigate, Routes, Route, useNavigate } from "react-router-dom";
import { authRoutes, dashboardRoutes } from './routers';
import DashboardLayout from './layout/dashboardLayout'
import { useCookies } from "react-cookie";
import { useEffect } from "react";
// layouts

// ----------------------------------------------------------------------

export default function Router() {
  const [cookies] = useCookies([
    "token",
  ]);
  const navigate=useNavigate()  
  useEffect(() => {
    !cookies.token&&navigate('/auth/login')
  }, [])
  
  return (


    dashboardRoutes.map((item,index)=>(
      item.provider?
      <item.provider>
        <Routes>
        {item.component?
          <Route  path={item.path} element={item.component}/>:
          <Route path={item.path} element={
            <Routes>
            {item.children.map((child,index)=>(
              <Route path={child.path} element={child.component}/>
            ))}
          </Routes>
          }
          />
      
        
      }
      </Routes>
      </item.provider>:
      <Routes>
         {item.component?
          <Route path={item.path} element={item.component}/>:
          <Route path={item.path} element={
            <Routes>
            {item.children.map((child,index)=>(
              <Route path={child.path} element={child.component}/>
            ))}
          </Routes>
          }
          />
        }
     

   
      
    
    </Routes>
     ))
 


 

  );

}
