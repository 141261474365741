import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { customerSelector } from "../features/customer/redux/slice";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  noUnderline: {
    '& .MuiAutocomplete-inputRoot': {
      // direction:'rtl',

      '& .MuiAutocomplete-input': {
        '& input': {
          textDecoration: 'none',
        },
      },
    },
  },
}));
const removeUnderline = {
  borderBottom: 'none',
  '&:before': {
    borderBottom: 'none',
  },
  '&:after': {
    borderBottom: 'none',
  },
};
const AutocompleteWithIdName = ({ suggestions, setValue,value, item,submit,submitSearch }) => {
    const {t}= useTranslation()
    const {customers}=useSelector(customerSelector)
    //console.log(suggestions);
   
    const _value=value[item.name]?value[item.name]:customers?.filter((customer)=>customer.id==value['customer_id'])[0]?.name
    
    const onChangeInput=(event,val)=>{
      console.log(val);
      const x=customers?.filter((customer)=>customer.name==val)[0]
      if(event){
        const name=event.target.id.split("-")[0]
        if(name){
          const index=parseInt(event.target.id.split("-").pop())
          const select=isNaN(index)
          console.log(select);
          if(!select){
            if(submit){
              submit(x)
            }
            else
            setValue({...value,[name]:val})
    
          }
          else{
            // const x=customers?.filter((customer)=>customer.name==val)[0]
            console.log(x);
            if(x){
              submit(x)
            }
            
            if(val.length==1)
          if(submitSearch)
          submitSearch(val)
          
          if(!x)
          {
            submit()
            setValue({...value,[name]:val,customer_id:''})

          }
    
          }
        }
        
      }
     
    }
  
    
    const classes = useStyles();
    return (
        <div>
        {/* <h3 style={{fontSize:'20px'}}>{t(item.label)}</h3> */}
        <Autocomplete
          
          className={classes.noUnderline}
          getOptionLabel={(option) => option.value}
          options={suggestions[item.name]}
          onInputChange={onChangeInput}
          selectOnFocus
          
          freeSolo={!item.freeSolo}
          // clearOnBlur
          handleHomeEndKeys
          value={_value}
          
          includeInputInList
        
          style={{ width: "100%",fontSize:'16px',borderBottom:'none' }}
          id={item.name}
          renderInput={(params) => 
          <TextField

            variant='outlined'
            className='inputs'

            InputLabelProps={{
            sx: {
               
              // set the color of the label when not shrinked
              color: "#c7c8c8",
              fontWeight:600,
              fontSize:'16px',
              "&.MuiInputLabel-root.Mui-focused":{
                color: "#c7c8c8",
            }
            },
           
            
          }}   
          InputProps={{   disableUnderline: true,  }}
           id={params} 
          label={t(item.label)}
          {...params}

          
   
         
          
          />
        
        }

        />
      </div>
    );
  };
  
  export default AutocompleteWithIdName;