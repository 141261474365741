// docs https://github.com/azouaoui-med/react-pro-sidebar
import { useEffect, useState } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";

import { useSidebarContext } from "./sidebarContext";

import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton, Avatar, Divider } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SideBarItem from "../../../components/SideBarItem";
import { sideBarData } from "../../../constent/sideBar";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { storageUrl } from "../../../constent/urls";
import logo from '../../../assets/img/cropped-taj-al-sham.png-250x250-01.png'
const MyProSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { sidebarRTL, setSidebarRTL, sidebarImage,selected,setSelected,setSideBarWidth } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  const {t,i18n}=useTranslation()

  const [cookies]=useCookies(['user'])

  //console.log(cookies.user);
  const replaceImage = (error) => {
    //console.log(error);
    // error.target.src=defaultImage
    //replacement of broken Image
    // error.target.src = this.state.defaultImage; 
}
  useEffect(() => {
    i18n.language=='ar'?setSidebarRTL(true):setSidebarRTL(false)
  },[i18n.language]);

  useEffect(() => {

    setSideBarWidth(250)
  }, [])
  
  //console.log(i18n.language);

 
  return (
    <Box
    id='s'
      sx={{
        // width:'20%',
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 1000,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          // padding: "5px 35px 5px 20px !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
        "& .menu-item.active": {
          color: `${colors.greenAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
      }}
    >
      <Sidebar
        breakPoint="md"
        rtl={sidebarRTL}
        backgroundColor='#f0f0ff'
        image={sidebarImage}
        // width="200px"
      >
        <Menu iconshape="square">
          <MenuItem
            icon={
              collapsed ? (
                <MenuOutlinedIcon onClick={() => {setSideBarWidth(250);collapseSidebar()}} />
              ) :''
            }
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                {/* <Typography sx={{fontFamily:'Cairo,sans-serif'}} variant="h3" color={colors.grey[100]}>
                 تاج للسياحة والسفر 
                </Typography> */}
                <IconButton
                  onClick={
                    broken ? () => {setSideBarWidth(250);toggleSidebar()} : () => {setSideBarWidth(80);collapseSidebar()}
                  }
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!collapsed && (
            <Box mb="25px">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  "& .avater-image": {
                    backgroundColor: colors.primary[500],
                  },
                }}
              >
                <Avatar
                  // className="avater-image"
                  sx={{width:100,height:100,cursor:'pointer'}}
                  width="100px"
                  height="100px"
                  src={logo} //TODO user image
                  
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h3"
                  color='#0066CC'
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                       تاج الشام للسياحة والسفر  {/* TODO name of user*/ }
                </Typography>
              </Box>
            </Box>
          )}
          <Box paddingLeft={collapsed ? undefined : "10%"}>

            {sideBarData.map((item)=>(
              <div>
                {item.title&&
              <div>
              <Divider/>
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 20px 5px 20px" }}
            >
              {t(item.title)}
            </Typography>
            </div>
            }
            {item.items?.map((subItem)=>(
              <SideBarItem
              title={t(subItem.title)}
              to={subItem.to}
              icon={subItem.icon}
              selected={selected}
              setSelected={setSelected}
            />
            ))}
            {item.title&&<Divider/>}
              </div>
            ))}
            

          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
