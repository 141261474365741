import Joi from "joi-browser";

export const addEmployeeSchema = {
    name: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter  name",
      };
    }),

  

    username: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a username",
      };
    }),

   

    

    is_active: Joi.boolean()
    .required()
    .error(() => {
      return {
        message: "Please choose active",
      };
    }),
    

    password: Joi.string().required().min(8).error(() => {
      return {
        message: 'Please enter a password at least 8 characters',
      }}),

     role: Joi.string()
     .required()
     .error(() => {
       return {
         message: "Please choose a role",
       };
     }),
};

export const editEmployeeSchema = {
    name: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter  name",
      };
    }),

  

    username: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a username",
      };
    }),

   

    

    is_active: Joi.boolean()
    .required()
    .error(() => {
      return {
        message: "Please choose active",
      };
    }),
    

    password: Joi.string().min(8).allow(null).error(() => {
        return {
          message: 'Please enter a password at least 8 characters, or leave it blank',
        };
      }),

     role: Joi.string()
     .required()
     .error(() => {
       return {
         message: "Please choose a role",
       };
     }),
};
