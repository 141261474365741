
const cookies={}
document.cookie?.split(';').map((item)=>{
 
  const [name, value] = item.trim().split('=');
  cookies[name]=value
});
const role=cookies?.user?JSON.parse(cookies?.user)?.role:''
export const ADD_TICKET_FORM = [
  {
    feild: "autoComplete",
    label: "Customer",
    name: "customer_name",
    grid: "1",
  },

  {
    feild: "textField",
    type: "date",
    label: "Release date",
    name: "release_date",
    grid: "1",
  },
  {
    feild: "textField",
    type: "text",
    label: "Travel",
    name: "travel",
    grid: "1",
  },

  {
    feild: "textField",
    type: "text",
    label: "Ticket number",
    name: "ticket_number",
    grid: "1",
  },
  {
    feild: "textField",
    type: "text",
    label: "Description",
    name: "description",
    grid: "4",
    multiline: true,
    rows: 4,
  },
  

  

  

  

   
  // {
  //   feild: "textField",
  //   type: "text",
  //   label: "Commission",
  //   name: "commission",
  //   grid: "1",
  // },
  // {
  //   feild: "textField",
  //   type: "text",
  //   label: "Supplier commission price",
  //   name: "supplier_commission_price",
  //   grid: "1",
  // },

  // {
  //   feild: "select",
  //   type: "text",
  //   label: "Company",
  //   data: [],
  //   name: "company_id",
  //   grid: "1",
  // },

  // {
  //   feild: "textField",
  //   type: "text",
  //   label: "ticket price syria",
  //   name: "ticket_price_sy",
  //   grid: "2",
  // },

  // {
  //   feild: "textField",
  //   type: "text",
  //   label: "ticket price syria fare",
  //   name: "ticket_price_sy_fare",
  //   grid: "2",
  // },

  // {
  //   feild: "textField",
  //   type: "text",
  //   label: "Ticket price dollar",
  //   name: "ticket_price_dollar",
  //   grid: "2",
  // },

  // {
  //   feild: "textField",
  //   type: "text",
  //   label: "ticket price dollar fare",
  //   name: "ticket_price_dollar_fare",
  //   grid: "2",
  // },

  // {
  //   feild: "textField",
  //   type: "text",
  //   label: "Customer Price",
  //   name: "customer_price",
  //   grid: "2",
  // },

  // {
  //   feild: "textField",
  //   type: "text",
  //   label: "Pay price sy",
  //   name: "pay_price_sy",
  //   grid: "1",
  // },

  // {
  //   feild: "textField",
  //   type: "text",
  //   label: "Pay price dollar",
  //   name: "pay_price_dollar",
  //   grid: "1",
  // },
];

export const ADD_TICKET_FORM_SECOUND = [
  {
    feild: "select",
    type: "text",
    label: "Currency",
    data: [],
    name: "currency_id",
    grid: "1",
  },

  {
    feild: "select",
    type: "text",
    label: "Supplier",
    data: [],
    name: "supplier_id",
    grid: "1",
  
  },

  {
    feild: "select",
    type: "text",
    label: "Company",
    data: [],
    name: "company_id",
    grid: "1",
  },
  {
    feild: "select",
    type: "text",
    label: "Supplier deal type",
    data: [],
    name: "supplier_deal_type",
    grid: "1",
  },
];

export const ADD_RETURNED_TICKET_FORM = [
  {
    feild: "autoComplete",
    type: "text",
    freeSolo: true,
    label: "Ticket number",
    data: [],
    name: "ticket_number",
    grid: "4",
  },

  {
    feild: "textField",
    type: "text",
    label: "Description",
    name: "description",
    grid: "4",
    multiline: true,
    rows: 4,
  },

  {
    feild: "select",
    type: "text",
    label: "Supplier",
    data: [],
    name: "supplier_id",
    grid: "2",
  },

  {
    feild: "select",
    type: "text",
    label: "Customer",
    data: [],
    name: "customer_id",
    grid: "2",
  },

  {
    feild: "textField",
    type: "text",
    label: "Returned amount supplier sy",
    name: "returned_amount_supplier_sy",
    grid: "2",
  },

  {
    feild: "textField",
    type: "text",
    label: "Returned amount supplier dollar",
    name: "returned_amount_supplier_dollar",
    grid: "2",
  },

  {
    feild: "textField",
    type: "text",
    label: "Returned amount customer sy",
    name: "returned_amount_customer_sy",
    grid: "2",
  },

  {
    feild: "textField",
    type: "text",
    label: "Returned amount customer dollar",
    name: "returned_amount_customer_dollar",
    grid: "2",
  },
];

export const EDIT_TICKET_FORM = [
  {
    feild: "textField",
    type: "text",
    label: "Customer Price",
    name: "customer_price",
    grid: "2",
  },

  {
    feild: "textField",
    type: "text",
    label: "Travel",
    name: "travel",
    grid: "2",
  },

  {
    feild: "textField",
    type: "text",
    label: "Description",
    name: "description",
    grid: "2",
    multiline: true,
    rows: 4,
  },

  {
    feild: "textField",
    type: "date",
    label: "Release date",
    name: "release_date",
    grid: "2",
  },

  {
    feild: "textField",
    type: "text",
    label: "Rest amount",
    name: "rest_amount",
    grid: "1",
  },

  {
    feild: "textField",
    type: "text",
    label: "Ticket price",
    name: "ticket_price",
    grid: "1",
  },

  {
    feild: "textField",
    type: "text",
    label: "Ticket price fare",
    name: "ticket_price_fare",
    grid: "1",
  },

  {
    feild: "textField",
    type: "text",
    label: "Payment price to supplier",
    name: "payment_price_to_supplier",
    grid: "1",
  },
];

export const EDIT_TICKET_FORM_WITHOUT_FARE = [
  {
    feild: "textField",
    type: "text",
    label: "Customer Price",
    name: "customer_price",
    grid: "2",
  },

  {
    feild: "textField",
    type: "text",
    label: "Travel",
    name: "travel",
    grid: "2",
  },

  {
    feild: "textField",
    type: "text",
    label: "Description",
    name: "description",
    grid: "2",
    multiline: true,
    rows: 4,
  },

  {
    feild: "textField",
    type: "date",
    label: "Release date",
    name: "release_date",
    grid: "2",
  },

  {
    feild: "textField",
    type: "text",
    label: "Rest amount",
    name: "rest_amount",
    grid: "1",
  },

  {
    feild: "textField",
    type: "text",
    label: "Ticket price",
    name: "ticket_price",
    grid: "1",
  },

  // {
  //   feild: "textField",
  //   type: "text",
  //   label: "Ticket price fare",
  //   name: "ticket_price_fare",
  //   grid: "1",
  // },

  {
    feild: "textField",
    type: "text",
    label: "Payment price to supplier",
    name: "payment_price_to_supplier",
    grid: "1",
  },
];

export const VOID_TICKET_FORM = [
  {
    feild: "textField",
    type: "text",
    label: "Fine price syria",
    name: "fine_sy",
    grid: "2",
  },

  {
    feild: "textField",
    type: "text",
    label: "Fine price dollar",
    name: "fine_dollar",
    grid: "2",
  },
];



export const REFUND_TICKET_FORM = [
  {
    feild: "textField",
    type: "text",
    label: "Customer fine",
    name: "customer_fine",
    grid: "2",
  },

  {
    feild: "textField",
    type: "text",
    label: "Supplier fine",
    name: "supplier_fine",
    grid: "2",
  },
];

export const REISSUE_TICKET_FORM = [
  {
    feild: "textField",
    type: "text",
    label: "Customer fine",
    name: "customer_fine",
    grid: "2",
  },

  {
    feild: "textField",
    type: "text",
    label: "Supplier fine",
    name: "supplier_fine",
    grid: "2",
  },

  {
    feild: "textField",
    type: "text",
    label: "Travel",
    name: "travel",
    grid: "2",
  },

  

  {
    feild: "textField",
    type: "date",
    label: "Release date",
    name: "release_date",
    grid: "2",
  },

  {
    feild: "textField",
    type: "text",
    label: "Description",
    name: "description",
    grid: "4",
    multiline: true,
    rows: 4,
  },
];


export const FLAT_COMMISSION_FORM = [

  {
    feild: "textField",
    type: "text",
    label: "Ticket price",
    name: "ticket_price",
    grid: "1",
  },
  {
    feild: "textField",
    type: "text",
    label: "Commission",
    name: "supplier_commission",
    grid: "1",
  },

 
  {
    feild: "textField",
    type: "text",
    label: "Customer Price",
    name: "customer_price",
    grid: "1",
  },

  role=='Manager'&&{
    feild: "textField",
    type: "text",
    label: "Pay price sy",
    name: "pay_price_sy",
    grid: "1",
  },

  role=='Manager'&& {
    feild: "textField",
    type: "text",
    label: "Pay price dollar",
    name: "pay_price_dollar",
    grid: "1",
  },
];

export const PLUS_COMMISSION_FORM = [

  {
    feild: "textField",
    type: "text",
    label: "Ticket price",
    name: "ticket_price",
    grid: "1",
  },
  {
    feild: "textField",
    type: "text",
    label: "Commission",
    name: "supplier_commission",
    grid: "1",
  },

 
  {
    feild: "textField",
    type: "text",
    label: "Customer Price",
    name: "customer_price",
    grid: "1",
  },

  role=='Manager'&&{
    feild: "textField",
    type: "text",
    label: "Pay price sy",
    name: "pay_price_sy",
    grid: "1",
  },

  role=='Manager'&& {
    feild: "textField",
    type: "text",
    label: "Pay price dollar",
    name: "pay_price_dollar",
    grid: "1",
  },
];

export const RATIO_COMMISSION_FORM = [

  {
    feild: "textField",
    type: "text",
    label: "Ticket price",
    name: "ticket_price",
    grid: "1",
  },

  {
    feild: "textField",
    type: "text",
    label: "Ticket fare",
    name: "ticket_fare",
    grid: "1",
  },
  {
    feild: "textField",
    type: "text",
    label: "Commission(1-100)",
    name: "supplier_commission",
    grid: "1",
  },

 
  {
    feild: "textField",
    type: "text",
    label: "Customer Price",
    name: "customer_price",
    grid: "1",
  },

  role=='Manager'&&{
    feild: "textField",
    type: "text",
    label: "Pay price sy",
    name: "pay_price_sy",
    grid: "1",
  },

  role=='Manager'&& {
    feild: "textField",
    type: "text",
    label: "Pay price dollar",
    name: "pay_price_dollar",
    grid: "1",
  },
];


