import AuthController from "../features/auth/AuthControler";
import CompanysController from "../features/company/CompanysController";
import ConstantsController from "../features/constant/ConstantsController";
import CustomersController from "../features/customer/CustomersController";
import EmployeesController from "../features/employee/EmployeesController";
import ExpancesController from "../features/expance/ExpancesController";
import FundsController from "../features/fund/FundsController";
import GeneralDailysController from "../features/generalDaily/GeneralDailysController";
import RecivablesController from "../features/recivable/RecivablesController";
import SuppliersController from "../features/supplier/SuppliersController";
import TicketsController from "../features/ticket/TicketsController";
import TransactionsController from "../features/transaction/TransactionsController";



export const dashboardRoutes = [
  // {
  //   path: "/",
  //   children: [
  //     {
  //       path: "/",
  //       component: <AppController />,
  //     },
  //   ],
  // },
  // {
  //   path: "/dashboard",
  //   component: <ChartsController child="charts" />,
  // },

  {
    path: "/customers*",
    children: [
      {
        path: "/",
        component: <CustomersController child="customers" />,
      },

      {
        path: "/add",
        component: <CustomersController child="add-customer" />,
      },

      {
        path: "/edit/:id",
        component: <CustomersController child="edit-customer" />,
      },

      {
        path: "/account-statement/:id",
        component: <CustomersController child="account-statement" />,
      },
    ],
  },

  {
    path: "/companys*",
    children: [
      {
        path: "/",
        component: <CompanysController child="companys" />,
      },

      {
        path: "/add",
        component: <CompanysController child="add-company" />,
      },

      {
        path: "/edit/:id",
        component: <CompanysController child="edit-company" />,
      },
    ],
  },

  {
    path: "/suppliers*",
    children: [
      {
        path: "/account-statement/:id",
        component: <SuppliersController child="account-statement" />,
      },
      {
        path: "/",
        component: <SuppliersController child="suppliers" />,
      },

      {
        path: "/add",
        component: <SuppliersController child="add-supplier" />,
      },

      {
        path: "/edit/:id",
        component: <SuppliersController child="edit-supplier" />,
      },
    ],
  },

  
  {
    path: "/employees*",
    children: [
      {
        path: "/",
        component: <EmployeesController child="employees" />,
      },

      {
        path: "/add",
        component: <EmployeesController child="add-employee" />,
      },

      {
        path: "/edit/:id",
        component: <EmployeesController child="edit-employee" />,
      },
    ],
  },

  {
    path: "/tickets*",
    children: [
      {
        path: "/",
        component: <TicketsController child="tickets" />,
      },

      {
        path: "/add",
        component: <TicketsController child="add-ticket" />,
      },

      {
        path: "/add-returned",
        component: <TicketsController child="add-returned-ticket" />,
      },

      {
        path: "/edit/:id",
        component: <TicketsController child="edit-ticket" />,
      },
    ],
  },

  {
    path: "/constants*",
    children: [
      {
        path: "/",
        component: <ConstantsController child="constants" />,
      },

      {
        path: "/add",
        component: <ConstantsController child="add-constant" />,
      },

      {
        path: "/edit/:id",
        component: <ConstantsController child="edit-constant" />,
      },
    ],
  },

  {
    path: "/transactions*",
    children: [
      // {
      //   path: "/",
      //   component: <TicketsController child="tickets" />,
      // },

      {
        path: "/add",
        component: <TransactionsController child="add-transaction" />,
      },

      // {
      //   path: "/edit/:id",
      //   component: <EmployeesController child="edit-employee" />,
      // },
    ],
  },

  {
    path: "/general-daily*",
    children: [

      {
        path: "/current-general-daily",
        component: <GeneralDailysController child="current-general-daily" />,
      },
      {
        path: "/",
        component: <GeneralDailysController child="generalDailys" />,
      },

      {
        path: "/add",
        component: <GeneralDailysController child="add-generalDaily" />,
      },

      {
        path: "/edit/:id",
        component: <GeneralDailysController child="edit-generalDaily" />,
      },
    ],
  },

  {
    path: "/funds*",
    children: [

      {
        path: "/",
        component: <FundsController child="funds" />,
      },
    ],
  },

  {
    path: "/bank*",
    children: [

      {
        path: "/",
        component: <FundsController child="bank" />,
      },
    ],
  },

  {
    path: "/recivables*",
    children: [

      {
        path: "/",
        component: <RecivablesController child="Recivables" />,
      },

      {
        path: "/add",
        component: <RecivablesController child="add-Recivable" />,
      },
    ],
  },

  {
    path: "/Expances*",
    children: [

      {
        path: "/",
        component: <ExpancesController child="Expances" />,
      },
    ],
  },
 
  

  {
    path: "/auth/*",
    children: [
      {
        path: "/login",
        component: <AuthController child="login" />,
      },
      
    ],
  },

  

];

