import { Link } from "react-router-dom";
import i18n from "../localization";
import { DeleteDialogProvider, useDeleteDialog } from './DeleteDialog';

export function Actions(deleteSubmit, editLink,accountStatement, banSubmit,unBanSubmit,migrateSubmit,downloadSubmit,voidTicket,refundTicket,reissueTicket,editLink1,width,deleteSubmit2) {
  const { handleClickOpen} = useDeleteDialog();
  const cookies={}
document.cookie?.split(';').map((item)=>{
 
  const [name, value] = item.trim().split('=');
  cookies[name]=value
});
  const role=cookies?.user?JSON.parse(cookies?.user)?.role:''
  console.log(role);
  const handleDeleteItem = (item) => {
    // //console.log(deleteSubmit);
  
    handleClickOpen(item,deleteSubmit);
  };
  const handleDeleteItem2 = (item) => {
    // //console.log(deleteSubmit);
  
    handleClickOpen(item,deleteSubmit2);
  };

  //console.log(downloadSubmit);
  return [
    {
      field: "action",
      headerName: i18n.t("Action"),
      
      width: width?width:300,
      renderCell: (params) => {
        //console.log(params.row?.type);
        return (
          <div style={{marginLeft:'auto'}}  className="cellAction">

            {editLink &&  (
              <Link
                
                to={editLink + params?.row?.id}
                style={{ textDecoration: "none", }}
              >
                <div className="viewButton">{i18n.t("Edit")}</div>
              </Link>
            )}
            {editLink1 &&params.row.status==='مفتوح'&&params?.row?.type!=="مرتجعة"&&params?.row?.type!=='ملغاة' && params?.row?.is_refunded!==1&&  (
              <Link
                to={editLink1 + params?.row?.id}
                style={{ textDecoration: "none" }}
              >
                <div className="viewButton">{i18n.t("Edit")}</div>
              </Link>
            )}

{migrateSubmit && params.row.status==='open'||params.row.status==='مفتوحة' &&(
              <div
              className="deleteButton"
              onClick={() => {
                migrateSubmit()
              }}
            >
              {i18n.t("Migrate")}
            </div>
            )}

{downloadSubmit && params.row.type&& (params.row.status==='migrated'||params.row.status==='مهجرة')&& (
              <div
              className="deleteButton"
              onClick={() => {
                downloadSubmit(params?.row?.file_path)
              }}
            >
              {i18n.t("Downlaod")}
            </div>
            )}
               {accountStatement && (
              <Link
                to={accountStatement + params?.row?.id}
                style={{ textDecoration: "none" }}
              >
                <div  className="viewButton">{i18n.t("Account statement")}</div>
              </Link>
            )}
            {deleteSubmit && (
              
              <div
                className="deleteButton"
                onClick={() => {
                  handleDeleteItem(params?.row?.id)
                }}
              >
                {i18n.t("Delete")}
              </div>
              
            )}

{deleteSubmit2 &&params.row.status==='مفتوح'&&params?.row?.type=="عادية"&&role=="Manager"&&(
              
              <div
                className="deleteButton"
                onClick={() => {
                  handleDeleteItem2(params?.row?.id)
                }}
              >
                {i18n.t("Delete")}
              </div>
              
            )}

            {banSubmit && (
              <div
                className="deleteButton"
                onClick={() => {
                  banSubmit(params?.row?.id);
                }}
              >
                {i18n.t("Ban")}
              </div>
            )}

          {unBanSubmit && (
              <div
                className="deleteButton"
                onClick={() => {
                  unBanSubmit(params?.row?.id);
                }}
              >
                {i18n.t("Unban")}
              </div>
            )}

{voidTicket && (params?.row?.type===i18n.t('Normal')||params?.row?.type===i18n.t('ReIssue')||params?.row?.type===i18n.t('Added_Receivable'))&&!params?.row?.is_refunded&&params.row.status!=='Migrated'&&params.row.status!=='مهجرة'&&role=="Manager"&& (
              <div
                className="deleteButton"
                onClick={() => {
                  voidTicket(params?.row?.id);
                }}
              >
                {i18n.t("Void")}
              </div>
            )}
            {refundTicket && (params?.row?.type===i18n.t('Normal')||params?.row?.type===i18n.t('ReIssue'))&&!params?.row?.is_refunded&& params.row.status!=='Migrated'&&params.row.status!=='مهجرة'&&role=="Manager"&& (
              <div
                className="deleteButton"
                onClick={() => {
                  refundTicket(params?.row?.id);
                }}
              >
                {i18n.t("Refund")}
              </div>
            )}

{reissueTicket && (params?.row?.type!=="مرتجعة"&&params?.row?.type!=='ملغاة') && !params?.row?.is_refunded&& params.row.status!=='Migrated'&&params.row.status!=='مهجرة'&& (
              <div
                className="deleteButton"
                onClick={() => {
                  reissueTicket(params?.row?.id,params?.row);
                }}
              >
                {i18n.t("ReIssue")}
              </div>
            )}
          </div>
        );
      },
    },

    
  ];
}
