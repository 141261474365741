import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from "react-i18next";
import { GlobalBoxContainer } from "../../Styles/Styles";
import { Actions, DataGridTable, Header } from "../../components";
import { getConstantHeaderMapper, getConstantMapper } from "./mapper";
import { constantSelector } from "./redux/slice";

const Constants = ({ getConstants, deleteConstant }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { constants } = useSelector(constantSelector)
  useEffect(() => {
    getConstants()
  }, [])

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Constants" subtitle={"Welcome to your " + "constants"} />
      </Box>
      <Button sx={{ bgcolor: '#0066CC' }} variant='contained' startIcon={<AddIcon />} onClick={() => { navigate('/constants/add') }}>{t('Add')}</Button>
      <GlobalBoxContainer>
        <DataGridTable rows={getConstantMapper(constants)} columns={getConstantHeaderMapper(constants).concat(Actions(deleteConstant, "edit/",'','','','','','','','','',180)).reverse()} />
      </GlobalBoxContainer>
    </Box>
  );
};

export default Constants;