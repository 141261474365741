import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from "react-i18next";
import { GlobalBoxContainer } from "../../Styles/Styles";
import { Actions, DataGridTable, Header, ReceivableDetails, useSidebarContext } from "../../components";
import { getExpanceDetails, getExpanceHeaderMapper, getExpanceMapper } from "./mapper";
import { ExpanceSelector } from "./redux/slice";
import { deleteNullValues } from "../../services/functions";
import { useState } from "react";

const Expances = ({ getExpances }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { Expances,total } = useSelector(ExpanceSelector)
  const {sidebarWidth} = useSidebarContext();
  const [paginationModel, setPaginationModel] = useState({
    page:0,
    pageSize:100
  })
  useEffect(() => {
    getExpances({page:paginationModel.page+1,limit:paginationModel.pageSize})
  }, [])

  useEffect(() => {
    getExpances({page:paginationModel.page+1,limit:paginationModel.pageSize})

  }, [paginationModel.page,paginationModel.pageSize])


  return (
    <Box sx={{width:`calc(100%-${sidebarWidth+'px'})`}} m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header viewAll={true} title="Expances" subtitle={"Welcome to your " + "Expances"} />
      </Box>
      {/* <Button sx={{ bgcolor: '#0066CC' }} variant='contained' startIcon={<AddIcon />} onClick={() => { navigate('/Expances/add') }}>{t('Add')}</Button> */}
      <GlobalBoxContainer>
      <ReceivableDetails title='Expance Information' data={deleteNullValues(getExpanceDetails(Expances)) }  />
        {Expances?.transactions?.length>0?
        <div>
    <h1 style={{color:"#535AC8"}} align="center" >{t('Transactions')}</h1>

        <DataGridTable total={total} paginationModel={paginationModel} setPaginationModel={setPaginationModel} rows={getExpanceMapper(Expances?.transactions)} columns={getExpanceHeaderMapper(Expances?.transactions)} />
        </div>:''
        }

{Expances?.receivable_details?.length>0?
        <div>
    <h1 style={{color:"#535AC8"}} align="center" >{t('Receivable Details')}</h1>

        <DataGridTable rows={getExpanceMapper(Expances?.receivable_details)} columns={getExpanceHeaderMapper(Expances?.receivable_details)} />
        </div>:""
        }
      </GlobalBoxContainer>
    </Box>
  );
};

export default Expances;