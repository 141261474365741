import { Stack, Container, Typography, CircularProgress, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import FullWidthButton from "../../components/FullWidthButton";
import TextInput from "../../components/TextInput";
import { ContentStyle, ParagraphErrorStyle, RootStyle, SectionStyle } from '../../Styles/Styles'
import LanguagePopover from '../../layout/dashboardLayout/LanguagePopover'
import style from './login.module.css'
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "./redux/slice";
import image from '../../assets/img/Travel and tourism background By Volyk _ TheHungryJPEG.jpeg'
import LoadingDialog from "../../components/LoadingDialog";

export default function Login({ errors, setErrors, submit }) {
    let { t } = useTranslation()
    const { logging } = useSelector(authSelector)
    const [login, setLogin] = useState({
        username: "",
        password: ""
    })
    const handleChange = (event) => {
        const { name, value } = event.target
        setLogin({ ...login, [name]: value })
        setErrors({ ...errors, [name]: "" })
    }
    useEffect(() => {
  
    
      return () => {
        if( document.getElementsByClassName('image').length>0)
        document.getElementsByClassName('image')[0].style.margin=0
      }
    }, [])
    

    return (
        <RootStyle   title='مكتب تاج الشام للسياحة والسفر'className='go'>
        
            {/* {logging && <LoadingDialog open={logging} />} */}
            <Grid  container spacing={0}>
                <Grid item xs={12} md={6}>
                    <SectionStyle className="image" sx={{ background: `url(https://i.pinimg.com/564x/b8/f8/fa/b8f8fa8d2eaa6518253f301eadac06b0.jpg)`,backgroundSize:'cover',backgroundPosition:'center center',backgroundRepeat:'no-repeat', display: { xs: "none", md: "flex" } }}></SectionStyle>
                </Grid>
                <Grid  item xs={12} md={6}>
                    <Container   maxWidth="sm">
                        <ContentStyle>
                            <Stack sx={{ mb: 5, textAlign: 'center' }}>
                                <Typography variant="h1" gutterBottom className={style.title}>
                                مكتب تاج الشام للسياحة والسفر
                                </Typography>
                            </Stack>
                            <Grid container spacing={2}>
                                <Grid  item xs={12}>
                                    
                                    <TextInput InputProps={{   disableUnderline: true,style: {border:'0'}}} name="username" label={t('Username')} handleChange={handleChange} type="text" />
                                    {errors['username'] && <ParagraphErrorStyle dir="rtl">{t(errors['username'])}</ParagraphErrorStyle>}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput InputProps={{   disableUnderline: true}} name="password" label={t('Password')} handleChange={handleChange} type="password" />
                                    {errors['password'] && <ParagraphErrorStyle dir="rtl">{t(errors['password'])}</ParagraphErrorStyle>}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <LanguagePopover />
                                </Grid>
                                </Grid>
                            <FullWidthButton label={logging ?<CircularProgress color="secondary" size={25} /> :t('Login')} onClick={() => submit(login)} />

                            </ContentStyle>
                    
                            </Container>
                            </Grid>
                            </Grid>
                            </RootStyle>
    )}
                       
 