import Joi from "joi-browser";

export const addSupplierSchema = {
    name: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a customer name ",
      };
    }),

    commission: Joi.any(),


    currency_id: Joi.any(),
    nationality: Joi.any(),


    account_type: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a account type ",
      };
    }),
};

export const editSupplierSchema = {
    name: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a customer name ",
      };
    }),

    commission: Joi.any(),
    currency_id: Joi.any(),
    currency: Joi.any(),

    nationality: Joi.any(),

    account_type: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a account type ",
      };
    }),
};
