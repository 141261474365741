import { createAsyncThunk } from "@reduxjs/toolkit";
import Transaction from "../TransactionApi";

export const getTransactions= createAsyncThunk(
  "transactionSlice/getTransactions",
  async ({ headers,params }, { rejectWithValue }) => {
    return await Transaction.getTransactions(headers,params, () => {
      return rejectWithValue();
    });
  }
);
export const deleteTransaction = createAsyncThunk(
  "transactionSlice/deleteTransaction",
  async ({ headers, id }, { rejectWithValue }) => {
    return await Transaction.deleteTransaction(headers, id, () => {
      throw new Error("Failed delete transaction");
    });
  }
);

export const addTransaction = createAsyncThunk('transactionSlice/addTransaction', async ({ headers,transaction }, { rejectWithValue }) => {
  return await Transaction.addTransaction(headers, transaction,()=>{
    throw new Error("Failed store transaction")});
})

export const getTransaction = createAsyncThunk(
  "transactionSlice/getTransaction",
  async ({ headers,id }, { rejectWithValue }) => {
    return await Transaction.getTransaction(headers,id, () => {
      return rejectWithValue();
    });
  }
);

export const editTransaction = createAsyncThunk('transactionSlice/editTransaction', async ({ headers,transaction,id }, { rejectWithValue }) => {
  return await Transaction.editTransaction(headers, transaction,id,()=>{
    throw new Error("Failed edit transaction")});
})