import { styled } from "@mui/material/styles";

import { Card, Stack, Container, Typography, Box } from "@mui/material";
import { Page } from "../components";


export const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

export const SectionStyle = styled("div")(({ theme }) => ({
  width: "100%",
  // maxWidth: "30%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  // margin: theme.spacing(2, 10, 6, 2),
  height:'100vh',
  margin:0
}));

export const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
 
}));
export const ParagraphErrorStyle = styled("p")(({ theme }) => ({
  color: "#d32f2f",
  margin:0,
  marginRight: "2%",
  
  fontWeight:600,
  // marginTop: "10px",
}));

export const GlobalBoxContainer = styled(Box)(({ theme }) => ({
  width:'100%',
  "& .MuiDataGrid-root": {
    border: "none",
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "none",
    fontSize: "14px",
    padding: "10px",
    direction:'rtl',
  
  },
  "& .name-column--cell": {
    color: "#94e2cd",
    fontWeight: "bold",
  },
  "& .MuiDataGrid-columnHeaders": {
    background: theme.palette.mode === "light" ? "linear-gradient(to bottom, #4FA1CF, #4FAcdd)" : "#242424",
    borderBottom: "none",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px",
  },
  "& .MuiDataGrid-main": {
    paddingTop: "20px",
    paddingBottom:'20px'
  },
  "& .MuiDataGrid-virtualScroller": {
    backgroundColor: theme.palette.mode === "light" ? "#f0f0ff" : "#1C1C1C",
    // direction:'ltr',
    color: theme.palette.mode === "light" ? "#000" : "#fff",
    width:'100%',
    // fontWeight: "bold",
    // minHeight:'400px',
    fontSize: "14px",
    padding: "10px",
    "&:hover": {
      // backgroundColor: theme.palette.mode === "light" ? "#D3D3D3" : "#2B2B2B",
    },
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "none",
    color: "#000",
    fontWeight:'700',
    // background: theme.palette.mode === "light" ? " linear-gradient(to bottom, #5DADE2, #80C1FF)" : "#242424",
    padding: "10px",
  },

  "& .MuiDataGrid-columnHeaderTitleContainer": {
    direction:'rtl',
    marginRight:'5%'
  },

 
  "& .MuiTablePagination-root": {
    color: "#000",
    fontWeight:'700',
    fontSize:'16px',
    margin:'auto'
  },
  "& .MuiCheckbox-root": {
    color: "#b7ebde !important",
  },
}));
