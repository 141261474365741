import { createAsyncThunk } from "@reduxjs/toolkit";
import GeneralDaily from "../GeneralDailyApi";

export const getGeneralDailys= createAsyncThunk(
  "generalDailySlice/getGeneralDailys",
  async ({ headers,params }, { rejectWithValue }) => {
    return await GeneralDaily.getGeneralDailys(headers,params, () => {
      return rejectWithValue();
    });
  }
);
export const deleteGeneralDaily = createAsyncThunk(
  "generalDailySlice/deleteGeneralDaily",
  async ({ headers, id }, { rejectWithValue }) => {
    return await GeneralDaily.deleteGeneralDaily(headers, id, () => {
      throw new Error("Failed delete generalDaily");
    });
  }
);

export const addGeneralDaily = createAsyncThunk('generalDailySlice/addGeneralDaily', async ({ headers,generalDaily }, { rejectWithValue }) => {
  return await GeneralDaily.addGeneralDaily(headers, generalDaily,()=>{
    throw new Error("Failed store generalDaily")});
})

export const migrateGeneralDaily = createAsyncThunk('generalDailySlice/migrateGeneralDaily', async ({ headers }, { rejectWithValue }) => {
  return await GeneralDaily.migrateGeneralDaily(headers, {},()=>{
    throw new Error("Failed migrate generalDaily")});
})

export const getGeneralDaily = createAsyncThunk(
  "generalDailySlice/getGeneralDaily",
  async ({ headers,id }, { rejectWithValue }) => {
    return await GeneralDaily.getGeneralDaily(headers,id, () => {
      return rejectWithValue();
    });
  }
);

export const getCurrentGeneralDaily = createAsyncThunk(
  "generalDailySlice/getCurrentGeneralDaily",
  async ({ headers,params }, { rejectWithValue }) => {
    return await GeneralDaily.getCurrentGeneralDaily(headers,params, () => {

      throw new Error("Failed get generalDaily")

    });
  }
);

export const editGeneralDaily = createAsyncThunk('generalDailySlice/editGeneralDaily', async ({ headers,generalDaily,id }, { rejectWithValue }) => {
  return await GeneralDaily.editGeneralDaily(headers, generalDaily,id,()=>{
    throw new Error("Failed edit generalDaily")});
})