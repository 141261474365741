import React from 'react';
import { Card, CardContent, Button, DialogActions, Grid, Typography, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { t } from 'i18next';

const CustomerReceivableDetailsDialog = ({ tickets, setOpen, open }) => {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: '3rem', fontWeight: 'bold', py: '1.5rem', textAlign: 'center' }}>{t("Ticket Details")}</DialogTitle>
      <DialogContent >
        <Cards tickets={tickets} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: '1.5rem' }}>
        <Button variant="contained" onClick={handleClose} sx={{ fontSize: '0.8rem', px: '1.2rem', py: '0.6rem' }}>{t("Close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

const Cards = ({ tickets }) => {
  return (
    <Grid sx={{ width: '100%', mt: '1.5rem' }} container spacing={4}>
      {tickets.map((ticket) => (
        <Grid item  xs={12} md={6} key={ticket.ticket_id}>
<Card sx={{
height: '100%',
minWidth:'400px',
display: 'flex',
flexDirection: 'column',
boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
borderRadius: '1rem',
direction:'rtl'
}}>
<Box sx={{
backgroundColor: 'darkblue',
display: 'flex',
justifyContent: 'center',
alignItems: 'center',
height: '5rem',
borderTopLeftRadius: '1rem',
borderTopRightRadius: '1rem',
}}>
<Typography sx={{
color: 'white',
fontSize: '1.5rem',
fontWeight: 'bold',
}}>
{t("Ticket Details")}
</Typography>
</Box>
<CardContent sx={{
flexGrow: 1,
padding: '1.5rem',
display: 'flex',
flexDirection: 'column',
justifyContent: 'space-between'
}}>

<Box sx={{
display: 'flex',
flexDirection: 'column',
marginBottom: '0.6rem',
}}>
<Typography sx={{
fontWeight: 'bold',
fontSize: '1.5rem',
marginBottom: '0.5rem',
}}>
{t("Customer Name")}
</Typography>
<Typography sx={{
fontSize: '1rem',
}}>
{ticket.customer_name}
</Typography>
</Box>
<Box sx={{
display: 'flex',
flexDirection: 'column',
marginBottom: '0.6rem',
}}>
<Typography sx={{
fontWeight: 'bold',
fontSize: '1.5rem',
marginBottom: '0.5rem',
}}>
{t("Rest Amount")}
</Typography>
<Typography sx={{
fontSize: '1rem',
}}>
{ticket.rest_amount+" "+ticket.currency} 
</Typography>
</Box>

<Box sx={{
display: 'flex',
flexDirection: 'column',
marginBottom: '0.6rem',
}}>

</Box>
<Box sx={{
display: 'flex',
flexDirection: 'column',
marginBottom: '0.6rem',
}}>
<Typography sx={{
fontWeight: 'bold',
fontSize: '1.5rem',
marginBottom: '0.5rem',
}}>
{t("Ticket Number")}
</Typography>
<Typography sx={{
fontSize: '1rem',
}}>
{ticket.ticket_number}
</Typography>
</Box>
<Box sx={{
display: 'flex',
flexDirection: 'column',
marginBottom: '0.6rem',
}}>
<Typography sx={{
fontWeight: 'bold',
fontSize: '1.5rem',
marginBottom: '0.5rem',
}}>
{t("Travel")}
</Typography>
<Typography sx={{
fontSize: '1rem',
}}>
{ticket.travel}
</Typography>
</Box>
</CardContent>
</Card>
        </Grid>
      ))}
    </Grid>

  );
};

export default CustomerReceivableDetailsDialog;