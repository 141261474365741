import { Component } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useErrorReader from "../hooks/useErrorReader";
import Toast from "./Toast";

function clearAllCookies() {
  var cookies = document.cookie.split(';');

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf('=');
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
}
class HandleError   {
     static email;
     static activate
     static auth
    static handleResponseError =  async(result,fail) => {
        //console.log(result);
      if (result.status == 422 ) {
        const list= useErrorReader(result.data.errors)
        //console.log(list);
        list.map((item)=>{
          Toast.showErrorToast(item[0],item[0]+Math.random())
        })
        fail()
      }
      else if (result.status == 400 ) { 
        Toast.showErrorToast(result.data.message)

        //console.log(fail);
        fail()
      
      }
      else if (result.status == 404 ) {
        //console.log(result); 
        Toast.showErrorToast(result.data.message)

        fail()
      }
      else if(result.status===401){
        //console.log(result);
        Toast.showErrorToast(result?.data?.message)

       
        fail()
        
        window.location='/auth/login'
        clearAllCookies()

      }

      else if(result.status===403){
        Toast.showErrorToast(result?.data?.message)
        fail()
        window.location='/auth/login'
        clearAllCookies()
      }
      else if(result.status>=500)
      {
        Toast.showErrorToast(result.data.message)
         fail()
      }
      // else if (result.status > 404&&result.status<500) {
      //   //console.log(result);
      //   toast(result.data.message, {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   });
      //   return false

      // }
     
    }
    static handleError=(result,success,fail)=>{
     
        if(result.status===200||result.status===201){
           return success()
        }
        else if (result.data) {
            /*return */
            //console.log(result);
            if(result.error?.message==='Network Error')
            {
              
              Toast.showErrorToast('Network Error')
               fail()
            }
            return this.handleResponseError({
              data: result.data,
              status: result.status,
              headers: result.headers,
            },fail);
          } else if (result.error.request) {
            Toast.showErrorToast('something wrong was happened')
            fail()

          } else {
            Toast.showErrorToast('something wrong was happened')
            fail()
          }
    }
}
export default HandleError;