export const ADD_GENERALDAILY_FORM =
  [
    {
      feild:'textField',
      type: "text",
      label: "Name",
      name: "name",
      grid: "2",
    },
    {
      feild:'textField',
      type: "number",
      label: "Year",
      name: "year",
      grid: "2",
    },
    {
      feild:'textField',
      type: "date",
      label: "Start date",
      name: "start_date",
      grid: "2",
    },
  ];

  export const EDIT_GENERALDAILY_FORM =
  [
    {
      feild:'textField',
      type: "text",
      label: "Name",
      name: "name",
      grid: "2",
    },
    {
      feild:'textField',
      type: "number",
      label: "Year",
      name: "year",
      grid: "2",
    },
    {
      feild:'textField',
      type: "date",
      label: "Start date",
      name: "start_date",
      grid: "2",
    },
    {
      feild:'textField',
      type: "date",
      label: "End date",
      name: "end_date",
      grid: "2",
    },
  ];

  export const GENERAL_DAILY_FILTER_FORM=[
    {
      feild:'textField',
      type: "date",
      label: "Start date",
      name: "min_date",
      grid: "2",
    },

    {
      feild:'textField',
      type: "date",
      label: "End date",
      name: "max_date",
      grid: "2",
    },
    
  ]