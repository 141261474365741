import Joi from "joi-browser";

export const editPasswordSchema = {
    old_password: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a old password ",
      };
    }),

    new_password: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a new password ",
      };
    }),

    new_password_confirmation: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a new password confirmation ",
      };
    }),

    
};