import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { t } from 'i18next';
import Form from './Form';
import TextInput from './TextInput';
import { Box, useMediaQuery } from '@mui/material';

const ConfirmationDialgoForm = ({ open, onClose,onOk, data,form,setValue,errors,handleChange }) => {
  
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleClose = () => {

    onClose();
  };

  const handleOk = () => {

    onOk();
  };

  //console.log(keyValueObject);
  return (
    <Dialog fullWidth  open={open} onClose={handleClose}>
      <DialogTitle sx={{textAlign:'center',fontSize:'40px',fontWeight:'bold'}}>تاكيد العملية</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{direction:'rtl',fontSize:'22px'}}>
          هل انت متاكد من تنفيذ العملية
        </DialogContentText>
        {/* <Form
          
          setValue={setValue}

          errors={errors}
          data={data}
          handleChange={handleChange}

          form={form}
        /> */}
         <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        width="100% "
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
            <div style={{ gridColumn: "span "+2 }}>

        <TextInput name='dollar_balance' handleChange={handleChange} value={data['dollar_balance'] } label='Dollar Balance'/>
        </div>
        <div style={{ gridColumn: "span "+2 }}>

        <TextInput name='syrian_balance' handleChange={handleChange} value={data['syrian_balance'] } label='Syrian Balance'/>

        </div>
        </Box>

        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("Close")}
        </Button>
        <Button onClick={handleOk} color="primary">
          {("OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialgoForm;
