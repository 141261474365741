export const ADD_TRANSACTION_FORM =
  [
    {
      feild:'switch',
      type: "checkbox",
      label: "Reverse transaction",
      name: "reverse_transaction",
      grid: "2",
      disabled:false
    },

    {
      feild:'switch',
      type: "checkbox",
      label: "Bank transfer",
      name: "bank_transfer",
      grid: "2",
      disabled:false
    },
    {
      feild:'select',
      type: "text",
      label: "Account type",
      name: "account_type",
      grid: "4",
    },

    {
      feild:'autoComplete',
      type: "autoComplete",
      label: "Account",
      name: "account_id",
      grid: "4",
    },

    {
      feild:'textField',
      type: "text",
      label: "pay price in syria",
      name: "pay_price_sy",
      grid: "2",
    },

    {
      feild:'textField',
      type: "text",
      label: "pay price in dollar",
      name: "pay_price_dollar",
      grid: "2",
    },

    {
      feild:'textField',
      type: "text",
      label: "description",
      name: "description",
      grid: "4",
      multiline:true,
      rows:4
    },

    {
      feild:'textField',
      type: "date",
      label: "Date",
      name: "date",
      grid: "2",
    },

   
    
  ];

  export const ADD_TRANSACTION_FORM_WITHOUT_ACCOUNT =
  [
    {
      feild:'switch',
      type: "checkbox",
      label: "Reverse transaction",
      name: "reverse_transaction",
      grid: "2",
      disabled:false
    },

    // {
    //   feild:'switch',
    //   type: "checkbox",
    //   label: "Bank transfer",
    //   name: "bank_transfer",
    //   grid: "2",
    //   disabled:false
    // },
    {
      feild:'select',
      type: "text",
      label: "Account type",
      name: "account_type",
      grid: "4",
    },

    // {
    //   feild:'autoComplete',
    //   type: "autoComplete",
    //   label: "Account",
    //   name: "account_id",
    //   grid: "4",
    // },

    {
      feild:'textField',
      type: "text",
      label: "pay price in syria",
      name: "pay_price_sy",
      grid: "2",
    },

    {
      feild:'textField',
      type: "text",
      label: "pay price in dollar",
      name: "pay_price_dollar",
      grid: "2",
    },

    {
      feild:'textField',
      type: "text",
      label: "description",
      name: "description",
      grid: "4",
      multiline:true,
      rows:4
    },

    {
      feild:'textField',
      type: "date",
      label: "Date",
      name: "date",
      grid: "2",
    },

   
    
  ];

  export const EDIT_TRANSACTION_FORM =
  [
    {
      feild:'Select',
      type: "text",
      label: "Account type",
      name: "account_type",
      grid: "2",
    },

    {
      feild:'autoComplete',
      type: "autoComplete",
      label: "Account",
      name: "account_id",
      grid: "2",
    },

    {
      feild:'textField',
      type: "text",
      label: "pay price in syria",
      name: "pay_price_sy",
      grid: "2",
    },

    {
      feild:'textField',
      type: "text",
      label: "pay price in dollar",
      name: "pay_price_dollar",
      grid: "2",
    },

    {
      feild:'textField',
      type: "text",
      label: "description",
      name: "description",
      grid: "2",
      multiline:true,
      rows:4
    },

    {
      feild:'textField',
      type: "date",
      label: "Date",
      name: "date",
      grid: "2",
    },
  ];