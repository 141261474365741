import i18n from "../../localization"

export const getFundHeaderMapper=(data)=>{
    const fundHeaderMapper=[]
    const fundDataMappar=getFundMapper(data)
    const keys=fundDataMappar.length>0? Object.keys(fundDataMappar[0]):[]
    keys.map((key)=>{
        let words = key.split("_");
        let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        let result = capitalizedWords.join(" ");
        fundHeaderMapper.push({
            field:key,
            headerName:i18n.t(result),
                  width: (key=='name'||key=="account_name")?200:100,
            outerHeight:200,
            innerHeight:200,

            
        })
    })
    return fundHeaderMapper
}

export const getFundMapper=(data)=>{
    const fundMapperData=[]
    //console.log(data);
    data?.map((item)=>{
        fundMapperData.push({
            ...item
        })
    })

    return fundMapperData
}



export const getOneFundMapper=(data)=>{
   
    const oneFundMapperData={
           
        }
  
    return oneFundMapperData
}

export const getFundDetails = (fundDetails) => {
    return {
      total_amount_received_sy:fundDetails.total_amount_received_sy,
      total_amount_received_dollar:fundDetails.total_amount_received_dollar,
      expenses_dollar: fundDetails.expenses_dollar,
      expenses_sy: fundDetails.expenses_sy,
      profits_dollar: fundDetails.profits_dollar,
      profits_sy: fundDetails.profits_sy,
      
    //   rest_amount_receivable_dollar:fundDetails.rest_amount_receivable_dollar,
    //   rest_amount_receivable_on_fund_dollar:fundDetails.rest_amount_receivable_on_fund_dollar,
    //   rest_amount_receivable_sy:fundDetails.rest_amount_receivable_sy,
    //   rest_amount_receivable_on_fund_sy:fundDetails.rest_amount_receivable_on_fund_sy,
      

  
  
    }
  };
  