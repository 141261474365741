import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from '../features/auth/redux/slice';
import { customerSlice } from '../features/customer/redux/slice';
import { companySlice } from '../features/company/redux/slice';
import { supplierSlice } from '../features/supplier/redux/slice';
import { employeeSlice } from '../features/employee/redux/slice';
import { ticketSlice } from '../features/ticket/redux/slice';
import { exchagneSlice } from '../features/exchange/redux/slice';
import { generalDailySlice } from '../features/generalDaily/redux/slice';
import { transactionSlice } from '../features/transaction/redux/slice';
import { fundSlice } from '../features/fund/redux/slice';
import { ExpanceSlice } from '../features/expance/redux/slice';
import { RecivableSlice } from '../features/recivable/redux/slice';
import { constantSlice } from '../features/constant/redux/slice';


export default configureStore({
  reducer: {
   auth:authSlice.reducer,
   customer:customerSlice.reducer,
   company:companySlice.reducer,
   supplier:supplierSlice.reducer,
   employee:employeeSlice.reducer,
   ticket:ticketSlice.reducer,
   exchange:exchagneSlice.reducer,
   generalDaily:generalDailySlice.reducer,
   transaction:transactionSlice.reducer,
   fund:fundSlice.reducer,
   constant:constantSlice.reducer,

   Expance:ExpanceSlice.reducer,
   Recivable:RecivableSlice.reducer
   
  },
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
});