import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from "react";
import { useTranslation } from "react-i18next";
import GeneralLabel from "./GeneralLabel";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const CustomExpandMoreIcon = () => (
  <ExpandMoreIcon style={{ color: '#c7c8c8',marginRight:'2%'}} />
);
function GeneralSelect({ item, data, handleChange, selectData, multiple,index,itemName }) {
  const { t } = useTranslation();
  //console.log(selectData);
  return (
    <div>
      {/* <GeneralLabel >
        {item.label}
      </GeneralLabel> */}
      <FormControl sx={{width:'100%'}}>
      <InputLabel sx={{fontWeight:'bold',color:'#c7c8c8',fontSize:'16px', left:'inherit !important',
               right:'1.75rem !important',
               transformOrigin:'right !important'}}>{t(item.label)}</InputLabel>
      <Select
  name={item.name}
  variant="filled"
  fullWidth
  // placeholder="choose food"
  
  multiple={multiple ? true : false}
  value={data&&data[item.name]?data[item.name]:[]}
  onChange={(event)=>{handleChange(event,itemName,index)}}

  MenuProps={MenuProps}
  disableUnderline
  sx={{ gridColumn: "span " + item.grid,backgroundColor: "#F7F8F8" ,borderRadius:'12px',border:'none'}}
  IconComponent={CustomExpandMoreIcon}
>
      <MenuItem style={{fontWeight:'bold'}}  disabled>{t(item.label)}</MenuItem>
      {selectData[item.name]?.map((subItem) => (
        <MenuItem style={{fontWeight:'bold'}} key={subItem.value} value={subItem.value}>
          {t(subItem.value)}
        </MenuItem>
      ))}
    </Select>
      </FormControl>
      
    </div>
  );
}

export default GeneralSelect;
