import React, {  useEffect, useState } from "react";
import useHeaders from "../../hooks/useHeaders";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmployee,deleteEmployee,editEmployee,getEmployee,getEmployees, getRoles
} from "./redux/service";
import { employeeSelector,employeeSlice} from "./redux/slice";
import { useSidebarContext } from "../../components/global/sidebar/sidebarContext";
import Employees from "./Employees";
import AddEmployee from "./AddEmployee";
import EditEmployee from "./EditEmployee";
import { LoadingDialog } from "../../components";
import Validation from "../../services/Validation";
import { addEmployeeSchema, editEmployeeSchema } from "./validationSchema";
import { ToastContainer } from "react-toastify";

function EmployeesController({ child }) {
  const { setSelected } = useSidebarContext();
  const { logging,employee:employeeSel } = useSelector(employeeSelector);
  const navigate = useNavigate();
  const headers = useHeaders();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({})
  const _getEmployees = async (params) => {
    dispatch(getEmployees({ headers,params }))
  };

  const _getRoles = async (params) => {
    dispatch(getRoles({ headers,params }))
  };
  const _deleteEmployee = async (id) => {
    dispatch(deleteEmployee({ headers, id })).then((res) => {
      if (res.error) {
      } else {
        dispatch(employeeSlice.actions.delete(id));
      }
    });
  };
  const _addEmployee = async (employee) => {
  
     const employeeValidationErrors = Validation.validation(addEmployeeSchema, employee);
     if (employeeValidationErrors) {
      setErrors(employeeValidationErrors);
    } else {
    const _employee={
      ...employee
     }
       dispatch(addEmployee({ headers,employee:_employee })).then((res) => {
         if (res.error) {
         } else {
           navigate("/employees");
         }
       });
      }
  
    
  };



  const _getEmployee = async (id) => {
    dispatch(getEmployee({ headers, id }));
  };
  const _editEmployee = async (id, employee) => {
    const employeeValidationErrors = Validation.validation(editEmployeeSchema, employee);
    if (employeeValidationErrors) {
     setErrors(employeeValidationErrors);
   } else {
    const _employee={...employee}
      if(_employee.username==employeeSel.username)
      delete _employee['username']
      dispatch(editEmployee({ headers, employee: _employee,id})).then((res) => {
        if (res.error) {
        } else {
          navigate("/employees");
        }
      });
    }
    }


  useEffect(() => {
    setSelected&& setSelected("Employees");
  }, []);

  const initChild = () => {
    switch (child) {
      case "employees": {
        return <Employees deleteEmployee={_deleteEmployee} getEmployees={_getEmployees}/>;
      }
      case "add-employee":
        return (
          <AddEmployee
        getRoles={_getRoles}
        addEmployee={_addEmployee}
        errors={errors}
        setErrors={setErrors}
          />
        );

      case "edit-employee":
        return (
          <EditEmployee
          getRoles={_getRoles}
          editEmployee={_editEmployee}
          getEmployee={_getEmployee}
          errors={errors}
          setErrors={setErrors}
          />
        );

      default:
    }
  };
  return (
    <div>
      <ToastContainer/>
      {logging && <LoadingDialog open={logging} />}

      {initChild()}
    </div>
  );
}

export default EmployeesController;
