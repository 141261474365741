import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from "react-i18next";
import { GlobalBoxContainer } from "../../Styles/Styles";
import { Actions, DataGridTable, Header } from "../../components";
import { getCustomerHeaderMapper, getCustomerMapper } from "./mapper";
import { customerSelector } from "./redux/slice";
import SearchField from "../../components/SearchField";

const Customers = ({ getCustomers, deleteCustomer }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { customers,total } = useSelector(customerSelector)
  const [paginationModel, setPaginationModel] = useState({
    page:0,
    pageSize:100
  })
  const [search, setSearch] = useState('')


  const getSearchResults=()=>{

    setPaginationModel({...paginationModel,page:0})
    getCustomers({limit:paginationModel.pageSize,page:paginationModel.page+1,search})

  }

  // useEffect(() => {
  //   getCustomers({account_type:'Customer',limit:paginationModel.pageSize,page:paginationModel.page+1})
  // }, [paginationModel.page,paginationModel.pageSize])
  


  useEffect(() => {
    if(search)
    {
      getCustomers({account_type:'Customer',limit:paginationModel.pageSize,page:1,search})

    }
    else
    getCustomers({account_type:'Customer',limit:paginationModel.pageSize,page:paginationModel.page+1})

  }, [search,paginationModel.page,paginationModel.pageSize])

  useEffect(() => {
   setPaginationModel({...paginationModel,page:0})
  }, [search])
  

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header viewAll={true} title="Customers" subtitle={"Welcome to your " + "customers"} />
      </Box>
      <Button sx={{ bgcolor: '#0066cc',marginRight:'2%',top:'10px' }} variant='contained' startIcon={<AddIcon />} onClick={() => { navigate('/customers/add') }}>{t('Add')}</Button>
      <SearchField getSearchResults={getSearchResults}  label={"Search Customer name"} placeholder={'Enter Customer name'} setValue={setSearch} value={search} />
      
      <GlobalBoxContainer>
        <DataGridTable total={total} paginationModel={paginationModel} setPaginationModel={setPaginationModel} rows={getCustomerMapper(customers)} columns={getCustomerHeaderMapper(customers).concat(Actions(deleteCustomer, "edit/","account-statement/")).reverse()} />
      </GlobalBoxContainer>
    </Box>
  );
};

export default Customers;