import { createSlice } from "@reduxjs/toolkit"
import { addRecivable, deleteRecivable, editRecivable, getRecivables, getRecivable } from "./service"
import { fulfilledReducer, pendingReducer, rejectedReducer } from "../../../redux/globalReducer"
const initialState = {
    status: '',
    logging: false,
    Recivables:[],
    transaction_total:0,
    receivable_details_total:0,
    Recivable:{},
}
export const RecivableSlice = createSlice({
    name: 'Recivable',
    initialState,
    reducers: {
        delete(state,action){
            return {
                ...state,Recivables:[...state.Recivables].filter((Recivable)=>Recivable.id!=action.payload)
            }
       
        }
    },
    extraReducers: {
        [getRecivables.pending]: pendingReducer,
        [getRecivables.fulfilled]: (state, { payload }) => {
            state.Recivables= payload?payload:[]
            state.transaction_total=payload?payload.transaction_total:state.transaction_total
            state.receivable_details_total=payload?payload.receivable_details_total:state.receivable_details_total

            state.status = 'fulfilled'
            state.logging = false
        },
        [getRecivables.rejected]: rejectedReducer,

        [deleteRecivable.pending]: pendingReducer,
        [deleteRecivable.fulfilled]: fulfilledReducer,
        [deleteRecivable.rejected]: rejectedReducer,

        
        [addRecivable.pending]: pendingReducer,
        [addRecivable.fulfilled]: fulfilledReducer,
        [addRecivable.rejected]: rejectedReducer,

        [getRecivable.pending]: pendingReducer,
        [getRecivable.fulfilled]: (state, { payload }) => {
           
            state.Recivable= payload?payload:{}
            state.status = 'fulfilled'
            state.logging = false
        },
        [getRecivable.rejected]: pendingReducer,

        [editRecivable.pending]: pendingReducer,
        [editRecivable.fulfilled]: fulfilledReducer,
        [editRecivable.rejected]: rejectedReducer,
 
    }
})

export const RecivableSelector = (state) => state.Recivable;