import React, {  useEffect, useState } from "react";
import useHeaders from "../../hooks/useHeaders";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addRecivable,deleteRecivable,editRecivable,getRecivable,getRecivables
} from "./redux/service";
import { RecivableSelector,RecivableSlice} from "./redux/slice";
import { useSidebarContext } from "../../components/global/sidebar/sidebarContext";
import Recivables from "./Recivables";
import AddRecivable from "./AddRecivable";
import EditRecivable from "./EditRecivable";
import { LoadingDialog } from "../../components";
import Validation from "../../services/Validation";
import { addRecivableSchema, editRecivableSchema } from "./validationSchema";
import { ToastContainer } from "react-toastify";
import { getCustomers } from "../customer/redux/service";
import { customerSelector } from "../customer/redux/slice";

function RecivablesController({ child }) {
  const { setSelected } = useSidebarContext();
  const { logging } = useSelector(RecivableSelector);
  const navigate = useNavigate();
  const headers = useHeaders();
  const dispatch = useDispatch();
  const {customers}=useSelector(customerSelector)
  const [errors, setErrors] = useState({})
  const _getRecivables = async (params) => {
    dispatch(getRecivables({ headers,params }))
  };
  const _deleteRecivable = async (id) => {
    dispatch(deleteRecivable({ headers, id })).then((res) => {
      if (res.error) {
      } else {
        dispatch(RecivableSlice.actions.delete(id));
      }
    });
  };
  const _addRecivable = async (Recivable) => {
  
     const RecivableValidationErrors = Validation.validation(addRecivableSchema, Recivable);
     if (RecivableValidationErrors) {
      setErrors(RecivableValidationErrors);
    } else {
      //console.log(customers);
    let _Recivable={
      ...Recivable,customer_id:customers.filter((customer)=>(customer.name==Recivable.customer_id))[0].id
     }
     _Recivable = Object.fromEntries(
      Object.entries(_Recivable).reduce((acc, [key, value]) => {
        if (value&&value !== null && value !== " ") {
          acc.push([key, value]);
        }
        return acc;
      }, [])
    );
       dispatch(addRecivable({ headers,recivable:_Recivable })).then((res) => {
         if (res.error) {
         } else {
           navigate("/Recivables");
         }
       });
      }
  
    
  };



  const _getRecivable = async (id) => {
    dispatch(getRecivable({ headers, id }));
  };
  const _editRecivable = async (id, Recivable) => {
    const RecivableValidationErrors = Validation.validation(editRecivableSchema, Recivable);
    if (RecivableValidationErrors) {
     setErrors(RecivableValidationErrors);
   } else {
    const _Recivable={...Recivable}
      dispatch(editRecivable({ headers, Recivable: _Recivable,id})).then((res) => {
        if (res.error) {
        } else {
          navigate("/Recivables");
        }
      });
    }
    }

    const _getCustomers=(params)=>{
      dispatch(getCustomers({headers,params}))
    }


  useEffect(() => {
    setSelected&& setSelected("Recivables");
  }, []);

  const initChild = () => {
    switch (child) {
      case "Recivables": {
        return <Recivables deleteRecivable={_deleteRecivable} getRecivables={_getRecivables}/>;
      }
      case "add-Recivable":
        return (
          <AddRecivable
        addRecivable={_addRecivable}
        getCustomers={_getCustomers}
        errors={errors}
        setErrors={setErrors}
          />
        );

      case "edit-Recivable":
        return (
          <EditRecivable
          editRecivable={_editRecivable}
          getRecivable={_getRecivable}
          errors={errors}
          setErrors={setErrors}
          />
        );

      default:
    }
  };
  return (
    <div>
      <ToastContainer/>

      {logging && <LoadingDialog open={logging} />}

      {initChild()}
    </div>
  );
}

export default RecivablesController;
