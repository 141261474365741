import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { t } from 'i18next';
import { Form } from '../../components';
import { REFUND_TICKET_FORM, VOID_TICKET_FORM } from './form';
import { Box } from '@mui/material';

const RefundTicket = ({ open, onClose,onOk,id }) => {
  const [refund, setRefund] = useState({
    id,
    customer_fine:'',
    supplier_fine:''
  })

  const handleClose = () => {

    onClose();
  };

  const handleOk = () => {

    onOk(refund);
  };

  const handleChange=(event)=>{
    const {name,value}=event.target
    setRefund({...refund,[name]:value})
  }

  
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{textAlign:'right'}}>{t('Refund Ticket')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{direction:'rtl'}}>
          هل انت متاكد من تنفيذ العملية
        </DialogContentText>
        <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        width="100% "
        sx={{
          "& > div": { gridColumn:  "span 4" },
        }}
      >
        <Form
          errors={{}}
          setValue={setRefund}
          data={refund}
          handleChange={handleChange}
          type="add"
          form={REFUND_TICKET_FORM}
        />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("Close")}
        </Button>
        <Button onClick={handleOk} color="primary">
          {("OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RefundTicket;
