import { createAsyncThunk } from "@reduxjs/toolkit";
import Recivable from "../RecivableApi";

export const getRecivables= createAsyncThunk(
  "RecivableSlice/getRecivables",
  async ({ headers,params }, { rejectWithValue }) => {
    return await Recivable.getRecivables(headers,params, () => {
      return rejectWithValue();
    });
  }
);
export const deleteRecivable = createAsyncThunk(
  "RecivableSlice/deleteRecivable",
  async ({ headers, id }, { rejectWithValue }) => {
    return await Recivable.deleteRecivable(headers, id, () => {
      throw new Error("Failed delete Recivable");
    });
  }
);

export const addRecivable = createAsyncThunk('RecivableSlice/addRecivable', async ({ headers,recivable }, { rejectWithValue }) => {
  return await Recivable.addRecivable(headers, recivable,()=>{
    throw new Error("Failed store Recivable")});
})

export const getRecivable = createAsyncThunk(
  "RecivableSlice/getRecivable",
  async ({ headers,id }, { rejectWithValue }) => {
    return await Recivable.getRecivable(headers,id, () => {
      return rejectWithValue();
    });
  }
);

export const editRecivable = createAsyncThunk('RecivableSlice/editRecivable', async ({ headers,Recivable,id }, { rejectWithValue }) => {
  return await Recivable.editRecivable(headers, Recivable,id,()=>{
    throw new Error("Failed edit Recivable")});
})