import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useMediaQuery} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Header } from "../../components";
import { ADD_Recivable_FORM } from "./form";
import { getCustomerData } from "../../services/functions";
import { customerSelector } from "../customer/redux/slice";
import { useSelector } from "react-redux";

const AddRecivable = ({addRecivable,errors,setErrors,getCustomers}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {t}=useTranslation()
  const { customers } = useSelector(customerSelector);

  const [Recivable, setRecivable] = useState({
    customer_id:"",
    amount_sy:"",
    amount_dollar:"",
    description:"",

  })
  const [selectData, setSelectData] = useState({
    customer_name: [],
    supplier_id: [],
    company_id: [],
  });
   const [fieldsData, setFieldsData] = useState({
    customer_id:[],
  })
  
  const handleFormSubmit = () => {
    addRecivable(Recivable)
  };
  const handleChange=(event)=>{
      const {name,value}=event.target
      setRecivable({...Recivable,[name]:value})
          setErrors((prevErrors)=>(
        {...prevErrors,
        [name]:''
        }
    ))
  }

  const handleChangeSwitch=(event)=>{
    const {name,checked}=event.target
    setRecivable({...Recivable,[name]:checked})
    setErrors((prevErrors)=>(
      {...prevErrors,
      [name]:''
      }
  ))
}

const handleColor=(color)=>{
     setRecivable({...Recivable,color:color.hex})
        setErrors((prevErrors)=>(
      {...prevErrors,
      color:''
      }
  ))
}

const handleChangeField=(event,itemName,index)=>{
    const {name,value}=event.target
    //console.log(name,value,index);
    const fields=Recivable[itemName]
    fields[index][name]=value
    setRecivable({...Recivable,[itemName]:fields})
}
const addField=(name)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]+1
    }
))
    const fields=Recivable[name]
    fields.push({})
    setRecivable((prevRecivable)=>(
      {...prevRecivable,
      [name]:fields
      }
  ))

}

const deleteField=(name,index)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]-1
    }
))
    const fields=Recivable[name]
    fields.splice(index,1)
    setRecivable({...Recivable,[name]:fields})
}
useEffect(() => {
  
  setSelectData({
    customer_id: getCustomerData(customers),
  });
}, [customers]);
useEffect(() => {
  getCustomers({})
}, []);
  return (
    <Box m="20px">
      <Header title="Recivable" subtitle="a New Recivable" />
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
           
            <Form selectData={selectData} handleColor={handleColor} handleChangeSwitch={handleChangeSwitch}  errors={errors} data={Recivable}  handleChange={handleChange} type='add' form={ADD_Recivable_FORM}  />
            
          </Box>

          <Box display="flex" justifyContent="end" mt="20px">
            <Button onClick={()=>{handleFormSubmit()}} type="submit" color="secondary" variant="contained">
              {t("New Recivable")}
            </Button>
          </Box>
     
  
    </Box>
  );
};

export default AddRecivable;
