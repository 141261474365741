export const BALANCE_FORM =
  [
    {
      feild:'textField',
      type: "text",
      label: "Dollar Balance",
      name: "dollar_balance",
      grid: "2",
    },

    {
      feild:'textField',
      type: "text",
      label: "Syrian balance",
      name: "syrian_balance",
      grid: "2",
    },
  ];

  export const EDIT_FUND_FORM =
  [
    
  ];