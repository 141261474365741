import { createAsyncThunk } from "@reduxjs/toolkit";
import Exchange from "../ExchangeApi";

export const getExchange= createAsyncThunk(
  "exchangeSlice/getExchange",
  async ({ headers,params }, { rejectWithValue }) => {
    return await Exchange.getExchange(headers,params, () => {
      return rejectWithValue();
    });
  }
);






export const editExchange = createAsyncThunk('exchangeSlice/editExchange', async ({ headers,exchange }, { rejectWithValue }) => {
  return await Exchange.editExchange(headers, exchange,()=>{
    throw new Error("Failed edit exchange")});
})