export const  getPrivilageData=(data)=>{
    const privilageData=[]
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        privilageData.push({
            id:element.id,
            value:element.name
        })
        
    }
    return privilageData
}

export const  getRolesData=(data)=>{
    const rolesData=[]
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        rolesData.push({
            id:element,
            value:element
        })   
    }
    return rolesData
}

export const  getCompanyData=(data)=>{
    const CompanyData=[]
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        CompanyData.push({
            id:element.id,
            value:element.name
        })
        
    }
    return CompanyData
}

export const  getConstantData=(data)=>{
    const CompanyData=[]
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if(element.id!=2)
        CompanyData.push({
            id:element.id,
            value:element.name
        })
        
    }
    return CompanyData
}

export const  getCustomerData=(data)=>{
    const customerData=[]
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        customerData.push({
            id:element.id,
            value:element.name
        })
        
    }
    return customerData
}

export const  getTicketsNumbersData=(data)=>{
    const ticketsNumbersData=[]
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        element.ticket_number&& ticketsNumbersData.push({
            id:element.id,
            value:element.ticket_number
        })
        
    }
    //console.log();
    return ticketsNumbersData
}

export const  getSupplierData=(data)=>{
    const supplierData=[]
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        supplierData.push({
            id:element.id,
            value:element.name
        })
        
    }
    return supplierData
}

export function deleteNullValues(obj) {
    const newObject={}
    obj&&Object.keys(obj).forEach((key) => {
      if (obj[key] != null) {
        newObject[key]=obj[key]
      }
    });
    return newObject;
  }

  export function deleteEmptyValues(obj) {
    const newObject={}
    obj&&Object.keys(obj).forEach((key) => {
      if (obj[key] != null&&obj[key] != "") {
        newObject[key]=obj[key]
      }
    });
    return newObject;
  }


  export function getCurrentDate(){
    const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate
  }

  export function autoScroll() {
    var content = document.getElementById('dataGrid');

    // Set the initial scroll position
    var scrollPosition = 0;

    // Set the scroll speed (adjust as needed)
    var scrollSpeed = 2;
    // Increment the scroll position
    scrollPosition += scrollSpeed;

    // Apply the new scroll position
    content.scrollLeft = scrollPosition;

    // Check if reached the end, then reset the scroll position
    // //console.log(content.clientWidth);
    if (scrollPosition >= content.scrollWidth - content.clientWidth) {
        scrollPosition = 0;
    }

    // Schedule the next frame
    requestAnimationFrame(autoScroll);
}

export const handleScroll=()=>{
    console.log("dddddssss");
    if( document.getElementsByClassName('MuiDataGrid-virtualScroller').length>0)
    document.getElementsByClassName('MuiDataGrid-virtualScroller')?.[0].scrollBy({
     left:-5000,
    //  behavior:'smooth'
    })
}

