import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useMode } from '../theme';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useSidebarContext } from './global/sidebar/sidebarContext';

export default function DataGridTable({ rows, columns, pageSize, total, setPaginationModel, paginationModel }) {
  const handlePageChange = (page) => {
    //console.log(page);
    setPaginationModel(page);
  };

  const { sidebarWidth } = useSidebarContext();
  const [theme, colorMode] = useMode();
  const gridRef = useRef(null);

  // Effect to scroll to the right when columns or data change
  useEffect(() => {
    if( document.getElementsByClassName('MuiDataGrid-virtualScroller').length>0)
    document.getElementsByClassName('MuiDataGrid-virtualScroller')?.[0].scrollBy({
     left:5000,
    //  behavior:'smooth'
    })
 
   }, [rows])

  return (
    <Box sx={{ paddingTop: '20px' }} id="dataGrid">
      {columns.length > 0 && (
        <DataGrid
          ref={gridRef}
          paginationMode="server"
          paginationModel={paginationModel}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          disableSelectionOnClick
          
          components={{ Toolbar: GridToolbar }}
          experimentalFeatures={{ newEditingApi: true, }}
          rowCount={total}
          onPaginationModelChange={handlePageChange}
          sx={{ direction: 'ltr' }}
          showCellVerticalBorder
          showColumnVerticalBorder
          pageSizeOptions={[10,25,50,100]}
          style={{width:'auto'}}
          

        />
      )}
    </Box>
  );
}
