import { createAsyncThunk } from "@reduxjs/toolkit";
import Customer from "../CustomerApi";

export const getCustomers= createAsyncThunk(
  "customerSlice/getCustomers",
  async ({ headers,params }, { rejectWithValue }) => {
    return await Customer.getCustomers(headers,params, () => {
      return rejectWithValue();
    });
  }
);
export const deleteCustomer = createAsyncThunk(
  "customerSlice/deleteCustomer",
  async ({ headers, id }, { rejectWithValue }) => {
    return await Customer.deleteCustomer(headers, id, () => {
      throw new Error("Failed delete customer");
    });
  }
);

export const addCustomer = createAsyncThunk('customerSlice/addCustomer', async ({ headers,customer }, { rejectWithValue }) => {
  return await Customer.addCustomer(headers, customer,()=>{
    throw new Error("Failed store customer")});
})

export const getCustomer = createAsyncThunk(
  "customerSlice/getCustomer",
  async ({ headers,id }, { rejectWithValue }) => {
    return await Customer.getCustomer(headers,id, () => {
      return rejectWithValue();
    });
  }
);

export const getAccountStatment = createAsyncThunk(
  "customerSlice/getAccountStatment",
  async ({ headers,id ,params}, { rejectWithValue }) => {
    return await Customer.getAccountStatement(headers,id,params, () => {
      return rejectWithValue();
    });
  }
);

export const editCustomer = createAsyncThunk('customerSlice/editCustomer', async ({ headers,customer,id }, { rejectWithValue }) => {
  return await Customer.editCustomer(headers, customer,id,()=>{
    throw new Error("Failed edit customer")});
})