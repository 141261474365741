import { createAsyncThunk } from "@reduxjs/toolkit";
import Company from "../CompanyApi";

export const getCompanys= createAsyncThunk(
  "companySlice/getCompanys",
  async ({ headers,params }, { rejectWithValue }) => {
    return await Company.getCompanys(headers,params, () => {
      return rejectWithValue();
    });
  }
);
export const deleteCompany = createAsyncThunk(
  "companySlice/deleteCompany",
  async ({ headers, id }, { rejectWithValue }) => {
    return await Company.deleteCompany(headers, id, () => {
      throw new Error("Failed delete company");
    });
  }
);

export const addCompany = createAsyncThunk('companySlice/addCompany', async ({ headers,company }, { rejectWithValue }) => {
  return await Company.addCompany(headers, company,()=>{
    throw new Error("Failed store company")});
})

export const getCompany = createAsyncThunk(
  "companySlice/getCompany",
  async ({ headers,id }, { rejectWithValue }) => {
    return await Company.getCompany(headers,id, () => {
      return rejectWithValue();
    });
  }
);

export const editCompany = createAsyncThunk('companySlice/editCompany', async ({ headers,company,id }, { rejectWithValue }) => {
  return await Company.editCompany(headers, company,id,()=>{
    throw new Error("Failed edit company")});
})