import i18n from "../../localization";

export const getGeneralDailyHeaderMapper = (data) => {
  const generalDailyHeaderMapper = [];
  const generalDailyDataMappar = getGeneralDailyMapper(data);
  const keys =
    generalDailyDataMappar.length > 0
      ? Object.keys(generalDailyDataMappar[0])
      : [];
  keys.map((key) => {
    if(key!='file_path'){
      let words = key.split("_");
      let capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      let result = capitalizedWords.join(" ");
      generalDailyHeaderMapper.push({
        field: key,
        headerName: i18n.t(result),
              width: (key=='name'||key=="account_name")?200:100,
      });
    }
    
  });
  return generalDailyHeaderMapper;
};

export const getCurrentGeneralDailyHeaderMapper = (data) => {
  const generalDailyHeaderMapper = [];
  const generalDailyDataMappar = getCurrentGeneralDailyMapper(data);
  const keys =
    generalDailyDataMappar.length > 0
      ? Object.keys(generalDailyDataMappar[0])
      : [];
  keys.map((key) => {
    
    let words = key.split("_");
    let capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    let result = capitalizedWords.join(" ");
    generalDailyHeaderMapper.push({
      field: key,
      headerName: i18n.t(result),
            width: (key=='name'||key=="account_name")?200:100,
    });
  });
  return generalDailyHeaderMapper;
};

export const getGeneralDailyMapper = (data) => {
  const generalDailyMapperData = [];
  data.map((item) => {
    // delete item['file_path']
    // //console.log(item.file_path);
    generalDailyMapperData.push({
      id: item.id,
      name:item.name,
      year: item.year,
      start_date:item.start_date,
      end_date: item.end_date,
      status: item.status,
      created_at: item.created_at,
      file_path:item.file_path
    });
  });

  return generalDailyMapperData;
};

export const getCurrentGeneralDailyMapper = (data) => {
  const generalDailyMapperData = [];
  data.map((item) => {
    generalDailyMapperData.push({
      ...item,
    });
  });

  return generalDailyMapperData;
};

export const getOneGeneralDailyMapper = (data) => {
  //console.log(data);
const currentDate = new Date(data?.start_date?.split(' ')[0]);
const currentDate1 = new Date(data?.end_date?.split(' ')[0]);


const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const day = String(currentDate.getDate()).padStart(2, '0');

const year1 = currentDate1.getFullYear();
const month1 = String(currentDate1.getMonth() + 1).padStart(2, '0');
const day1 = String(currentDate1.getDate()).padStart(2, '0');

const formattedDate = `${year}-${month}-${day}`;
const formattedDate1 = `${year1}-${month1}-${day1}`;

  const oneGeneralDailyMapperData = {
    name:data?.name,
    year:data?.year,
    start_date:formattedDate,
    end_date:formattedDate1

  };

  return oneGeneralDailyMapperData;
};

export const getCurrentGeneralDailyDetails = (generalDaily) => {
  return {
    total_profit_dollar:generalDaily.total_profit_dollar,
    total_profit_sy:generalDaily.total_profit_sy,
 
  }
};
