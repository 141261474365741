import { createSlice } from "@reduxjs/toolkit"
import { addEmployee, deleteEmployee, editEmployee, getEmployees, getEmployee, getRoles } from "./service"
import { fulfilledReducer, pendingReducer, rejectedReducer } from "../../../redux/globalReducer"
const initialState = {
    status: '',
    logging: false,
    employees:[],
    roles:[],
    total:0,
    employee:{},
}
export const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        delete(state,action){
            return {
                ...state,employees:[...state.employees].filter((employee)=>employee.id!=action.payload)
            }
       
        }
    },
    extraReducers: {
        [getRoles.pending]: pendingReducer,
        [getRoles.fulfilled]: (state, { payload }) => {
            state.roles= payload?payload:[]
            state.status = 'fulfilled'
            state.logging = false
        },
        [getRoles.rejected]: rejectedReducer,
        [getEmployees.pending]: pendingReducer,
        [getEmployees.fulfilled]: (state, { payload }) => {
            state.employees= payload?payload.data:[]
            state.total=payload.total?payload.total:state.total
            state.status = 'fulfilled'
            state.logging = false
        },
        [getEmployees.rejected]: rejectedReducer,

        [deleteEmployee.pending]: pendingReducer,
        [deleteEmployee.fulfilled]: fulfilledReducer,
        [deleteEmployee.rejected]: rejectedReducer,

        
        [addEmployee.pending]: pendingReducer,
        [addEmployee.fulfilled]: fulfilledReducer,
        [addEmployee.rejected]: rejectedReducer,

        [getEmployee.pending]: pendingReducer,
        [getEmployee.fulfilled]: (state, { payload }) => {
           
            state.employee= payload?payload:{}
            state.status = 'fulfilled'
            state.logging = false
        },
        [getEmployee.rejected]: pendingReducer,

        [editEmployee.pending]: pendingReducer,
        [editEmployee.fulfilled]: fulfilledReducer,
        [editEmployee.rejected]: rejectedReducer,
 
    }
})

export const employeeSelector = (state) => state.employee;