import React, { useState } from "react";
import {
    Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import { t } from "i18next";
import { Form } from "../components";
import { useContext } from "react";



export const Filter=({
  handleFilter,
  form,
  data,
  errors,
  setData,
  setErrors,
  open,setOpen

})=> {
  const isNonMobile = useMediaQuery("(min-width:600px)");


    //console.log(data);
//   const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleReset = () => {
    //console.log(data);
    const dataKeys = Object.keys(data);
    const errorKeys = Object.keys(errors);
    //console.log(dataKeys);
    for (let index = 0; index < dataKeys.length; index++) {
      const dataKey = dataKeys[index];
      data[dataKey] = "";
    }
    for (let index = 0; index < errorKeys.length; index++) {
      const errorKey = errorKeys[index];
      data[errorKey] = "";
    }
    //console.log(setData);
    setData(data);
    setErrors(errors);
  };

  return (
   
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("Filter")}</DialogTitle>
        <DialogContent>
        <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
          <Form
            errors={errors}
            data={data}
            handleChange={handleChange}
            form={form}
          />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
          <Button onClick={handleFilter}>{t("Ok")}</Button>
          {/* <Button onClick={handleReset}>{t("Reset")}</Button> */}
        </DialogActions>
      </Dialog>

  );
}



