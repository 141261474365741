import { toast } from "react-toastify"

class Toast{
    static showSuccessToast=(message,id)=>
    {
        toast.success(message,{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId:id
          })
    }
    static showErrorToast=(message,id)=>{
        toast.error(message,{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId:id
          })
    }
}
export default Toast