import { createSlice } from "@reduxjs/toolkit";
import { loginUser, resetPassword, sendCodeToEmail, logout, editPassword } from "./service";
import { fulfilledReducer, pendingReducer, rejectedReducer } from "../../../redux/globalReducer";

const initialState = {
  status: "",
  refreshStatus: "",
  token: "",
  logging: false,
  errors: [],
  errorMessage: "",
  user: null,
  sections: [],
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // initToken: setToken,
    // initUser:setUser,
    // logout:signout
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.logging = true;
      state.status = "pending";
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      // state.token = payload.token
      // state.user = payload.user
      state.status = "fulfilled";
      state.logging = false;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.status = "rejected";
      state.logging = false;
    },

    [sendCodeToEmail.pending]: (state) => {
      state.logging = true;
      state.status = "pending";
    },
    [sendCodeToEmail.fulfilled]: (state, { payload }) => {
      // state.token = payload.token
      // state.user = payload.user
      state.status = "fulfilled";
      state.logging = false;
    },
    [sendCodeToEmail.rejected]: (state, { payload }) => {
      state.status = "rejected";
      state.logging = false;
    },

    [resetPassword.pending]: (state) => {
      state.logging = true;
      state.status = "pending";
    },
    [resetPassword.fulfilled]: (state, { payload }) => {
      // state.token = payload.token
      // state.user = payload.user
      state.status = "fulfilled";
      state.logging = false;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.status = "rejected";
      state.logging = false;
    },

    [logout.pending]: (state) => {
      state.logging = true;
      state.status = "pending";
    },
    [logout.fulfilled]: (state, { payload }) => {
      state.status = "fulfilled";
      state.logging = false;
    },
    [logout.rejected]: (state, { payload }) => {
      state.status = "rejected";
      state.logging = false;
    },

    [editPassword.pending]: pendingReducer,
        [editPassword.fulfilled]: fulfilledReducer,
        [editPassword.rejected]: rejectedReducer,
  },
});
// export const { initToken, initUser, logout } = authSlice.actions;
export const authSelector = (state) => state.auth;
