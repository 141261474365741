import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useMediaQuery} from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {Form, Header } from "../../components";
import { EDIT_TICKET_FORM, EDIT_TICKET_FORM_WITHOUT_FARE } from "./form";
import { ticketSelector } from "./redux/slice";
import { getOneTicketMapper } from "./mapper";
import { useParams } from "react-router-dom";
import { getCurrentDate } from "../../services/functions";

const EditTicket = ({editTicket,getTicket,errors,setErrors}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {ticket}=useSelector(ticketSelector)
  const {id}=useParams()
  const {t}=useTranslation()
  const [_ticket, setTicket] = useState({
  
  })
   const [fieldsData, setFieldsData] = useState({
    customer_price:"",
    travel:"",
    description:"",
    release_date:getCurrentDate(),
    rest_amount:"",
    ticket_price:"",
    ticket_price_fare:"",
    payment_price_to_supplier:"",

  })

  const handleFormSubmit = () => {
    editTicket(id,_ticket)
  };
  const handleChange=(event)=>{
      const {name,value}=event.target
      setTicket({..._ticket,[name]:value})
      setErrors((prevErrors)=>(
        {...prevErrors,
        [name]:''
        }
    ))
  }

  const handleChangeSwitch=(event)=>{
    const {name,checked}=event.target
    setTicket({..._ticket,[name]:checked})
    setErrors((prevErrors)=>(
      {...prevErrors,
      [name]:''
      }
  ))
}

const handleColor=(color)=>{
     setTicket({...ticket,color:color.hex})
        setErrors((prevErrors)=>(
      {...prevErrors,
      color:''
      }
  ))
}

const handleChangeField=(event,itemName,index)=>{
    const {name,value}=event.target
    //console.log(name,value,index);
    const fields=ticket[itemName]
    fields[index][name]=value
    setTicket({...ticket,[itemName]:fields})
}
const addField=(name)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]+1
    }
))
    const fields=ticket[name]
    fields.push({})
    setTicket((prevTicket)=>(
      {...prevTicket,
      [name]:fields
      }
  ))

}

const deleteField=(name,index)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]-1
    }
))
    const fields=ticket[name]
    fields.splice(index,1)
    setTicket({...ticket,[name]:fields})
}

  useEffect(() => {
   getTicket(id)
  }, [])

  useEffect(() => {
    setTicket(getOneTicketMapper(ticket))
  }, [ticket])
    
  return (
    <Box m="20px">
      <Header title="Ticket" subtitle="Edit a Ticket" />
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
           
            <Form handleColor={handleColor} handleChangeSwitch={handleChangeSwitch} errors={errors} data={_ticket}  handleChange={handleChange}  form={ticket?.ticket_price_fare?  EDIT_TICKET_FORM:EDIT_TICKET_FORM_WITHOUT_FARE}  />
          </Box>
         

          <Box display="flex" justifyContent="end" mt="20px">
            <Button onClick={()=>{handleFormSubmit()}} type="submit" color="secondary" variant="contained">
              {t("Edit Ticket")}
            </Button>
          </Box>
     
  
    </Box>
  );
};

export default EditTicket;
