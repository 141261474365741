import { FormControlLabel, Switch } from '@mui/material'
import { t } from 'i18next'
import React from 'react'

function GeneralSwitch({item,data,color,handleChange}) {
  return (
    <FormControlLabel
    control={
      <Switch
        checked={data[item.name]}
        onChange={handleChange}
        name={item.name}
        color={color}
      />
      
    }
    disabled={item.disabled?true:false}
    label={t(item.label)}
  />
  )
}

export default GeneralSwitch