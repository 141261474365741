import Joi from "joi-browser";

export const addConstantSchema = {
    name: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter  name",
      };
    }),
    value: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter  value",
      };
    }),
};

export const editConstantSchema = {
    name: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter  name",
      };
    }),
    value: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter  value",
      };
    }),
};
